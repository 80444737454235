import React, { FC, MouseEvent, ReactNode } from 'react';
import { IconName } from 'assets';
import classNames from 'classnames';
import { COLORS } from 'styles';

import { Icon } from '../icon/Icon';
import { Text, TextProps } from '../text/Text';

import './sideModal.scss';

type Props = {
  bottomButtons?: ReactNode;
  className?: string;
  content?: ReactNode;
  fullwidth?: boolean;
  header: SideModalHeader;
  isVisible?: boolean;
  onBackgroundClick?: () => void;
  placement?: 'left' | 'right';
};

export type SideModalHeader = {
  backgroundColor?: string;
  leftIcon?: {
    iconName: IconName;
    onClick: () => void;
  };
  rightIcon?: {
    iconName: IconName;
    onClick: () => void;
  };
  title: {
    props?: TextProps;
    text: string;
  };
};

export const SideModal: FC<Props> = ({
  bottomButtons,
  className,
  content,
  fullwidth = false,
  header,
  isVisible,
  onBackgroundClick = () => {},
  placement = 'right',
}) => {
  const handleModalBackgroundClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onBackgroundClick();
    }
  };

  return (
    <div className={classNames('side-modal-wrapper', { 'show-drawer': isVisible })}>
      <div className={classNames(placement, className)} id="drawer">
        <section className="header" style={{ backgroundColor: header.backgroundColor ?? 'transparent' }}>
          {header.leftIcon ? (
            <div className="left-icon">
              <Icon colorName={COLORS.zulu.$08} name={header.leftIcon.iconName} onClick={header.leftIcon.onClick} />
            </div>
          ) : null}
          <Text color={COLORS.zulu.$08} size="MD" uppercase weight="bold" {...header.title.props}>
            {header.title.text}
          </Text>
          <div className="right-icon">
            {header.rightIcon ? (
              <Icon colorName={COLORS.zulu.$08} name={header.rightIcon.iconName} onClick={header.rightIcon.onClick} />
            ) : null}
          </div>
        </section>
        <section className={classNames('content', fullwidth && 'fullwidth')}>{content}</section>
        {bottomButtons ? <section className="footer">{bottomButtons}</section> : null}
      </div>
      <div id="overlay" onClick={handleModalBackgroundClick}></div>
    </div>
  );
};
