import { useState } from 'react';
import { useFlightroomsContext } from 'shared';
import { FlightRoomFilterState } from 'types';

import { FlightRoomFilterValue } from '../_types/FlightRoomFilterValue';

export type FlightRoomFilter = {
  appliedFlightRoomFilters: FlightRoomFilterState | null;
  filterFormState: FlightRoomFilterState;
  handleFlightRoomFilterChange: (value: FlightRoomFilterValue, name: keyof FlightRoomFilterState) => void;
  handleFlightRoomFilterClose: () => void;
  handleFlightRoomFilterReset: () => void;
  handleFlightRoomFilterSubmit: () => void;
};

export const initialFilterState: FlightRoomFilterState = {
  activeFlights: true,
  aircraftRegistrations: [],
  arrivalFromDate: null,
  arrivalFromTime: null,
  arrivalToDate: null,
  arrivalToTime: null,
  callSigns: [],
  departureFromDate: null,
  departureFromTime: null,
  departureGates: [],
  departureIcaos: [],
  departureToDate: null,
  departureToTime: null,
  desks: [],
  destinationGates: [],
  destinationIcaos: [],
  flightNumbers: [],
  landedFlights: true,
  tasks: false,
};

export const useFlightRoomFilter = (): FlightRoomFilter => {
  const [filterFormState, setFilterFormState] = useState<FlightRoomFilterState>(initialFilterState);
  const [appliedFlightRoomFilters, setAppliedFlightRoomFilters] = useState<FlightRoomFilterState | null>(null);
  const { closeFilters } = useFlightroomsContext();

  const handleChange = (value: FlightRoomFilterValue, name: keyof FlightRoomFilterState) => {
    setFilterFormState(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFilterFormState(initialFilterState);
  };

  const handleSubmit = () => {
    if (filterFormState === initialFilterState) {
      setAppliedFlightRoomFilters(null);
    } else {
      setAppliedFlightRoomFilters(filterFormState);
    }
  };

  const handleClose = () => {
    setFilterFormState(appliedFlightRoomFilters ?? initialFilterState);
    closeFilters();
  };

  return {
    appliedFlightRoomFilters,
    filterFormState: filterFormState,
    handleFlightRoomFilterChange: handleChange,
    handleFlightRoomFilterClose: handleClose,
    handleFlightRoomFilterReset: handleReset,
    handleFlightRoomFilterSubmit: handleSubmit,
  };
};
