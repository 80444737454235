import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useAppContext } from '@aviobook/_context';
import { initialFilterState, useFlightRoomFilter } from '@aviobook/_hooks';
import { FlightRoomFilterValue } from '@aviobook/_types/FlightRoomFilterValue';
import { ChatWrapper } from '@aviobook/chat/ChatWrapper';
import { Filters } from '@aviobook/filters/Filters';
import ConfirmationModal from '@aviobook/modal/confirmation/ConfirmationModal';
import {
  isRoomCompleted,
  useAuthenticatedUser,
  useFlightroomsContext,
  useFlightroomsUnreadCount,
  useNotificationSound,
} from 'shared';
import { sendRoomCloseEvent, sendRoomOpenEvent } from 'shared/src/sockets/socket';
import { FlightArrivalStatus, FlightRoom, FlightRoomFilterState } from 'types';

import { FlightRoomList } from './_components/FlightRoomList';
import { FlightRoomsHeaderComponent } from './_components/FlightRoomsHeaderComponent/FlightRoomsHeaderComponent';
import { SearchedFlightRoomList } from './_components/SearchedFlightRoomList';
import { FlightroomDashboardTabs } from './flightroomDashboardTabs';

import './flightrooms.scss';

export const Flightrooms: FC = () => {
  useNotificationSound();
  const { isActiveMemberOfRoom } = useAuthenticatedUser();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeFlightrooms, completedFlightrooms, resetUnreadCountsForRoom } = useFlightroomsUnreadCount(true);
  const { selectedFlightroomTab, setSelectedFlightroomTab } = useAppContext();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoadingSelectedFlightroom, setIsLoadingSelectedFlightroom] = useState(false);
  const { getRoomById, removeFlightroom, selectedFlightroom, setSelectedFlightroom } = useFlightroomsContext();
  const [tab, setTab] = useState<FlightroomDashboardTabs>(
    selectedFlightroom && isRoomCompleted(selectedFlightroom) ? FlightArrivalStatus.COMPLETED : FlightArrivalStatus.ACTIVE,
  );
  const prevSelectedFlightroomRef = useRef<FlightRoom | null>(null);
  const {
    appliedFlightRoomFilters,
    filterFormState,
    handleFlightRoomFilterChange,
    handleFlightRoomFilterClose,
    handleFlightRoomFilterReset,
    handleFlightRoomFilterSubmit,
  } = useFlightRoomFilter();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const flightrooms: FlightRoom[] = tab !== FlightArrivalStatus.COMPLETED ? activeFlightrooms : completedFlightrooms;

  const removeSelectedFlightRoom = useCallback(() => {
    setSearchQuery('');
    setSearchParams();
    setSelectedFlightroom(null);
  }, [setSearchParams, setSelectedFlightroom]);

  const handleTabChange = useCallback(
    (newTab: FlightroomDashboardTabs) => {
      setTab(newTab);
      setSelectedFlightroomTab(newTab === FlightArrivalStatus.ACTIVE ? 'active' : 'completed');
    },
    [setTab, setSelectedFlightroomTab],
  );

  const setRoom = useCallback(
    async (id: FlightRoom['externalId']) => {
      setIsLoadingSelectedFlightroom(true);
      let room = await getRoomById(id);

      if (!room || !isActiveMemberOfRoom(room)) {
        removeSelectedFlightRoom();
        setIsLoadingSelectedFlightroom(false);
        return;
      }

      handleTabChange(isRoomCompleted(room) ? FlightArrivalStatus.COMPLETED : FlightArrivalStatus.ACTIVE);
      resetUnreadCountsForRoom(room.externalId);
      room = { ...room, hasUnreadMentions: false };
      setSelectedFlightroom(room);
      setIsLoadingSelectedFlightroom(false);
    },
    [
      getRoomById,
      handleTabChange,
      isActiveMemberOfRoom,
      removeSelectedFlightRoom,
      resetUnreadCountsForRoom,
      setSelectedFlightroom,
    ],
  );

  const removeFlightRoom = useCallback(() => {
    if (selectedFlightroom) {
      removeFlightroom(selectedFlightroom);
    }
    removeSelectedFlightRoom();
  }, [removeFlightroom, removeSelectedFlightRoom, selectedFlightroom]);

  const setSelectedRoom = useCallback(() => {
    const roomId = searchParams.get('room') ?? '';

    if (!roomId && selectedFlightroom) {
      // when there is no room id it means there is no url search param, so it makes no sense to keep the current
      // selected flight room
      // this removes the current selected room + removes the room from the flightroom list
      removeSelectedFlightRoom();
      removeFlightroom(selectedFlightroom);
      return;
    }

    if (isSelectedRoomChanged(roomId, selectedFlightroom?.externalId ?? '')) {
      // when the flight room is changed to another then the current selected room we set the room automatically
      if (!appliedFlightRoomFilters) {
        // when there are no filter applied since the list will always contain the selected flightroom
        setRoom(roomId);
        return;
      }

      const isNewSelectedRoomInFilteredRooms = flightrooms.some(f => f.externalId === roomId);
      // when filters are applied it is possible that the selected flightroom is filtered out by the applied filters
      if (isNewSelectedRoomInFilteredRooms) {
        setRoom(roomId);
      } else {
        removeFlightRoom();
      }
    }
  }, [
    appliedFlightRoomFilters,
    flightrooms,
    removeFlightRoom,
    removeFlightroom,
    removeSelectedFlightRoom,
    searchParams,
    selectedFlightroom,
    setRoom,
  ]);

  useEffect(() => {
    setSelectedRoom();
  }, [setSelectedRoom]);

  useEffect(() => {
    handleTabChange(selectedFlightroomTab === 'active' ? FlightArrivalStatus.ACTIVE : FlightArrivalStatus.COMPLETED);
  }, [handleTabChange, selectedFlightroomTab]);

  const isSelectedRoomChanged = (newRoomId: string, selectedRoomId: string) => newRoomId && newRoomId !== selectedRoomId;

  const handleFlightroomClick = (room: FlightRoom) => {
    if (room.externalId !== selectedFlightroom?.externalId) {
      setSearchParams({ room: room.externalId });
    }
  };

  const isFilterChanged = useMemo(
    () =>
      (appliedFlightRoomFilters && appliedFlightRoomFilters !== filterFormState) ??
      (!appliedFlightRoomFilters && JSON.stringify(filterFormState) !== JSON.stringify(initialFilterState)),
    [appliedFlightRoomFilters, filterFormState],
  );

  const handleFilterClose = () => {
    isFilterChanged ? setIsModalOpen(true) : handleFlightRoomFilterClose();
  };

  const handleModalConfirm = () => {
    if (isFilterChanged) {
      handleFlightRoomFilterClose();
    } else {
      handleFlightRoomFilterReset();
      setIsFilterUpdated(true);
    }
    setIsModalOpen(false);
  };

  const handleFilterDelete = (value: FlightRoomFilterValue, filterKey: keyof FlightRoomFilterState) => {
    handleFlightRoomFilterChange(value, filterKey);
    setIsFilterUpdated(true);
  };

  useEffect(() => {
    const prevFlightroom = prevSelectedFlightroomRef.current;
    prevSelectedFlightroomRef.current = selectedFlightroom ?? null;

    // No current or previous flightroom
    if (!selectedFlightroom && !prevFlightroom) {
      return;
    }

    // Current flightroom but no previous flightroom
    if (!prevFlightroom && selectedFlightroom) {
      resetUnreadCountsForRoom(selectedFlightroom.externalId);
      sendRoomOpenEvent(selectedFlightroom.externalId);
      return;
    }

    // Previous flightroom but no current flightroom
    if (prevFlightroom && !selectedFlightroom) {
      resetUnreadCountsForRoom(prevFlightroom?.externalId);
      sendRoomCloseEvent();
      return;
    }

    // Different flightrooms
    if (prevFlightroom?.externalId !== selectedFlightroom?.externalId) {
      if (prevFlightroom) {
        resetUnreadCountsForRoom(prevFlightroom?.externalId);
      }
      if (selectedFlightroom) {
        resetUnreadCountsForRoom(selectedFlightroom?.externalId);
      }
      sendRoomCloseEvent();
      // TODO check if this can be done in the same if as above
      if (selectedFlightroom) {
        sendRoomOpenEvent(selectedFlightroom?.externalId);
      }
    }
  }, [resetUnreadCountsForRoom, selectedFlightroom]);

  useEffect(() => {
    if (isFilterUpdated) {
      handleFlightRoomFilterSubmit();
      setIsFilterUpdated(false);
    }
  }, [handleFlightRoomFilterSubmit, isFilterUpdated]);

  useEffect(() => {
    if (appliedFlightRoomFilters) {
      const areAllFiltersDeleted = Object.values(appliedFlightRoomFilters)
        .filter(value => typeof value !== 'boolean')
        .every(value => value === null || (Array.isArray(value) && value.length === 0));
      const areTabFiltersDeleted = !Object.values(appliedFlightRoomFilters)
        .filter(value => typeof value === 'boolean')
        .some(value => value);

      if (areAllFiltersDeleted || areTabFiltersDeleted) {
        handleFlightRoomFilterReset();
        setIsFilterUpdated(true);
      }
    }
  }, [appliedFlightRoomFilters, handleFlightRoomFilterReset]);

  return (
    <>
      <aside className="flightrooms-container">
        <FlightRoomsHeaderComponent
          filters={appliedFlightRoomFilters}
          handleFlightRoomFilterClose={handleFilterClose}
          handleFlightRoomFilterDelete={handleFilterDelete}
          handleFlightRoomFilterReset={() => setIsModalOpen(true)}
          searchQuery={searchQuery}
          selectedFlightroomTab={tab}
          setSearchQuery={setSearchQuery}
          setSelectedFlightroomTab={handleTabChange}
        />
        {searchQuery ? (
          <SearchedFlightRoomList resetSearchQuery={() => setSearchQuery('')} searchQuery={searchQuery} />
        ) : (
          <FlightRoomList
            filters={appliedFlightRoomFilters}
            flightrooms={flightrooms}
            onFlightroomClick={handleFlightroomClick}
            selectedFlightroomTab={tab}
          />
        )}
      </aside>
      {isModalOpen ? (
        <ConfirmationModal
          confirmText={t('FILTERS.MODAL.CONFIRM')}
          content={isFilterChanged ? t('FILTERS.MODAL.FILTERS_CHANGED') : t('FILTERS.MODAL.FILTERS_RESET')}
          onCancel={() => setIsModalOpen(false)}
          onConfirm={handleModalConfirm}
          title={isFilterChanged ? t('FILTERS.MODAL.ABANDON_CHANGES') : t('FILTERS.MODAL.RESET_FILTERS')}
        />
      ) : null}
      <div className="flightrooms-info">
        <Filters
          filterFormState={filterFormState}
          handleFlightRoomFilterChange={handleFlightRoomFilterChange}
          handleFlightRoomFilterClose={handleFilterClose}
          handleFlightRoomFilterReset={handleFlightRoomFilterReset}
          handleFlightRoomFilterSubmit={handleFlightRoomFilterSubmit}
        />
        <Routes>
          <Route element={<ChatWrapper isLoading={isLoadingSelectedFlightroom} />} path="*"></Route>
        </Routes>
      </div>
    </>
  );
};
