import { Division, JobTitleV1 } from 'types';

type TransformableNameObject = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  preferredName?: string;
};

export class UserV1Util {
  static getFullName(user?: { firstName: undefined; lastName: undefined; middleName: undefined; preferredName: undefined }): '-';
  static getFullName(user?: TransformableNameObject): string;
  static getFullName(
    user?:
      | TransformableNameObject
      | { firstName: undefined; lastName: undefined; middleName: undefined; preferredName: undefined },
  ): string {
    const preferredName = user?.preferredName ?? '';

    if (preferredName.length > 0) {
      return preferredName;
    }

    const firstName = user?.firstName ?? '';
    const middleName = user?.middleName ?? '';
    const lastName = user?.lastName ?? '';

    if (!(firstName.length > 0) && !(middleName.length > 0) && !(lastName.length > 0)) {
      return '';
    }

    return [firstName, middleName, lastName].filter(name => name && name.length > 0).join(' ');
  }

  static getDivision(jobTitle?: JobTitleV1) {
    switch (jobTitle) {
      case JobTitleV1.ADMINISTRATOR:
        return Division.ADMINISTRATOR;
      case JobTitleV1.CABIN_CREW:
        return Division.CABIN_CREW;
      case JobTitleV1.DISPATCHER:
        return Division.DISPATCHER;
      case JobTitleV1.GROUND_HANDLER:
        return Division.GROUND_HANDLER;
      case JobTitleV1.MAINTENANCE_CREW:
        return Division.MAINTENANCE_CREW;
      case JobTitleV1.PILOT:
        return Division.PILOT;
      case JobTitleV1.UNKNOWN:
      default:
        return Division.UNKNOWN;
    }
  }

  static getGuestUserString(jobTitle?: JobTitleV1) {
    return `Guest (${UserV1Util.getDivision(jobTitle).toUpperCase()})`;
  }
}
