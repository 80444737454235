import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { useUnsavedChangesContext } from '@aviobook/_context';
import ConfirmationModal from '@aviobook/modal/confirmation/ConfirmationModal';

export const NavigationGuard = () => {
  const { t } = useTranslation();
  const { hasUnsavedChanges, setUnsavedChanges } = useUnsavedChangesContext();
  const [showModal, setShowModal] = useState(false);
  const blocker = useBlocker(hasUnsavedChanges);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setShowModal(true);
    }
  }, [blocker.state]);

  const confirmNavigation = () => {
    setUnsavedChanges(false);
    setShowModal(false);
    setTimeout(() => {
      blocker?.proceed?.();
    });
  };

  const cancelNavigation = () => {
    setShowModal(false);
    blocker?.reset?.();
  };

  return (
    <>
      {showModal ? (
        <ConfirmationModal
          confirmText={t('NAVIGATION_GUARD.CONFIRM')}
          content={t('NAVIGATION_GUARD.CONTENT')}
          onCancel={cancelNavigation}
          onConfirm={confirmNavigation}
          title={t('NAVIGATION_GUARD.TITLE')}
        />
      ) : null}
    </>
  );
};
