import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { useLogout } from '@aviobook/_hooks';
import { useAppInitializer } from '@aviobook/_hooks/useAppInitializer';
import { Flightrooms } from '@aviobook/flightrooms/Flightrooms';
import { LicenseAgreement } from '@aviobook/license-agreement/LicenseAgreement';
import { ModalOpener } from '@aviobook/modal/ModalOpener';
import OneButtonModal from '@aviobook/modal/oneButtonModal/OneButtonModal';
import { useQueryClient } from '@tanstack/react-query';
import {
  areArraysEqual,
  EventType,
  HttpClient,
  NotificationsContextProvider,
  useAppConfigContext,
  useAuthenticate,
  useAuthenticationContext,
  useFlightroomsContext,
  useSocket,
  useSocketEvent,
} from 'shared';
import {
  AuthenticatedUser,
  FlightRoomPermissions,
  QueryKeys,
  UserPermissionUpdateEvent,
  UserStatus,
  UserStatusUpdateEvent,
} from 'types';

import { FirebaseConfigContextProvider, useFirebaseConfigContext } from './_context';
import { DashboardLayout } from './_routing/dashboard-layout/DashboardLayout';
import { Spinner } from './_shared';
import { OpenWebNotification } from './open-web-notification';

import './index.scss';

export const AppWeb = () => {
  document.title = 'Connect Web';
  const { t } = useTranslation();

  const {
    accessToken,
    isLoading: authenticationIsLoading,
    setLoading: setAuthenticationIsLoading,
    setUser,
    user: authenticatedUser,
  } = useAuthenticationContext();
  const { appConfig, updateAppConfigAccount } = useAppConfigContext();

  const { isLoading: isFetchingAuthentication } = useAuthenticate(false, {
    enabled: !!accessToken,
    onSuccess: user => {
      updateAppConfigAccount(user.account);
      HttpClient.setHeaders({ ...appConfig, account: user.account });
      setUser(user);
    },
  });

  useEffect(() => {
    setAuthenticationIsLoading(isFetchingAuthentication);
  }, [isFetchingAuthentication, setAuthenticationIsLoading]);

  if (authenticationIsLoading) {
    return <Spinner overlay>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  if (authenticatedUser && !authenticatedUser.hasAcceptedLicenseAgreement) {
    return (
      <Routes>
        <Route element={<LicenseAgreement />} path="*" />
      </Routes>
    );
  }

  if (authenticatedUser) {
    return (
      <FirebaseConfigContextProvider>
        <AuthenticatedRoutes>
          <Route element={<DashboardLayout />} path="dashboard/*">
            <Route element={<Flightrooms />} path="flightrooms" />
            <Route element={<Navigate to="flightrooms" />} path="*" />
          </Route>
          <Route element={<Navigate to="dashboard" />} path="*" />
          <Route element={<OpenWebNotification />} path="open-notification/:type/:payload" />
        </AuthenticatedRoutes>
      </FirebaseConfigContextProvider>
    );
  }

  return <Routes></Routes>;
};

const AuthenticatedRoutes: FC<PropsWithChildren> = ({ children }) => {
  useAppInitializer();
  const { logEvent } = useFirebaseConfigContext();

  const { t } = useTranslation();
  const { setUser, user } = useAuthenticationContext();
  const { socket } = useSocket();
  const { resetFlightrooms } = useFlightroomsContext();
  const queryClient = useQueryClient();
  const [isPermissionAlertOpen, setIsPermissionAlertOpen] = useState(false);

  const [, setSearchParams] = useSearchParams();

  const logout = useLogout();

  const handleAuthUpdate = (updatedUser: AuthenticatedUser) => {
    setUser(updatedUser);

    const permissionsDidChange = user && !areArraysEqual(user.permissions, updatedUser.permissions, true);
    if (!permissionsDidChange) {
      return;
    }
    handlePermissionUpdate({ externalId: updatedUser.externalId, permissions: updatedUser.permissions }, false);
  };

  const handlePermissionUpdate = (updateEvent: UserPermissionUpdateEvent, updateState = true) => {
    const { externalId, permissions } = updateEvent;
    if (!user || externalId !== user.externalId) {
      return;
    }

    if (!Array.isArray(permissions)) {
      return;
    }

    if (updateState) {
      setUser({ ...user, permissions });
    }

    const hasViewPermission = permissions.includes(FlightRoomPermissions.VIEW);
    if (!hasViewPermission) {
      setSearchParams();
      resetFlightrooms();
      queryClient.invalidateQueries([QueryKeys.FLIGHTROOMS]);
    }

    if (!isPermissionAlertOpen) {
      ModalOpener.instance.open({
        render: () => (
          <OneButtonModal
            confirmText={t('SHARED.BUTTONS.OK')}
            content={t('PERMISSIONS.PERMISSIONS_UPDATED.CONTENT')}
            onConfirm={() => {
              setIsPermissionAlertOpen(false);
              ModalOpener.instance.close();
            }}
            title={t('PERMISSIONS.PERMISSIONS_UPDATED.TITLE')}
          />
        ),
      });
      setIsPermissionAlertOpen(true);
    }
  };

  const handleUserStatusUpdate = (userUpdate: UserStatusUpdateEvent) => {
    if (userUpdate.externalId === user?.externalId && userUpdate.userStatus === UserStatus.INACTIVE) {
      logEvent(EventType.LOGOUT, { reason: 'Socket event user update: inactive' });
      logout();
    }
  };

  useSocketEvent(socket, 'auth.update', handleAuthUpdate);
  useSocketEvent(socket, 'user.permission.update', handlePermissionUpdate);
  useSocketEvent(socket, 'user.status.update', handleUserStatusUpdate);

  return (
    <NotificationsContextProvider>
      <Routes>{children}</Routes>
    </NotificationsContextProvider>
  );
};
