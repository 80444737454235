import { MutateOptions, useMutation } from '@tanstack/react-query';
import { ApiError, FlightRoom, QueryKeys } from 'types';

import { joinFlightroom } from '../queries';
import { getFlightroomById } from '../queries/flightrooms/requests';

type OptionType = Omit<MutateOptions<FlightRoom, ApiError, FlightRoom['externalId'], void>, 'onSuccess'> & {
  onSuccess: (room: FlightRoom | null) => void;
};

export const useJoinFlightroom = (options?: OptionType) =>
  useMutation([QueryKeys.JOIN_FLIGHTROOM], joinFlightroom, {
    ...options,
    onSuccess: async (_, variable) => {
      if (options?.onSuccess) {
        const flightroom = await getFlightroomById(variable);

        options.onSuccess(flightroom?.items?.at(0) ?? null);
      }
    },
  });
