import { FlightroomDashboardTabs } from '@aviobook/flightrooms/flightroomDashboardTabs';
import { AvioDateTime } from 'shared';
import { formatDateToDateTimeLongNoZone } from 'shared/src/utils/date-time/lib/avio-date-time.util';
import { FlightArrivalStatus, FlightRoomFilterState, Option } from 'types';

export type FilterLabel = FlightRoomFilterState & {
  arrivalFrom: string;
  arrivalTo: string;
  departureFrom: string;
  departureTo: string;
};

type FiltersTab = keyof Pick<FilterLabel, 'activeFlights' | 'landedFlights'>;

export const filtersTabMap = new Map<FlightroomDashboardTabs, FiltersTab>([
  [FlightArrivalStatus.ACTIVE, 'activeFlights'],
  [FlightArrivalStatus.COMPLETED, 'landedFlights'],
]);

export const filtersLabelMap = new Map<keyof FilterLabel, string>([
  ['activeFlights', 'FILTERS.LABEL.ACTIVE'],
  ['aircraftRegistrations', 'FILTERS.LABEL.AIRCRAFT_REGISTRATION'],
  ['arrivalFrom', 'FILTERS.LABEL.ARRIVAL_FROM'],
  ['arrivalTo', 'FILTERS.LABEL.ARRIVAL_TO'],
  ['callSigns', 'FILTERS.LABEL.CALL_SIGN'],
  ['departureFrom', 'FILTERS.LABEL.DEPARTURE_FROM'],
  ['departureGates', 'FILTERS.LABEL.DEPARTURE_GATE'],
  ['departureIcaos', 'FILTERS.LABEL.DEPARTURE_ICAO'],
  ['departureTo', 'FILTERS.LABEL.DEPARTURE_TO'],
  ['desks', 'FILTERS.LABEL.DESK'],
  ['destinationGates', 'FILTERS.LABEL.DESTINATION_GATE'],
  ['destinationIcaos', 'FILTERS.LABEL.DESTINATION_ICAO'],
  ['flightNumbers', 'FILTERS.LABEL.FLIGHT_NUMBER'],
  ['landedFlights', 'FILTERS.LABEL.LANDED'],
  ['tasks', 'FILTERS.LABEL.TASKS'],
]);

export const mapAppliedFlightRoomFilters = (
  filters: FlightRoomFilterState | null,
): {
  key: keyof FilterLabel;
  label: string;
  value: string;
}[] => {
  if (filters) {
    return Object.entries(filters)
      .filter(([, value]) => !!value)
      .filter(([key]) => !key.endsWith('Time'))
      .map(([key, value]) => {
        const mappedKey = key as keyof FilterLabel;
        if (Array.isArray(value)) {
          return (value as Option[]).map((v: Option) => ({ key: mappedKey, label: v.label, value: v.value }));
        }

        if (value instanceof Date) {
          const convertedDateKey = key.split('Date')[0] as keyof FilterLabel;
          const convertedTimeKey = `${convertedDateKey}Time`;
          const timeValue = filters[convertedTimeKey as keyof FlightRoomFilterState] as Option;
          const dateTimeValue = AvioDateTime(value).setHoursWithMinutes(timeValue?.value ?? '00:00');
          return [
            {
              key: convertedDateKey,
              label: '',
              value: formatDateToDateTimeLongNoZone(dateTimeValue) ?? '',
            },
          ];
        }

        if (typeof value === 'boolean') {
          return [{ key: mappedKey, label: '', value: '' }];
        }

        return [];
      })
      .flat();
  }

  return [];
};
