import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@aviobook/_hooks';
import { FormValidationErrors } from '@aviobook/_hooks/useForm';
import { InputField } from '@aviobook/_shared';
import { ModalForm } from '@aviobook/cms/connect/_screens/roles/_components/form/ModalForm';
import { FormValidator } from 'shared';
import { RoleQuickReply } from 'types';

type Props = {
  closeModal: () => void;
  isLoading: boolean;
  onAdd(values: RoleQuickReply): void;
};

const initialForm: RoleQuickReply = {
  description: '',
  value: '',
};

const validateForm = (values: RoleQuickReply): FormValidationErrors<RoleQuickReply> => {
  const validator = new FormValidator();

  return {
    value: validator.required(values.value),
  };
};

export const AddRoleQuickRepliesModal: FC<Props> = params => {
  const { t } = useTranslation();

  const { closeModal, isLoading, onAdd } = params;

  const form = useForm<RoleQuickReply>({
    initialForm,
    submitForm: onAdd,
    validateForm,
  });

  return (
    <ModalForm
      disabled={!form.isValid}
      headerTitle={t('QUICK_REPLIES.ACTIONS.ADD')}
      isSubmitting={isLoading}
      onCancel={closeModal}
      onSubmit={form.submit}
      testId="add-role-quick-reply-modal"
    >
      <InputField
        label={t('QUICK_REPLIES.FORM.TEXT')}
        name="value"
        onChange={form.setAttribute}
        required
        showInlineBottomError
        validation={form.validationErrors.value}
        value={form.values?.value}
      />
      <InputField
        label={t('QUICK_REPLIES.FORM.DESCRIPTION')}
        name="description"
        onChange={form.setAttribute}
        showInlineBottomError
        validation={form.validationErrors.description}
        value={form.values?.description}
      />
    </ModalForm>
  );
};
