import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFirebaseConfigContext } from '@aviobook/_context';
import { useLogout } from '@aviobook/_hooks';
import { queryClient } from '@aviobook/_http/queryClient';
import { AviobookLogo } from 'assets/images';
import { EventType, useAuthenticationContext } from 'shared';
import { COLORS } from 'styles';
import { QueryKeys } from 'types';

import { Button, Icon, Text } from '../_shared';
import { useAcceptAgreement } from './_queries';

import './licenseAgreement.scss';

export const LicenseAgreement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setEnableAuthentication, setUser, user } = useAuthenticationContext();
  const { logEvent } = useFirebaseConfigContext();
  const logout = useLogout();

  const { mutate: acceptAgreement } = useAcceptAgreement({
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKeys.AUTHENTICATE]);
      setEnableAuthentication(true);
      if (user) {
        setUser({ ...user, hasAcceptedLicenseAgreement: true });
      }
      navigate('/dashboard');
    },
  });

  const [checked, setChecked] = useState(false);

  const onClickCheckbox = () => {
    setChecked(!checked);
  };

  const onContinue = () => {
    acceptAgreement();
  };

  const onPressBack = () => {
    logEvent(EventType.LOGOUT, { reason: 'Manual logout' });
    logout();
  };

  return (
    <main className="license-agreement-container">
      <header>
        <button className="license-agreement-button" onClick={onPressBack}>
          <Icon colorName={COLORS.zulu.$08} name="arrowLeft" />
          <Text>{t('SHARED.BUTTONS.BACK')}</Text>
        </button>
      </header>
      <section className="license-agreement-content-container">
        <div className="license-agreement-content-container-inner">
          <img alt="logo" src={AviobookLogo} width={160} />
          <Text as="h1" className="title" size="XL" weight="bold">
            {t('LICENSE_AGREEMENT.WEB.TITLE').toUpperCase()}
          </Text>
          <Text as="p" className="subtitle" size="SM" weight="bold">
            {t('LICENSE_AGREEMENT.WEB.SUBTITLE')}
          </Text>
          <Text size="XS" wrap>
            {t('LICENSE_AGREEMENT.EULA_CONTENT').replace(/\\n/gi, '\n')}
          </Text>
        </div>
      </section>
      <footer className="license-agreement-checkbox-container">
        <div className="license-agreement-checkbox-inner">
          <div className="checkbox-container">
            <input checked={checked} id="license-agreement-checkbox" onChange={onClickCheckbox} type="checkbox" />
            <Text as="span" className="checkbox-label" color={COLORS.zulu.$08} size="SM">
              <label htmlFor="license-agreement-checkbox">{t('LICENSE_AGREEMENT.WEB.CHECKBOX_LABEL')}</label>
            </Text>
          </div>
          <Button disabled={!checked} onClick={onContinue}>
            {t('LICENSE_AGREEMENT.CONTINUE')}
          </Button>
        </div>
      </footer>
    </main>
  );
};
