import React, { FC, Key, useMemo } from 'react';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom';
import { Text } from '@aviobook/_shared';
import classnames from 'classnames';
import { getLinkMatches } from 'shared';
import { getArrayOfTextSplitByMention } from 'shared/src/utils/mentions';
import { COLORS } from 'styles';
import { FALLBACK_REGION_CODE } from 'translations';
import { Mention } from 'types';

import './messageBubbleText.scss';

type Props = {
  isMe: boolean;
  mentions?: Mention[];
  setActiveMention: (mention: Mention) => void;
  text: string;
  withStatus: boolean;
};

export const MessageBubbleText: FC<Props> = ({ isMe, mentions = [], setActiveMention, text, withStatus }) => {
  const textColor = isMe ? COLORS.zulu.$08 : COLORS.zulu.$10;

  const linkifyComponentDecorator = (decoratedHref: string, decoratedText: string, key: Key) => {
    return (
      <Link className="message-bubble-hyperlink-text" key={key} rel="noopener noreferrer" target="_blank" to={decoratedHref}>
        {decoratedText}
      </Link>
    );
  };

  const mentionLabels = useMemo(() => mentions?.map(mentions => mentions.label), [mentions]);

  const regionCode = useMemo(() => {
    // format of navigator.language is: en-US, nl-BE, ...
    const splittedArray = navigator.language.split('-');
    if (splittedArray.length > 1) {
      return splittedArray[1];
    }

    return FALLBACK_REGION_CODE;
  }, []);

  const matchDecorator = (text: string) => getLinkMatches(text, regionCode);

  return (
    <Linkify componentDecorator={linkifyComponentDecorator} matchDecorator={matchDecorator}>
      <Text
        className={classnames({
          'slide-left': withStatus,
        })}
        color={textColor}
        size="SM"
      >
        {getArrayOfTextSplitByMention(text, mentionLabels)?.map((part, index) => {
          const mention = mentions.find(mention => `@${mention.label}` === part);

          if (mention) {
            return (
              <button className={'clickable-mention'} key={`${mention.label}-${index}`} onClick={() => setActiveMention(mention)}>
                <Text as="span" className="message-bubble-mention-role" weight="bold">
                  {`${part} `}
                </Text>
              </button>
            );
          } else {
            return <span key={index}>{part}</span>;
          }
        })}
      </Text>
    </Linkify>
  );
};
