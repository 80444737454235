import { parsePhoneNumber } from 'awesome-phonenumber';
import LinkifyIt, { Match } from 'linkify-it';
import tlds from 'tlds';
import { FALLBACK_REGION_CODE } from 'translations';

const linkify = new LinkifyIt();
linkify.tlds(tlds);

const PHONE_NUMBER_REGEX = /(?:\+?(\d{1,3})[-.\s/]?)?(?:\(?(\d{1,4})\)?[-.\s/]?)?(\d{1,4})[-.\s/]?(\d{1,4})[-.\s/]?(\d{1,9})/g;

export const getLinkMatches = (text: string, regionCode: string = FALLBACK_REGION_CODE): Match[] => {
  const defaultMatches = linkify.match(text) ?? [];
  const phoneNumbers: string[] = text.match(PHONE_NUMBER_REGEX) ?? [];

  const phoneNumberMatches = phoneNumbers
    .map(match => {
      const phoneNumber = parsePhoneNumber(match, { regionCode }); // Specify default country if needed
      if (phoneNumber && phoneNumber.valid) {
        return {
          index: text.indexOf(match),
          lastIndex: text.indexOf(match) + match.length,
          raw: match,
          schema: 'tel:',
          text: match,
          url: `tel:${phoneNumber.number.e164}`,
        };
      }
      return null;
    })
    // We can cast as Match[] here since the filter(Boolean) leaves out the null ones
    .filter(Boolean) as Match[];

  return [...phoneNumberMatches, ...defaultMatches].sort((a, b) => a.index - b.index);
};
