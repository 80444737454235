import { ConnectivityStatusV1, UserV1 } from 'types';

export enum UserActionType {
  'ADD_USER',
  'UPDATE_USER',
  'UPDATE_USERS',
  'UPDATE_USER_CONNECTIVITY_STATUS',
}

export type UserAction =
  | {
      payload: UserV1[];
      type: UserActionType.UPDATE_USERS;
    }
  | {
      payload: UserV1;
      type: UserActionType.ADD_USER;
    }
  | {
      payload: UserV1;
      type: UserActionType.UPDATE_USER;
    }
  | {
      payload: {
        connectivityStatus: ConnectivityStatusV1;
        externalUserId: string;
      };
      type: UserActionType.UPDATE_USER_CONNECTIVITY_STATUS;
    };
