import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { useSetupEnvConfig } from '@aviobook/_hooks/useConfigureEnvConfig';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  AppConfigContextProvider,
  AuthenticationContextProvider,
  EnvConfigContextProvider,
  FlightroomsContextProvider,
  SocketContextProvider,
  UsersContextProvider,
} from 'shared';

import { AppContextProvider, OfflineMessagesContextProvider, UnsavedChangesContextProvider } from './_context';
import { AxiosInterceptorProvider } from './_http/AxiosInterceptorProvider';
import { queryClient } from './_http/queryClient';
import { NavigationGuard, Spinner } from './_shared';
import { ProviderComposer } from './_utils/compose';
import { App } from './App';

import 'translations';

const RootComponent: React.FC = () => {
  const { envConfig, error, loading } = useSetupEnvConfig();

  if (loading) {
    return <Spinner overlay />;
  }

  if (error ?? !envConfig) {
    return <div>Error: {error}</div>;
  }

  return (
    <EnvConfigContextProvider env={envConfig}>
      <AppConfigContextProvider>
        <QueryClientProvider client={queryClient}>
          <AuthenticationContextProvider>
            <AxiosInterceptorProvider>
              <SocketContextProvider queryClient={queryClient}>
                <ProviderComposer
                  providers={[
                    AppContextProvider,
                    UsersContextProvider,
                    FlightroomsContextProvider,
                    OfflineMessagesContextProvider,
                    UnsavedChangesContextProvider,
                  ]}
                >
                  <App />
                  <NavigationGuard />
                  <ReactQueryDevtools initialIsOpen={false} />
                </ProviderComposer>
              </SocketContextProvider>
            </AxiosInterceptorProvider>
          </AuthenticationContextProvider>
        </QueryClientProvider>
      </AppConfigContextProvider>
    </EnvConfigContextProvider>
  );
};

const router = createBrowserRouter(createRoutesFromElements(<Route element={<RootComponent />} path="*" />));

createRoot(document.getElementById('root')!).render(
  <Suspense fallback={<Spinner overlay />}>
    <RouterProvider router={router} />
  </Suspense>,
);
