import { FlightRoom, FlightRoomUserStatus, PaginationV1, RequestOptionsV1 } from 'types';

import { DEFAULT_PAGE_SIZE } from '../../constants';
import { API_FACTORY, HttpClient, Params, parseRequestOptionsV1ToUrlParams, RequestOptionsRecords } from '../../utils';
import { getFlightroomByIdFiql } from './fiqls/flightRoomFiqls';

export type FlightRoomsQueryOptions = RequestOptionsV1<FlightRoom> & {
  flightRoomUserStatus?: FlightRoomUserStatus[];
  unreadMessagesCount?: boolean;
  useCustomFiqlTransformation?: boolean;
};

export const flightRoomsQuery: FlightRoomsQueryOptions = {
  limit: DEFAULT_PAGE_SIZE,
  unreadMessagesCount: false,
  useCustomFiqlTransformation: false,
};

export const getFlightRooms = (query: FlightRoomsQueryOptions): Promise<PaginationV1<FlightRoom>> => {
  return HttpClient.get<PaginationV1<FlightRoom>>(API_FACTORY.v2('rooms'), parseFlightRoomsQueryOptions(query) as Params);
};

export const getFlightroomById = (id: string): Promise<PaginationV1<FlightRoom>> => {
  return HttpClient.get<PaginationV1<FlightRoom>>(
    API_FACTORY.v2('rooms'),
    parseFlightRoomsQueryOptions({
      queryString: getFlightroomByIdFiql(id),
      useCustomFiqlTransformation: true,
    }) as Params,
  );
};

export const getFlightroomByIdAndUserIsActive = (id: FlightRoom['externalId']) => {
  const query: FlightRoomsQueryOptions = {
    flightRoomUserStatus: [FlightRoomUserStatus.ACTIVE],
    limit: 1,
    queryString: getFlightroomByIdFiql(id),
    useCustomFiqlTransformation: true,
  };

  return HttpClient.get<PaginationV1<FlightRoom>>(API_FACTORY.v2('rooms'), parseFlightRoomsQueryOptions(query) as Params);
};

export const joinFlightRoom = (externalRoomId: FlightRoom['externalId']): Promise<void> => {
  return HttpClient.post<void>(API_FACTORY.v2(`rooms/${encodeURIComponent(externalRoomId)}/join`));
};

export const parseFlightRoomsQueryOptions = (options?: FlightRoomsQueryOptions) => {
  const params: RequestOptionsRecords & {
    flightRoomUserStatus?: FlightRoomUserStatus[];
    unreadMessagesCount?: boolean;
    useCustomFiqlTransformation?: boolean;
  } = parseRequestOptionsV1ToUrlParams(options);

  if (options?.flightRoomUserStatus) {
    params.flightRoomUserStatus = options.flightRoomUserStatus;
  }

  params.unreadMessagesCount = options?.unreadMessagesCount ?? false;
  params.useCustomFiqlTransformation = options?.useCustomFiqlTransformation ?? false;

  return params;
};
