import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { Tab, TabRouter } from '@aviobook/cms/_shared/tabRouter/TabRouter';
import { QuickRepliesPermissions, RolesPermissions, SmartActionPermissions } from 'types';

export const Connect = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const MAX_BREAD_CRUMB_DEPTH = 4;

  const tabs: Tab[] = useMemo(
    () => [
      {
        label: t('TAB.GENERAL.TITLE'),
        name: 'General',
        order: 0,
        permissions: [
          SmartActionPermissions.VIEW,
          SmartActionPermissions.ADD,
          SmartActionPermissions.DELETE,
          SmartActionPermissions.UPDATE,
          QuickRepliesPermissions.ADD,
          QuickRepliesPermissions.VIEW,
        ],
        url: '/admin/connect/general',
      },
      {
        label: t('TAB.ROLES.TITLE'),
        name: 'Roles',
        order: 1,
        permissions: [RolesPermissions.VIEW],
        url: '/admin/connect/roles',
      },
    ],
    [t],
  );

  const isBreadCrumbDepthAllowed = useCallback(() => {
    return location.pathname.split('/').filter(Boolean).length <= MAX_BREAD_CRUMB_DEPTH;
  }, [location.pathname]);

  const showTabs = useMemo(
    () => tabs.some(t => location.pathname === t.url || isBreadCrumbDepthAllowed()),
    [tabs, location.pathname, isBreadCrumbDepthAllowed],
  );

  return (
    <>
      {showTabs ? <TabRouter navBarClassName="top-nav-bar" tabs={tabs} textSize="XL" /> : null}
      <Outlet />
    </>
  );
};
