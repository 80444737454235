import { useTranslation } from 'react-i18next';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { ApiError, QueryKeys, SmartAction } from 'types';

const smartActions = (values: SmartAction): Promise<SmartAction> =>
  HttpClient.post(API_FACTORY.v1('/admin/smart-actions'), values);

export const useCreateSmartAction = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  return useMutation<SmartAction, ApiError, SmartAction>([QueryKeys.SMART_ACTIONS], smartActions, {
    onError: (error: ApiError) => {
      toastError(error.message ?? t('TOAST_ERROR'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.SMART_ACTIONS]);
      toastSuccess(t('TOAST_SUCCESS'));
      onSuccess();
    },
  });
};
