import React, { ComponentType } from 'react';
import { ClearIndicatorProps, components, GroupBase } from 'react-select';
import { Icon } from '@aviobook/_shared';
import { COLORS } from 'styles';
import { Option } from 'types';

export const ClearIndicator: ComponentType<ClearIndicatorProps<Option, boolean, GroupBase<Option>>> | undefined = ({
  ...props
}: ClearIndicatorProps<Option, boolean>) => (
  <components.ClearIndicator {...props}>
    {props.children}
    <Icon colorName={COLORS.zulu.$08} name="close" />
  </components.ClearIndicator>
);
