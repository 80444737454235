import { Mention } from 'types';

export const getArrayOfTextSplitByMention = (text: string, mentions: string[]): string[] => {
  if (!mentions.length) {
    return [text];
  }
  const mentionsPattern = mentions.map(role => `@${role.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}`).join('|');

  const mentionRegex = new RegExp(`(${mentionsPattern})\\s*`, 'g');
  return text?.split(mentionRegex);
};

export const getMentionInProgressValue = (value: string): string => {
  if (value.startsWith('@')) {
    return value.substring(1);
  } else {
    return value;
  }
};

export const getMentionInProgress = (message: string, pattern: string, cursorPosition: number): string => {
  const isMentionInProgress = (word: string) => {
    return word.startsWith('@') && !new RegExp(pattern).test(word);
  };

  if (message) {
    const words = message.split(/\s+/);

    let startIndex = 0;
    const currentWord = words.find(word => {
      const indexOfCurrentWord = message.indexOf(word, startIndex);
      startIndex += word.length;
      return cursorPosition >= indexOfCurrentWord && cursorPosition <= indexOfCurrentWord + word.length;
    });

    if (currentWord && isMentionInProgress(currentWord)) {
      const startIndexOfWord = message.indexOf(currentWord, startIndex - currentWord.length);
      return message.substring(startIndexOfWord, cursorPosition);
    }
  }

  return '';
};

export const getMentionsRegex = (options: Mention[]) => {
  if (!options.length) {
    return 'a^';
  }
  return `@(${options
    .map(mention => `\\b${mention.label.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}\\b(?!\\w)`)
    .sort((a, b) => b.length - a.length) // Sort by length to match longest mentions first
    .join('|')})`;
};
