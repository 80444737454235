import { useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Spinner } from '@aviobook/_shared';
import { SaveButton } from '@aviobook/cms/_shared';
import { PermissionsUtil, SetUtil, useAuthenticationContext } from 'shared';
import { ConnectPermission, RolesPermissions } from 'types';

import { usePermissions } from './_hooks';
import PermissionGroups, { AllPermissions } from './permission-groups';
import { ActionTypes, reducer } from './reducer';

import './rolePermissions.scss';

const allPermissionsAsSet = new Set(AllPermissions);

export const RolePermissions = () => {
  const { t } = useTranslation();

  const { user } = useAuthenticationContext();

  const ownPermissions = new PermissionsUtil(user);

  const { isLoading, permissions, updatePermissions } = usePermissions();

  const startPermissions = useRef(permissions);

  const [state, dispatch] = useReducer(reducer, permissions);

  const areAllChecked = useMemo(() => SetUtil.containSameEntries(state, allPermissionsAsSet), [state]);

  const canUpdate = ownPermissions.hasPermission(RolesPermissions.UPDATE);

  const saveDisabled = !canUpdate || SetUtil.containSameEntries(state, startPermissions.current);

  if (isLoading) {
    return <Spinner />;
  }

  const onChangeGroup = (checked: boolean, permissions: ConnectPermission[]) => {
    dispatch({
      payload: permissions,
      type: checked ? ActionTypes.ADD : ActionTypes.REMOVE,
    });
  };

  const onChangeSingle = (checked: boolean, permission: string) => {
    dispatch({
      payload: [permission as ConnectPermission],
      type: checked ? ActionTypes.ADD : ActionTypes.REMOVE,
    });
  };

  const onSavePermissions = () => {
    updatePermissions(state);
    startPermissions.current = state;
  };

  return (
    <main className="permissions-table-container">
      <div className="permissions-header">
        <SaveButton isDisabled={saveDisabled} onClick={onSavePermissions} />
      </div>
      <table className="permissions-table">
        <thead className="permission-table--header">
          <tr className="permission-group">
            <th className={`permission-group--title ${!canUpdate ? 'permission-disabled' : null}`}>
              <Checkbox
                checked={areAllChecked}
                className="permission-wrapper permission-wrapper--title"
                disabled={!canUpdate}
                label={t('ROLES.PERMISSIONS.TABLE.HEADER.TITLE')}
                name="select-all"
                onChange={checked => onChangeGroup(checked, AllPermissions)}
              />
            </th>
            <th className="permission-group--section">
              <div className="permission-wrapper permission-wrapper--title">
                <span>{t('ROLES.PERMISSIONS.TABLE.HEADER.PERMISSIONS')}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="permission-table--body">
          {PermissionGroups.map(({ key, permissions }) => (
            <tr className="permission-group" key={key}>
              <td className={`permission-group--title ${!canUpdate ? 'permission-disabled' : null}`}>
                <Checkbox
                  checked={permissions.map(({ permission }) => permission).every(permission => state.has(permission))}
                  className="permission-wrapper permission-wrapper--body"
                  data-test="permission-checkbox"
                  disabled={!canUpdate}
                  label={t(`ROLES.PERMISSIONS.KEYS.${key}`)}
                  name={t(`ROLES.PERMISSIONS.KEYS.${key}`)}
                  onChange={checked =>
                    onChangeGroup(
                      checked,
                      permissions.map(({ permission }) => permission),
                    )
                  }
                />
              </td>
              <td className="permission-group--section">
                {permissions.map(({ action, permission }) => (
                  <div className={`permission-group--cell ${!canUpdate ? 'permission-disabled' : null}`} key={permission}>
                    <Checkbox
                      checked={state.has(permission)}
                      className="permission-wrapper permission-wrapper--body"
                      disabled={!ownPermissions.hasPermission(RolesPermissions.UPDATE)}
                      label={t(`ROLES.PERMISSIONS.ACTIONS.${action}`)}
                      name={permission}
                      onChange={onChangeSingle}
                    />
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
};
