import { useCallback } from 'react';
import { MessageUnion, useAuthenticationContext, useFlightroomsContext, useSocket, useSocketEvent } from 'shared';
import { UserMessageV1 } from 'types';

export const useFlightroomsUnreadCount = (isFocused: boolean) => {
  const { socket } = useSocket();
  const { user: currentUser } = useAuthenticationContext();
  const { activeFlightrooms, completedFlightrooms, incrementMessageCountOfRoom, selectedFlightroom, updateMessageCountOfRoom } =
    useFlightroomsContext();

  const resetUnreadCounts = useCallback(() => {
    [...completedFlightrooms, ...activeFlightrooms].forEach(room => {
      updateMessageCountOfRoom(room.externalId, 0);
    });
  }, [activeFlightrooms, completedFlightrooms, updateMessageCountOfRoom]);

  const resetUnreadCountsForRoom = useCallback(
    (roomId: string) => {
      updateMessageCountOfRoom(roomId, 0);
    },
    [updateMessageCountOfRoom],
  );

  const processMessageForRoom = useCallback(
    (message: MessageUnion) => {
      if (
        isFocused &&
        message.room !== selectedFlightroom?.externalId &&
        (message as UserMessageV1)?.user !== currentUser?.externalId
      ) {
        const roomId = message.room;
        const allRooms = [...activeFlightrooms, ...completedFlightrooms];
        const room = allRooms.find(room => room.externalId === roomId);

        if (room) {
          incrementMessageCountOfRoom(room);
        }
      }
    },
    [
      activeFlightrooms,
      completedFlightrooms,
      currentUser?.externalId,
      incrementMessageCountOfRoom,
      isFocused,
      selectedFlightroom?.externalId,
    ],
  );

  useSocketEvent(socket, 'message.create', processMessageForRoom);

  return {
    activeFlightrooms,
    completedFlightrooms,
    resetUnreadCounts,
    resetUnreadCountsForRoom,
  };
};
