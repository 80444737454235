import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { COLORS } from 'styles';
import { Option } from 'types';

import { Text } from '../../_shared';

import '../about.scss';

type Props = Option & { onClick?: (value: string) => void };

export const InfoItem: FC<Props> = ({ label, onClick, value }) => {
  const { t } = useTranslation();

  return (
    <li>
      <Text color={COLORS.zulu.$08} size="SM" weight="semiBold">
        {t(`ABOUT.${label}`)}
      </Text>
      <Text
        className={classNames('about-version__info', { 'about-version__info--copyable': !!onClick })}
        color={onClick ? COLORS.romeo.$10 : COLORS.zulu.$08}
        size="SM"
      >
        <span onClick={onClick ? () => onClick(value) : onClick} role="presentation">
          {value}
        </span>
      </Text>
    </li>
  );
};
