import {
  Division,
  FlightRoom,
  FlightRoomUserType,
  GiftedMessage,
  JobTitleV1,
  MessageStatus,
  MessageTypes,
  RoomRoleTitle,
  SystemMessageV1,
  UserMessageV1,
  UserV1,
} from 'types';

import { MessageUnion, SocketUserMessageV1 } from '../../hooks';
import { replaceJobTitlePlaceholders } from '../strings';
import { UserV1Util } from '../users';

export class MessageV1Util {
  static processSystemMessage(input: Required<SystemMessageV1>['text']): string {
    return replaceJobTitlePlaceholders(input);
  }
}

export const mapStringToUserMessage = (
  flightroomId: string,
  userExternalId: string,
  retryId: string,
  pendingImage?: File | null,
  message: string = '',
): UserMessageV1 => {
  const timestamp = new Date().toISOString();
  const id = retryId;

  return {
    createdAt: timestamp,
    id: id,
    meta: {
      clientMessageId: id,
    },
    pendingImage: pendingImage ?? undefined,
    receipts: [],
    room: flightroomId,
    status: MessageStatus.PENDING,
    text: message.trim() || undefined,
    type: MessageTypes.USER,
    updatedAt: timestamp,
    user: userExternalId,
  };
};

export const mapUserMessageToSocketMessage = (message: UserMessageV1): SocketUserMessageV1 => {
  return {
    attachment: message.attachment,
    createdAt: message.createdAt,
    id: message.id,
    mentions: message.mentions,
    meta: {
      clientMessageId: message.id,
    },
    receipts: [],
    room: message.room,
    status: message.status,
    text: message.text,
    type: message.type,
    updatedAt: message.updatedAt,
    user: message.user,
  };
};

export const mapToChatMessage =
  <T extends string = string>(iconMapper: (arg: RoomRoleTitle | JobTitleV1) => T) =>
  (room: FlightRoom, users: UserV1[], message: MessageUnion, showUserNames = true): GiftedMessage<T> => {
    const user = users?.find(u => u.externalId === (message as UserMessageV1)?.user);
    const roomUser = room.users?.find(u => u.externalId === (message as UserMessageV1)?.user);

    let title: FlightRoomUserType['name'] | Division = Division.UNKNOWN;
    let icon: T = iconMapper(user?.jobTitle ?? JobTitleV1.UNKNOWN);

    if (roomUser) {
      if (roomUser.scheduled) {
        title = roomUser.type.name;
        icon = iconMapper(roomUser.type.role);
      } else if (user?.jobTitle) {
        title = UserV1Util.getGuestUserString(user.jobTitle || JobTitleV1.UNKNOWN);
      }
    }

    return {
      _id: message.id,
      createdAt: new Date(message.createdAt),
      delivered: message.status === MessageStatus.DELIVERED,
      failed: message.status === MessageStatus.FAILED,
      image: message.attachment,
      mentions: message.mentions,
      pending: message.status === MessageStatus.PENDING || Boolean(message?.pendingImage),
      read: message.status === MessageStatus.READ,
      sent: message.status === MessageStatus.SENT,
      status: message.status,
      system: message.type === MessageTypes.SYSTEM,
      systemMessageLevel: (message as SystemMessageV1).level,
      text: (message as UserMessageV1)?.text ?? '',
      user: {
        _id: (message as UserMessageV1)?.user ?? '',
        icon,
        name: !user || !showUserNames ? undefined : UserV1Util.getFullName(user),
        title,
      },
    };
  };
