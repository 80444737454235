import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { toastSuccess } from '@aviobook/Toastify';
import { AppConfigOption, useAppConfigContext } from 'shared';
import { COLORS } from 'styles';
import { License } from 'types';

import licensesAsObject from '../../licenses.json';
import { Text } from '../_shared';
import { InfoItem } from './infoItem/InfoItem';
import { LicenseItem } from './licenseItem/LicenseItem';

import './about.scss';

export const About = () => {
  const { appConfig } = useAppConfigContext();
  const { t } = useTranslation();
  const [, copyToClipboard] = useCopyToClipboard();

  const handleCopy = (value: string) => {
    copyToClipboard(value);
    toastSuccess(t('MESSAGE.COPY.COPIED'));
  };

  const metadata: AppConfigOption[] = Object.entries(licensesAsObject.metadata).map(
    ([key, value]) => ({ label: key, value }) as AppConfigOption,
  );

  const infoItems: AppConfigOption[] = Object.entries(appConfig)
    .filter(([, value]) => value)
    .map(([key, value]) => ({ label: key, value }) as AppConfigOption)
    .concat(metadata);

  const renderInfoItem = (appConfigOption: AppConfigOption) => {
    const infoItem = { ...appConfigOption, label: appConfigOption.label.toUpperCase() };
    return (
      <InfoItem key={infoItem.label} onClick={appConfigOption.label === 'deviceId' ? handleCopy : undefined} {...infoItem} />
    );
  };

  const licenses: License[] = Object.entries(licensesAsObject.dependencies).map(([name, fields]) => ({ name, ...fields }));
  const renderLicense = (license: License) => <LicenseItem key={license.name} {...license} />;

  const yearRelease = new Date(licensesAsObject.metadata.generatedAt).getUTCFullYear();

  return (
    <>
      <div className="about-version-container">
        <Text className="about-version__title" color={COLORS.romeo.$06} size="SM" weight="bold">
          {t('ABOUT.APP_CONFIGURATION')}
        </Text>
        <ul>{infoItems.map(renderInfoItem)}</ul>
      </div>
      <div className="about-copyright-container">
        <Text className="about-copyright-text" color={COLORS.romeo.$06} size="SM" weight="bold">
          {t('ABOUT.COPYRIGHT')}
        </Text>
        <Text color={COLORS.zulu.$10} size="SM" weight="semiBold">
          {t('ABOUT.YEAR_RELEASE', { yearRelease })}
        </Text>
      </div>
      <div className="about-licenses-container">
        <Text className="about-licenses-text" color={COLORS.romeo.$06} size="SM" weight="bold">
          {t('ABOUT.LICENSES')}
        </Text>
        <ul className="about-licenses-list">{licenses.map(renderLicense)}</ul>
      </div>
    </>
  );
};
