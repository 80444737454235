import { FC } from 'react';
import { MessageProps } from 'react-native-gifted-chat';
import { IconName } from 'assets';
import { formatTime, MessageV1Util } from 'shared';
import { GiftedMessage, Mention } from 'types';

import { MessageBubble, SystemMessage, UnreadIndicator } from '../index';

type Props = {
  firstUnreadMessage: string;
  message: MessageProps<GiftedMessage<IconName>>;
  onDelete: (message: GiftedMessage<IconName>) => void;
  onRetry: (message: GiftedMessage<IconName>, pendingImage: File | null) => void;
  pendingImage: File | null;
  setActiveMention: (mention: Mention) => void;
  setAttachmentPreview: (attachment: string) => void;
  showMessageInfo: () => void;
  userId: string;
};

export const GiftedChatMessage: FC<Props> = ({
  firstUnreadMessage,
  message,
  onDelete,
  onRetry,
  pendingImage,
  setActiveMention,
  setAttachmentPreview,
  showMessageInfo,
  userId,
}) => {
  const renderUnreadIndicator =
    firstUnreadMessage === message.currentMessage?.messageId && message.currentMessage.user._id !== userId;
  return (
    <div className="chatbubble">
      {message.currentMessage?.system ? (
        <SystemMessage
          key={message.currentMessage.id}
          level={message.currentMessage.systemMessageLevel}
          message={MessageV1Util.processSystemMessage(message.currentMessage.text)}
          time={formatTime(new Date(message.currentMessage.createdAt)) ?? ''}
        />
      ) : (
        <MessageBubble
          {...message}
          onClickAttachment={setAttachmentPreview}
          onDelete={onDelete}
          onRetry={onRetry}
          pendingImage={pendingImage}
          setActiveMention={setActiveMention}
          showMessageInfo={showMessageInfo}
        />
      )}

      {renderUnreadIndicator ? <UnreadIndicator /> : null}
    </div>
  );
};
