import { useInfiniteQuery } from '@tanstack/react-query';
import { FlightRoom, PaginationV1, QueryKeys } from 'types';

import { useDebounce } from '../../../hooks';
import { SortAscending } from '../../../utils';
import { getSearchFiql } from '../fiqls/flightRoomFiqls';
import { flightRoomsQuery, getFlightRooms } from '../requests';

export const useSearchFlightRooms = (search: string) => {
  const debouncedSearchQuery = useDebounce(search, 300);

  return useInfiniteQuery<PaginationV1<FlightRoom>>({
    cacheTime: 0,
    getNextPageParam: ({ items, limit, offset }) => {
      if (items?.length !== limit) {
        return undefined;
      }

      return offset + limit;
    },
    queryFn: ({ pageParam = 0 }) =>
      getFlightRooms({
        ...flightRoomsQuery,
        offset: pageParam,
        // query: getSearchFiql(search),
        // TODO: convert back to query
        queryString: getSearchFiql(debouncedSearchQuery),
        sort: [new SortAscending<FlightRoom>('schedule.std')],
        useCustomFiqlTransformation: true,
      }),
    queryKey: [QueryKeys.SEARCH_FLIGHTROOM, debouncedSearchQuery],
    staleTime: 0,
  });
};
