import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getAnalytics as getGoogleAnalytics, logEvent as firebaseLogEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { deleteToken, getMessaging, getToken, Messaging, onMessage } from 'firebase/messaging';
import { API_FACTORY, HttpClient, useAuthenticationContext, useEnvConfig, useFlightroomsContext } from 'shared';

import { WebNotificationType } from '../open-web-notification/web-notification.types';

type FirebaseConfigContext = {
  deleteFirebaseToken: () => void;
  logEvent: (eventName: string, eventParams?: Record<string, string>) => void;
  unregisterServiceWorker: () => void;
};

const FirebaseConfigContext = createContext<FirebaseConfigContext>({
  deleteFirebaseToken: () => {},
  logEvent: () => {},
  unregisterServiceWorker: () => {},
});

export const useFirebaseConfigContext = () => useContext(FirebaseConfigContext);

export const FirebaseConfigContextProvider = ({ children }: PropsWithChildren) => {
  const firebaseScope = '/firebase-push-notification-scope';
  const {
    firebaseApiKey,
    firebaseAppId,
    firebaseAuthDomain,
    firebaseMessagingSenderId,
    firebaseProjectId,
    firebaseStorageBucket,
    firebaseVapidKey,
  } = useEnvConfig();
  const { user } = useAuthenticationContext();
  const { selectedFlightroom } = useFlightroomsContext();
  const [messaging, setMessaging] = useState<Messaging>();
  const [serviceWorker, setServiceWorker] = useState<ServiceWorkerRegistration>();

  const firebaseConfig = useMemo(
    () => ({
      apiKey: firebaseApiKey ?? '',
      appId: firebaseAppId ?? '',
      authDomain: firebaseAuthDomain ?? '',
      messagingSenderId: firebaseMessagingSenderId ?? '',
      projectId: firebaseProjectId ?? '',
      storageBucket: firebaseStorageBucket ?? '',
    }),
    [firebaseApiKey, firebaseAppId, firebaseAuthDomain, firebaseMessagingSenderId, firebaseProjectId, firebaseStorageBucket],
  );

  const getFirebaseToken = useCallback(async () => {
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);
    setMessaging(messaging);

    const getOrRegisterServiceWorker = () => {
      if ('serviceWorker' in navigator && typeof window.navigator.serviceWorker !== 'undefined') {
        return window.navigator.serviceWorker.getRegistration(firebaseScope).then(serviceWorker => {
          if (serviceWorker) {
            serviceWorker.update();
            return serviceWorker;
          }
          return window.navigator.serviceWorker.register(`/firebase-messaging-sw.js?${new URLSearchParams(firebaseConfig)}`, {
            scope: firebaseScope,
          });
        });
      }
    };

    if (messaging) {
      const serviceWorkerRegistration = await getOrRegisterServiceWorker();
      setServiceWorker(serviceWorkerRegistration);
    }
  }, [firebaseConfig]);

  const analytics = useMemo(() => {
    return getGoogleAnalytics(initializeApp(firebaseConfig));
  }, [firebaseConfig]);

  const logEvent = useCallback(
    (eventName: string, eventParams?: Record<string, string>) => {
      firebaseLogEvent(analytics, eventName, eventParams);
    },
    [analytics],
  );

  const unregisterServiceWorker = useCallback(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration(firebaseScope).then(registration => {
        if (registration) {
          registration.unregister();
        }
      });
    }
  }, []);

  const deleteFirebaseToken = useCallback(() => {
    if (messaging) {
      deleteToken(messaging);
    }
  }, [messaging]);

  useEffect(() => {
    if (user?.externalId) {
      getFirebaseToken();
    }
  }, [getFirebaseToken, user?.externalId]);

  useEffect(() => {
    if (serviceWorker?.active && 'serviceWorker' in navigator && messaging) {
      window.navigator.serviceWorker.getRegistration(firebaseScope).then(serviceWorkerRegistration => {
        if (serviceWorkerRegistration) {
          getToken(messaging, { serviceWorkerRegistration, vapidKey: firebaseVapidKey })
            .then((token: string) => HttpClient.post(API_FACTORY.v1('users/fcm-token'), { fcmToken: token }))
            // eslint-disable-next-line no-console
            .catch(error => console.error('An error occurred while retrieving token. ', error));
        }
      });
    }
  }, [firebaseVapidKey, messaging, serviceWorker?.active]);

  useEffect(() => {
    if (messaging && serviceWorker) {
      onMessage(messaging, payload => {
        const roomId = payload.data?.link.split('/').at(-1);
        if (roomId !== selectedFlightroom?.externalId) {
          serviceWorker.showNotification(payload.notification?.title ?? '', {
            body: payload.notification?.body ?? '',
            data: {
              url: `/open-notification/${WebNotificationType.OPEN_FLIGHTROOM}/${roomId}`,
            },
            icon: '/favicon.ico',
            silent: false,
            tag: payload?.messageId,
          });
        }
      });
    }
  }, [selectedFlightroom, messaging, serviceWorker]);

  return useMemo(
    () => (
      <FirebaseConfigContext.Provider
        value={{
          deleteFirebaseToken,
          logEvent,
          unregisterServiceWorker,
        }}
      >
        {children}
      </FirebaseConfigContext.Provider>
    ),
    [children, deleteFirebaseToken, logEvent, unregisterServiceWorker],
  );
};
