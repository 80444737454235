import { Platform } from 'react-native';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig, ResponseType } from 'axios';
import qs from 'qs';
import { ApiError, HttpStatus, ResponseErrorCodesV1, ValidationError } from 'types';

import { AppConfig, EnvConfig } from '../../context';
import { getHeaders } from '../headers';
import { ErrorResponse, Headers, Params } from './http.types';

class HttpClient {
  static config: EnvConfig;
  static requestInterceptorResult: number;
  static responseInterceptorResult: number;

  static readonly axios = Axios.create({
    headers: HttpClient.getBasicHeaders(),
  });

  static readonly requestInterceptor = (
    request: InternalAxiosRequestConfig,
    appConfig: AppConfig,
  ): InternalAxiosRequestConfig => {
    request.headers.set(getHeaders(appConfig));
    return request;
  };

  static readonly errorInterceptor =
    (onError: (response: ErrorResponse) => void) => async (error: AxiosError<{ code: ResponseErrorCodesV1 }>) => {
      const errorResponse = error.response;
      if (errorResponse) {
        onError(errorResponse);
      }
      return Promise.reject(error);
    };

  static setConfig(config: EnvConfig) {
    HttpClient.config = config;
    HttpClient.axios.defaults.baseURL = HttpClient.config.connectServiceApiHost;
  }

  static setHeaders(appConfig: AppConfig) {
    if (HttpClient.requestInterceptorResult) {
      HttpClient.axios.interceptors.request.eject(HttpClient.requestInterceptorResult);
    }
    HttpClient.requestInterceptorResult = HttpClient.axios.interceptors.request.use(request =>
      HttpClient.requestInterceptor(request, appConfig),
    );
  }

  static setInstances(onError: (response: ErrorResponse) => void) {
    HttpClient.responseInterceptorResult = HttpClient.axios.interceptors.response.use(
      response => response,
      HttpClient.errorInterceptor(onError),
    );
  }

  static resetInstances() {
    if (HttpClient.responseInterceptorResult) {
      HttpClient.axios.interceptors.response.eject(HttpClient.responseInterceptorResult);
    }
  }

  static getBasicHeaders(): Headers {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      platform: Platform.OS,
    } as Headers;
  }

  static createApiError(error: AxiosError<ApiError>): ApiError {
    if (error.response) {
      const data: {
        code?: string;
        error?: string;
        message?: string | Array<ValidationError & { property: string }>;
      } = error.response.data;
      return {
        error: data.error,
        message: this.mapErrorToMessage(data.message) || (data.code ?? undefined),
        statusCode: error.response.status,
        validationErrors: Array.isArray(data.message)
          ? data.message.reduce(
              (acc: Record<string, ValidationError>, { property, ...validationError }) => ({
                ...acc,
                [property]: validationError,
              }),
              {},
            )
          : {},
      };
    }
    return {
      message: error.message,
      statusCode: HttpStatus.INTERNAL_SERVER_ERROR,
    };
  }

  static async getRaw<T>(
    route: string,
    params: Params = {},
    headers: Headers = {} as Headers,
    responseType: ResponseType = 'json',
  ): Promise<AxiosResponse<T>> {
    try {
      return await HttpClient.axios.get<T>(route, {
        headers,
        params,
        paramsSerializer: {
          serialize: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        },
        responseType,
        withCredentials: true,
      });
    } catch (error) {
      throw this.createApiError(error as AxiosError<ApiError>);
    }
  }

  static async get<T>(
    route: string,
    params: Params = {},
    headers: Headers = {} as Headers,
    responseType: ResponseType = 'json',
  ): Promise<T> {
    const result = await this.getRaw<T>(route, params, headers, responseType);
    return result.data;
  }

  static async put<T>(
    route: string,
    body: Record<string, unknown> = {},
    headers: Headers = {} as Headers,
    params: Params = {},
  ): Promise<T> {
    try {
      const result = await HttpClient.axios.put<T>(route, body, {
        headers,
        params,
        paramsSerializer: {
          serialize: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        },
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error as AxiosError<ApiError>);
    }
  }

  static async patch<T>(route: string, body: Record<string, unknown> = {}, headers: Headers = {} as Headers): Promise<T> {
    try {
      const result = await HttpClient.axios.patch<T>(route, body, {
        headers,
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error as AxiosError<ApiError>);
    }
  }

  static async post<T>(
    route: string,
    body: Record<string, unknown> | string | FormData = {},
    headers: Headers = {} as Headers,
    withCredentials = true,
  ): Promise<T> {
    try {
      const result = await HttpClient.axios.post<T>(route, body, {
        headers,
        // Prevent axios from trying to transform FormData
        transformRequest: [
          data => {
            if (data instanceof FormData) {
              return data;
            }
            if (typeof data === 'object') {
              return JSON.stringify(data);
            }
            return data as string;
          },
        ],

        withCredentials,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error as AxiosError<ApiError>);
    }
  }

  static async delete<T>(route: string, headers: Headers = {} as Headers): Promise<T> {
    try {
      const result = await HttpClient.axios.delete<T>(route, {
        headers,
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error as AxiosError<ApiError>);
    }
  }

  static async retry(config: AxiosRequestConfig): Promise<AxiosResponse> {
    try {
      return await HttpClient.axios(config);
    } catch (error) {
      throw this.createApiError(error as AxiosError<ApiError>);
    }
  }

  private static mapErrorToMessage(message?: string | Array<ValidationError & { property: string }>): string {
    if (typeof message === 'string') {
      return message;
    }

    if (Array.isArray(message)) {
      return message.join(', ');
    }

    return '';
  }
}

export default HttpClient;
