import { UserV1 } from 'types';

import { UserAction, UserActionType } from './Users.actions';

type UserMap = Map<UserV1['externalId'], UserV1>;

type State = {
  users: UserMap;
};

export const userReducer = (state: State, { payload, type }: UserAction) => {
  const newState = { ...state };
  switch (type) {
    case UserActionType.UPDATE_USERS: {
      payload.forEach(user => newState.users.set(user.externalId, user));
      const updatedUsers = new Map(newState.users);
      return { ...newState, users: updatedUsers };
    }
    case UserActionType.ADD_USER: {
      const updatedUsers = new Map(newState.users.set(payload.externalId, payload));
      return { ...newState, users: updatedUsers };
    }
    case UserActionType.UPDATE_USER: {
      const existingUser = newState.users.get(payload.externalId);
      const updatedUser = {
        ...payload,
        connectivityStatus: existingUser?.connectivityStatus,
      };
      const updatedUsers = new Map(newState.users.set(payload.externalId, updatedUser));
      return { ...newState, users: updatedUsers };
    }
    case UserActionType.UPDATE_USER_CONNECTIVITY_STATUS: {
      const { connectivityStatus, externalUserId } = payload;
      const user = newState.users.get(externalUserId);
      if (user) {
        user.connectivityStatus = connectivityStatus;
        const updatedUsers = new Map(newState.users.set(externalUserId, user));
        return { ...newState, users: updatedUsers };
      }
      return newState;
    }
    default:
      return state;
  }
};
