import { useInfiniteQuery } from '@tanstack/react-query';
import { FlightArrivalStatus, FlightRoom, FlightRoomFilterState, PaginationV1, QueryKeys } from 'types';
import { FlightRoomUserStatus } from 'types/src/room';

import { useAuthenticationContext, useFlightroomsContext } from '../../context';
import { useAuthenticatedUser } from '../../hooks';
import { SortAscending } from '../../utils';
import { getRoomArrivalFiql } from './fiqls/flightRoomFiqls';
import { flightRoomsQuery, FlightRoomsQueryOptions, getFlightRooms } from './requests';

export const useGetFlightRooms = (
  status: FlightArrivalStatus.COMPLETED | FlightArrivalStatus.ACTIVE,
  isSearch?: boolean,
  includeUnreadCount?: boolean,
  filters: FlightRoomFilterState | null = null,
) => {
  const { user } = useAuthenticationContext();
  const { canViewFlightRooms } = useAuthenticatedUser();
  const { setActiveFlightrooms, setCompletedFlightrooms } = useFlightroomsContext();

  const updateFlightRooms = (pages: PaginationV1<FlightRoom>[]) => {
    const flightRooms = pages.reduce((accu: FlightRoom[], page) => [...accu, ...(page.items ?? [])], []);
    if (status === FlightArrivalStatus.ACTIVE) {
      setActiveFlightrooms([...flightRooms]);
    } else if (status === FlightArrivalStatus.COMPLETED) {
      setCompletedFlightrooms([...flightRooms]);
    }
  };

  return useInfiniteQuery<PaginationV1<FlightRoom>>({
    cacheTime: 0,
    enabled: canViewFlightRooms,
    getNextPageParam: ({ items, limit, offset }) => {
      if (items?.length !== limit) {
        return undefined;
      }

      return offset + limit;
    },
    onSuccess: ({ pages }) => {
      if (canViewFlightRooms) {
        updateFlightRooms(pages);
      }
    },
    queryFn: ({ pageParam = 0 }) => {
      if (!canViewFlightRooms) {
        // Don't fetch data when user can not view flightrooms
        return Promise.resolve({
          items: [],
          limit: 0,
          offset: 0,
          total: 0,
        });
      }

      const query: FlightRoomsQueryOptions & { unreadMessagesCount: boolean } = {
        ...flightRoomsQuery,
        flightRoomUserStatus: isSearch ? undefined : [FlightRoomUserStatus.ACTIVE, FlightRoomUserStatus.PENDING],
        offset: pageParam,
        // TODO: convert back to query
        // query: getRoomArrivalFiql(status),
        queryString: getRoomArrivalFiql(status, filters),
        sort: [new SortAscending<FlightRoom>('schedule.std')],
        unreadMessagesCount: includeUnreadCount ?? false,
        useCustomFiqlTransformation: true,
      };

      return getFlightRooms(query);
    },
    queryKey: [QueryKeys.FLIGHTROOMS, status, user?.externalId, filters],
    refetchOnMount: canViewFlightRooms,
    staleTime: 0,
  });
};
