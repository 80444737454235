import { useTranslation } from 'react-i18next';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { ApiError, QueryKeys, SmartAction } from 'types';

const smartActions = (values: SmartAction): Promise<SmartAction> => {
  return HttpClient.patch(API_FACTORY.v1(`admin/smart-actions/${values.id}`), {
    apiKey: values.apiKey,
    description: values.description,
    isRequestPublic: values.isRequestPublic,
    isResponsePublic: values.isResponsePublic,
    keywords: values.keywords,
    name: values.name,
    url: values.url,
  });
};

export const useUpdateSmartAction = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  return useMutation<SmartAction, ApiError, SmartAction>([QueryKeys.SMART_ACTIONS], smartActions, {
    onError: (error: ApiError) => {
      toastError(error.message ?? t('TOAST_ERROR'));
    },
    onSuccess: data => {
      queryClient.invalidateQueries([QueryKeys.SMART_ACTIONS]);
      queryClient.invalidateQueries([QueryKeys.SMART_ACTION, data.id]);
      toastSuccess(t('TOAST_SUCCESS'));
      onSuccess();
    },
  });
};
