import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlightRoomFilterValue } from '@aviobook/_types/FlightRoomFilterValue';
import { AppliedFiltersCollapse } from '@aviobook/flightrooms/_components/filters/AppliedFiltersCollapse';
import { FlightroomDashboardTabs } from '@aviobook/flightrooms/flightroomDashboardTabs';
import { NotificationCenter } from '@aviobook/notification-center/NotificationCenter';
import { Profile } from '@aviobook/profile/Profile';
import { useAuthenticatedUser, useFlightroomsContext, useNotificationsContext } from 'shared';
import { COLORS } from 'styles';
import { FlightArrivalStatus, FlightRoomFilterState } from 'types';

import { Icon, SearchField, UnreadDot } from '../../../_shared';
import { TabBar } from '../tabBar/TabBar';
import { TabBar as TabBarType } from '../tabBar/TabBar.types';

import './flightroomsHeaderComponent.scss';

type Props = {
  filters: FlightRoomFilterState | null;
  handleFlightRoomFilterClose: () => void;
  handleFlightRoomFilterDelete: (value: FlightRoomFilterValue, filterKey: keyof FlightRoomFilterState) => void;
  handleFlightRoomFilterReset: () => void;
  searchQuery: string;
  selectedFlightroomTab: FlightroomDashboardTabs;
  setSearchQuery: (query: string) => void;
  setSelectedFlightroomTab: (roomStatus: FlightroomDashboardTabs) => void;
};

export const FlightRoomsHeaderComponent: FC<Props> = ({
  filters,
  handleFlightRoomFilterClose,
  handleFlightRoomFilterDelete,
  handleFlightRoomFilterReset,
  searchQuery,
  selectedFlightroomTab,
  setSearchQuery,
  setSelectedFlightroomTab,
}) => {
  const { t } = useTranslation();
  const { canSearchFlightRooms, canViewFlightRooms } = useAuthenticatedUser();
  const [isNotificationCenterVisible, setIsNotificationCenterVisible] = useState(false);
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const { unreadNotificationsCount } = useNotificationsContext();
  const { isFiltersOpen, openFilters } = useFlightroomsContext();

  const tabs: TabBarType[] = [
    {
      id: FlightArrivalStatus.ACTIVE,
      onClick: () => setSelectedFlightroomTab(FlightArrivalStatus.ACTIVE),
      text: t('FLIGHTROOMS.ARRIVAL_STATUS.ACTIVE'),
    },
    {
      id: FlightArrivalStatus.COMPLETED,
      onClick: () => setSelectedFlightroomTab(FlightArrivalStatus.COMPLETED),
      text: t('FLIGHTROOMS.ARRIVAL_STATUS.COMPLETED'),
    },
  ];

  const isSearchEnabled = canViewFlightRooms && canSearchFlightRooms;

  const selectedFlightRoomTab = tabs.find(({ id }) => selectedFlightroomTab === id)?.id ?? FlightArrivalStatus.ACTIVE;

  return (
    <>
      <header className="flightrooms-header-container">
        <div className="flightrooms-header-top-container">
          <div className="flightrooms-header-top-left" />
          <div className="flightrooms-header-top-right">
            <button
              className="flightrooms-header-notification-icon"
              disabled={!canViewFlightRooms}
              onClick={() => setIsNotificationCenterVisible(true)}
            >
              {canViewFlightRooms && unreadNotificationsCount > 0 ? (
                <UnreadDot amount={unreadNotificationsCount} className="flightrooms-header-notification-count" fontSize="XS" />
              ) : null}
              <Icon
                className="notification-icon"
                colorName={canViewFlightRooms ? COLORS.zulu.$08 : COLORS.zulu.$04}
                name="notificationBell"
                size={2.5}
              />
            </button>
            <Icon colorName={COLORS.zulu.$08} name="profileOutline" onClick={() => setIsProfileVisible(true)} size={2.5} />
          </div>
        </div>
        <div className="flightrooms-header-search" data-test="search-bar">
          <SearchField disabled={!isSearchEnabled} search={searchQuery} setSearch={setSearchQuery} />
          <button
            className="flightrooms-header-filter-icon"
            onClick={() => (isFiltersOpen ? handleFlightRoomFilterClose() : openFilters())}
          >
            <Icon colorName={COLORS.zulu.$08} name={'sliderFilter'} size={2.5} />
            {filters ? <div className="flightrooms-header-filter-icon--notification" /> : null}
          </button>
        </div>
        <AppliedFiltersCollapse
          filters={filters}
          handleFlightRoomFilterDelete={handleFlightRoomFilterDelete}
          handleFlightRoomFilterReset={handleFlightRoomFilterReset}
          selectedTab={selectedFlightRoomTab}
        />
        {!searchQuery ? (
          <div className="flightrooms-header-nav-container">
            <TabBar selected={selectedFlightRoomTab} tabs={tabs} />
          </div>
        ) : null}
      </header>
      <NotificationCenter isVisible={isNotificationCenterVisible} onClose={() => setIsNotificationCenterVisible(false)} />
      <Profile isVisible={isProfileVisible} onClose={() => setIsProfileVisible(false)} />
    </>
  );
};
