import { MessageUnion } from '../../hooks';
import { ChatMessagesActionType } from './chatMessagesAction.enum';
import { ChatMessagesActions } from './chatMessagesAction.type';

type State = {
  error: Error | null;
  messages: Map<MessageUnion['meta']['clientMessageId'], MessageUnion>;
};

export const chatMessagesReducer = (state: State, { payload, type }: ChatMessagesActions): State => {
  switch (type) {
    case ChatMessagesActionType.ADD_MESSAGE: {
      const messageId = payload.meta?.clientMessageId ?? payload.id;
      const updatedMessages = new Map(state.messages.set(messageId, payload));
      return { ...state, messages: updatedMessages };
    }
    case ChatMessagesActionType.REMOVE_MESSAGE: {
      const updatedMessages = new Map(state.messages);
      updatedMessages.delete(payload);
      return { ...state, messages: updatedMessages };
    }
    case ChatMessagesActionType.SET_ERROR: {
      return { ...state, error: payload };
    }
    case ChatMessagesActionType.ADD_MESSAGES: {
      const updatedMessages = new Map(state.messages);
      payload.forEach(message => {
        const messageId = message.meta?.clientMessageId ?? message.id;
        updatedMessages.set(messageId, message);
      });
      return { ...state, messages: updatedMessages };
    }
    case ChatMessagesActionType.UPDATE_MESSAGES_STATUS: {
      const updatedMessages = new Map(state.messages);
      payload.forEach(messageReceipt => {
        const message = updatedMessages.get(messageReceipt.meta?.clientMessageId ?? messageReceipt.id);
        if (message) {
          message.receipts = messageReceipt.receipts;
          message.status = messageReceipt.status;
        }
      });
      return { ...state, messages: updatedMessages };
    }
    default:
      return state;
  }
};
