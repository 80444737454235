import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { Tab, TabRouter } from '@aviobook/cms/_shared/tabRouter/TabRouter';
import { QuickRepliesPermissions, SmartActionPermissions } from 'types';

export const General: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const tabs: Tab[] = useMemo(
    () => [
      {
        label: t('TAB.GENERAL.SMART_ACTIONS'),
        name: 'smartActions',
        order: 0,
        permissions: [SmartActionPermissions.VIEW],
        url: '/admin/connect/general/smart-actions',
      },
      {
        label: t('QUICK_REPLIES.MULTI'),
        name: 'quickReplies',
        order: 1,
        permissions: [QuickRepliesPermissions.VIEW],
        url: '/admin/connect/general/quick-replies',
      },
    ],
    [t],
  );

  const showTabs = useMemo(() => tabs.some(t => t.url === location.pathname), [tabs, location.pathname]);

  return (
    <>
      {showTabs ? <TabRouter tabs={tabs} /> : null}
      <Outlet />
    </>
  );
};
