import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '@aviobook/_hooks';
import { toastError } from '@aviobook/Toastify';

import { useGetRole } from '../../_queries';

export const useRole = () => {
  const { t } = useTranslation();
  const { roleId } = useParams();
  const updateBreadCrumbs = useBreadcrumbs(state => state.findAndReplace);
  const { data, isError, isLoading, isSuccess } = useGetRole(roleId ?? '');

  const updateBreadCrumbsName = useCallback(() => {
    if (isSuccess) {
      updateBreadCrumbs(roleId ?? '', { name: data.airlineRole, url: `/admin/connect/roles/${roleId ?? ''}` });
    }
  }, [data, isSuccess, roleId, updateBreadCrumbs]);

  useEffect(() => {
    updateBreadCrumbsName();
  }, [updateBreadCrumbsName]);

  if (isError) {
    toastError(t('TOAST_ERROR'));
  }

  return { data, isError, isLoading, updateBreadCrumbsName };
};
