import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

type UnsavedChangesContext = {
  hasUnsavedChanges: boolean;
  setUnsavedChanges: (state: boolean) => void;
};

const UnsavedChangesContext = createContext<UnsavedChangesContext>({
  hasUnsavedChanges: false,
  setUnsavedChanges: () => {},
});

export const useUnsavedChangesContext = () => useContext(UnsavedChangesContext);

export const UnsavedChangesContextProvider = ({ children }: PropsWithChildren) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const unSavedChangesValue = useMemo(
    () => ({
      hasUnsavedChanges,
      setUnsavedChanges: setHasUnsavedChanges,
    }),
    [hasUnsavedChanges],
  );

  return <UnsavedChangesContext.Provider value={unSavedChangesValue}>{children}</UnsavedChangesContext.Provider>;
};
