import { FlightRoom } from 'types';

export enum FlightRoomActionType {
  'ADD_FLIGHTROOM',
  'UPDATE_FLIGHTROOM',
  'SET_ACTIVE_FLIGHTROOMS',
  'UPDATE_ACTIVE_FLIGHTROOMS',
  'SET_COMPLETED_FLIGHTROOMS',
  'UPDATE_COMPLETED_FLIGHTROOMS',
  'REMOVE_FLIGHTROOM',
  'UPDATE_FLIGHTROOM_MESSAGE_COUNT',
  'INCREMENT_FLIGHTROOM_MESSAGE_COUNT',
}

export type FlightRoomAction =
  | {
      payload: FlightRoom;
      type: FlightRoomActionType.ADD_FLIGHTROOM;
    }
  | {
      payload: FlightRoom;
      type: FlightRoomActionType.UPDATE_FLIGHTROOM;
    }
  | {
      payload: FlightRoom[];
      type: FlightRoomActionType.SET_ACTIVE_FLIGHTROOMS;
    }
  | {
      payload: FlightRoom[];
      type: FlightRoomActionType.UPDATE_ACTIVE_FLIGHTROOMS;
    }
  | {
      payload: FlightRoom[];
      type: FlightRoomActionType.SET_COMPLETED_FLIGHTROOMS;
    }
  | {
      payload: FlightRoom[];
      type: FlightRoomActionType.UPDATE_COMPLETED_FLIGHTROOMS;
    }
  | {
      payload: FlightRoom;
      type: FlightRoomActionType.REMOVE_FLIGHTROOM;
    }
  | {
      payload: { count: number; roomId: string };
      type: FlightRoomActionType.UPDATE_FLIGHTROOM_MESSAGE_COUNT;
    }
  | {
      payload: { roomId: string };
      type: FlightRoomActionType.INCREMENT_FLIGHTROOM_MESSAGE_COUNT;
    };
