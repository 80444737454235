import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorName, COLORS } from 'styles';

import { isCompleted } from '../../utils';
import { DateTime } from '../../utils/date-time/lib/avio-date-time.model';
import { AvioDateTime, parseDate } from '../../utils/date-time/lib/avio-date-time.util';

type CountdownProps = {
  ata?: DateTime;
  atd?: DateTime;
  eta?: DateTime;
  etd?: DateTime;
  sta: DateTime;
  std: DateTime;
};

type CountdownState = {
  countdownLabel: string;
  countdownString: string;
  countdownTextColor: ColorName;
  isCompleted: boolean;
};

const formatTimeString = (totalSeconds: number) => {
  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${days > 0 ? `${days}d ` : ''}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
    seconds,
  ).padStart(2, '0')}`;
};

const useCountDown = ({ ata, atd, eta, etd, sta, std }: CountdownProps): CountdownState => {
  const { t } = useTranslation();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const previousCountdownState = useRef<CountdownState | null>(null);
  const [currentCountdownState, setCurrentCountdownState] = useState<CountdownState | null>(null);

  const targetTime = useMemo(() => {
    const departureDate = atd ? std : (etd ?? std);
    return parseDate(departureDate).value.getTime();
  }, [atd, etd, std]);

  const calculateCountdownState = useCallback(
    (isCompleted: boolean): CountdownState => {
      const currentTime = atd ? parseDate(atd).value.getTime() : AvioDateTime().value.getTime();
      const timeDifference = targetTime - currentTime;
      const isTargetDatePassed = timeDifference < 0;

      const absoluteDifference = Math.abs(timeDifference);
      const totalSeconds = Math.floor(absoluteDifference / 1000);

      return {
        countdownLabel: atd ? t('COUNTDOWN.OUT') : etd ? t('COUNTDOWN.ETD') : t('COUNTDOWN.STD'),
        countdownString: `${isTargetDatePassed ? '+' : '-'}${formatTimeString(totalSeconds)}`,
        countdownTextColor: isTargetDatePassed ? COLORS.fuji.$06 : COLORS.everest.$07,
        isCompleted: isCompleted,
      };
    },
    [atd, targetTime, t, etd],
  );

  useEffect(() => {
    const tick = () => {
      const isRoomCompleted = isCompleted(sta, eta, ata);

      if (atd) {
        setCurrentCountdownState(prevState => {
          if (!prevState || prevState.countdownLabel !== t('COUNTDOWN.OUT')) {
            return calculateCountdownState(isRoomCompleted);
          }
          return { ...prevState, isCompleted: isRoomCompleted };
        });
      } else {
        const newState = calculateCountdownState(isRoomCompleted);
        // Update state only if it has changed
        if (!previousCountdownState.current || JSON.stringify(newState) !== JSON.stringify(previousCountdownState.current)) {
          setCurrentCountdownState(newState);
          previousCountdownState.current = newState;
        }
      }

      if (!isRoomCompleted) {
        // Schedule the next tick
        const now = Date.now();
        const msUntilNextSecond = 1000 - (now % 1000);
        intervalRef.current = setTimeout(tick, msUntilNextSecond);
      }
    };

    tick(); // Initial tick

    return () => {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
      }
    };
  }, [calculateCountdownState, atd, sta, eta, ata, t]);

  return currentCountdownState ?? calculateCountdownState(isCompleted(sta, eta, ata));
};

export default useCountDown;
