export const SUPPORTED_MIME_TYPES = [
  {
    extensions: ['.jpg', '.jpeg'],
    mimeType: 'image/jpeg',
    validFileSignatureCheck: (signature: string) => JPEG_SIGNATURES.some(jpegSignature => signature.startsWith(jpegSignature)),
  },
  {
    extensions: ['.png'],
    mimeType: 'image/png',
    validFileSignatureCheck: (signature: string) => PNG_SIGNATURES.some(pngSignature => signature.startsWith(pngSignature)),
  },
  {
    extensions: ['.webp'],
    mimeType: 'image/webp',
    validFileSignatureCheck: (signature: string) =>
      WEBP_SIGNATURES.some(webpSignature => {
        const [header, content] = webpSignature;
        return signature.startsWith(header) && signature.includes(content);
      }),
  },
  {
    extensions: ['.svg'],
    mimeType: 'image/svg+xml',
    validFileSignatureCheck: (_: string) => false, // No binary signature, needs to be checked on text content
  },
  {
    extensions: ['.heic'],
    mimeType: 'image/heic',
    validFileSignatureCheck: (signature: string) =>
      HEIC_SIGNATURES.some(heicSignature => {
        const [header, content] = heicSignature;
        return signature.includes(header) && signature.includes(content);
      }),
  },
  {
    extensions: ['.heif'],
    mimeType: 'image/heif',
    validFileSignatureCheck: (signature: string) =>
      HEIF_SIGNATURES.some(heifSignature => {
        const [header, content] = heifSignature;
        return signature.includes(header) && signature.includes(content);
      }),
  },
  {
    extensions: ['.bmp'],
    mimeType: 'image/bmp',
    validFileSignatureCheck: (signature: string) => BMP_SIGNATURES.some(bmpSignature => signature.startsWith(bmpSignature)),
  },
  {
    extensions: ['.avif'],
    mimeType: 'image/avif',
    validFileSignatureCheck: (signature: string) =>
      AVIF_SIGNATURES.some(avifSignature => {
        const [header, content] = avifSignature;
        return signature.includes(header) && signature.includes(content);
      }),
  },
];

export const APPLE_ONLY_FILE_TYPES = ['.heif', '.heic'];

export const MAX_IMAGE_SIZE = 12582912; // 12mb (= 12 * 1024 * 1024)

const JPEG_SIGNATURES = [
  'FFD8FF',
  'FFD8FFE0',
  'FFD8FFE1',
  'FFD8FFE2',
  'FFD8FFE3',
  'FFD8FFE8',
  'FFD8FFED',
  'FFD8FFEE',
  'FFD8FFDB',
  'FFD8FFEF',
];

const PNG_SIGNATURES = ['89504E47'];

const WEBP_SIGNATURES = [
  ['52494646', '57454250'], // Lookout, since this is also the magic number for AVI and WAV
];

const HEIC_SIGNATURES = [
  ['66747970', '68656963'],
  ['66747970', '68656978'],
];

const HEIF_SIGNATURES = [
  ['66747970', '68656966'],
  ['66747970', '6D696631'],
];

const BMP_SIGNATURES = ['424D'];

const AVIF_SIGNATURES = [
  ['66747970', '61766966'],
  ['66747970', '6D696631'],
];
