import React, {
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { InputToolbarProps } from 'react-native-gifted-chat';
import { MentionOptions, MentionOptionsRef } from '@aviobook/chat/_components/mentionOptions/MentionOptions';
import { useAuthenticatedUser, useChatMessagesContext, useMentions } from 'shared';
import { getMentionInProgress } from 'shared/src/utils/mentions/MentionUtils';
import { GiftedMessage, Mention } from 'types';

import './mentionInput.scss';

type Props = InputToolbarProps<GiftedMessage> & {
  icon: ReactElement;
  message: string;
  sendDisabled: boolean;
  sendMessage: () => void;
  setMessage: (message: string) => void;
};

export type RelevantMentionSection = {
  data: Mention[];
  title: string;
};

export type MentionInputRef = {
  sendText: (text: string) => void;
};

export const MentionInput = forwardRef<MentionInputRef, Props>(
  ({ icon, message, sendDisabled, sendMessage, setMessage }, ref) => {
    const [mentionInProgress, setMentionInProgress] = useState('');
    const [cursorPositionX, setCursorPositionX] = useState(0);
    const [maxRightPosition, setMaxRightPosition] = useState(0);
    const [cursorIndex, setCursorIndex] = useState(0);
    const [isInputFocused, setIsInputFocused] = useState(true);
    const { t } = useTranslation();
    const { selectMention } = useChatMessagesContext();
    const { canMentionExternally } = useAuthenticatedUser();

    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);
    const suggestionListRef = useRef<MentionOptionsRef>(null);

    useImperativeHandle(ref, () => ({
      sendText: (text: string) => {
        const newMessage = insertText(text);
        const newTextInputIndex = cursorIndex + text.length + 1;
        setTextAndCursorPosition(newMessage, newTextInputIndex);
      },
    }));

    const { externalMentions, filteredMentionOptions, mentionsRegex } = useMentions(mentionInProgress.trim());

    useLayoutEffect(() => {
      const updateMaxRightPosition = () => {
        setMaxRightPosition(
          (containerRef.current?.getBoundingClientRect().right ?? 0) - (containerRef.current?.getBoundingClientRect().left ?? 0),
        );
      };
      updateMaxRightPosition();

      const observer = new ResizeObserver(() => {
        updateMaxRightPosition();
      });

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        observer.disconnect();
      };
    }, []);

    useEffect(() => {
      inputRef.current?.focus();
    }, [inputRef]);

    useEffect(() => {
      const mention = getMentionInProgress(message, mentionsRegex, cursorIndex);
      setMentionInProgress(mention);
    }, [message, mentionsRegex, cursorIndex]);

    // AVIO-44078 undo bold external mentions when permission lost and vice versa
    useEffect(() => {
      setTextAndCursorPosition(message, cursorIndex);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canMentionExternally]);

    const handleClickOutside = useCallback((event: MouseEvent) => {
      if (
        suggestionListRef.current &&
        !suggestionListRef.current.containsNode(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsInputFocused(false);
      }
    }, []);

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [handleClickOutside]);

    const makeMentionsBold = useCallback(
      (text: string) => {
        const pattern = new RegExp(mentionsRegex, 'g');
        return text.replace(pattern, match => {
          return `<span class="bold-text">${match}</span>`;
        });
      },
      [mentionsRegex],
    );

    const setTextAndCursorPosition = useCallback(
      (newText: string, cursorIndex: number) => {
        const editor = inputRef.current;
        if (!editor) {
          return;
        }

        editor.textContent = newText;
        editor.innerHTML = makeMentionsBold(newText);
        setMessage(newText);

        restoreCursorPositionAfterTextUpdate(cursorIndex);
        setCursorIndex(cursorIndex);
      },
      [makeMentionsBold, setMessage],
    );

    useEffect(() => {
      // reset inner text value of input field when message is sent
      if (inputRef.current && !message) {
        setTextAndCursorPosition('', 0);
      }
    }, [message, setTextAndCursorPosition]);

    const suggestions: Mention[] = useMemo(() => {
      return [...filteredMentionOptions, ...externalMentions];
    }, [filteredMentionOptions, externalMentions]);

    const relevantMentions: RelevantMentionSection[] = useMemo(() => {
      return [
        {
          data: filteredMentionOptions,
          title: t('CHAT.MENTIONS.INTERNAL'),
        },
        {
          data: externalMentions,
          title: t('CHAT.MENTIONS.EXTERNAL'),
        },
      ];
    }, [filteredMentionOptions, t, externalMentions]);

    const updateCursorIndex = () => {
      const index = getNewCursorPosition() ?? 0;

      setCursorIndex(index);
    };

    const handleEnter = () => {
      if (mentionInProgress && suggestions.length > 0) {
        if (suggestionListRef.current) {
          const currentIndex = suggestionListRef.current.index;
          let correctSuggestionIndex = currentIndex;
          if (currentIndex > suggestions.length - 1) {
            correctSuggestionIndex = 0;
          }
          setMention(suggestions[correctSuggestionIndex]);
        }
        return;
      }

      const canSendMessage = !sendDisabled;
      if (canSendMessage) {
        sendMessage();
      }
    };

    const handleArrowDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (mentionInProgress) {
        event.preventDefault();
        if (suggestionListRef.current) {
          suggestionListRef.current.setIndex((suggestionListRef.current.index + 1) % suggestions.length);
        }
      }
    };

    const handleArrowUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (mentionInProgress) {
        event.preventDefault();
        if (suggestionListRef.current) {
          const currentIndex = suggestionListRef.current.index;
          suggestionListRef.current.setIndex(currentIndex === 0 ? suggestions.length - 1 : currentIndex - 1);
        }
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      switch (event.code) {
        case 'Enter':
        case 'NumpadEnter':
          handleEnter();
          break;
        case 'ArrowDown':
          handleArrowDown(event);
          break;
        case 'ArrowUp':
          handleArrowUp(event);
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          requestAnimationFrame(updateCursorIndex);
          break;
        default:
          break;
      }
    };

    const insertText = useCallback(
      (text: string, from: number = cursorIndex, to: number = cursorIndex) => {
        return `${message.slice(0, from)}${text} ${message.slice(to, message.length)}`;
      },
      [cursorIndex, message],
    );

    const setMention = useCallback(
      (mention: Mention) => {
        const currentMention = getMentionInProgress(message, mentionsRegex, cursorIndex);
        const mentionIndex = message.lastIndexOf(currentMention);
        const newMessage = insertText(`@${mention.label}`, mentionIndex, mentionIndex + currentMention.length);

        selectMention(mention);
        // + 2 to take the space after the mention into account and the @ before
        const newTextInputIndex = mentionIndex + mention.label.length + 2;
        setTextAndCursorPosition(newMessage, newTextInputIndex);
      },
      [message, mentionsRegex, cursorIndex, insertText, selectMention, setTextAndCursorPosition],
    );

    const getNewCursorPosition = () => {
      const editor = inputRef.current;
      if (!editor) {
        return null;
      }

      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) {
        return null;
      }

      const range = selection.getRangeAt(0);
      const preRange = range.cloneRange();
      preRange.selectNodeContents(inputRef.current);
      preRange.setEnd(range.startContainer, range.startOffset);
      const cursorOffset = preRange.toString().length;

      return cursorOffset;
    };

    const restoreCursorPositionAfterTextUpdate = (cursorOffset: number | null) => {
      if (cursorOffset === null) {
        return;
      }

      const editor = inputRef.current;
      if (!editor) {
        return;
      }

      let offset = cursorOffset;
      const selection = window.getSelection();
      const range = document.createRange();
      range.setStart(editor, 0);
      range.collapse(true);

      const traverseNodes = (node: Node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          if (node.textContent!.length >= offset) {
            range.setStart(node, offset);
            range.collapse(true);
            return true;
          } else {
            offset -= node.textContent!.length;
          }
        } else {
          for (const child of Array.from(node.childNodes)) {
            if (traverseNodes(child)) {
              return true;
            }
          }
        }
        return false;
      };

      traverseNodes(editor);

      selection?.removeAllRanges();
      selection?.addRange(range);

      if (containerRef.current) {
        const parentRect = containerRef.current.getBoundingClientRect();
        setCursorPositionX(range.getBoundingClientRect().left - parentRect.left);
      }
    };

    const handleInput = () => {
      const editor = inputRef.current;
      if (!editor) {
        return;
      }

      const cursorIndex = getNewCursorPosition();

      setTextAndCursorPosition(editor.textContent ?? '', cursorIndex ?? (editor.textContent?.length ?? 1) - 1);
    };

    return (
      <div className="input-field" ref={containerRef}>
        {icon}
        <MentionOptions
          data={relevantMentions}
          maxRightPosition={maxRightPosition}
          onSelect={(mention: Mention) => setMention(mention)}
          ref={suggestionListRef}
          startPositionList={cursorPositionX}
          visible={!!mentionInProgress && isInputFocused && !!suggestions.length}
        />
        <div
          className={'message-input'}
          contentEditable="true"
          data-test={'mention-input'}
          onClick={updateCursorIndex}
          onFocus={() => setIsInputFocused(true)}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          placeholder={t('CHAT.INPUT.PLACEHOLDER')}
          ref={inputRef}
          role={'presentation'}
        ></div>
      </div>
    );
  },
);
