import { Icon, UnreadDot } from '@aviobook/_shared';
import { ModalOpener } from '@aviobook/modal/ModalOpener';
import { UserRequestAccessModal } from '@aviobook/modal/userRequestAccessModal/UserRequestAccessModal';
import { useAuthenticatedUser, useFlightroomsContext } from 'shared';
import { FlightRoomUserStatus } from 'types/src/room';

export const GrantAccess = () => {
  const { hasGrantAccess } = useAuthenticatedUser();
  const { selectedFlightroom: room } = useFlightroomsContext();
  const pendingRequests = room?.users?.filter(({ status }) => status === FlightRoomUserStatus.PENDING) ?? [];

  if (!hasGrantAccess || pendingRequests.length === 0) {
    return null;
  }

  const onClickRequestIcon = () => {
    ModalOpener.instance.open({
      render: () => (
        <UserRequestAccessModal
          onSuccess={() => {
            ModalOpener.instance.close();
          }}
        />
      ),
    });
  };

  return (
    <button
      className="chat-header-notification-icon"
      data-test="grant-access-button"
      disabled={pendingRequests.length === 0}
      onClick={onClickRequestIcon}
    >
      <UnreadDot amount={pendingRequests.length} fontSize="XS" testId="grant-access-counter" />
      <Icon name="peopleUserManagementUserCircularPlus" size={2.5} />
    </button>
  );
};
