import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FlightRoomFilter } from '@aviobook/_hooks';
import { Button, Checkbox, Icon, Text, TimePicker, Typeahead } from '@aviobook/_shared';
import { DatePicker } from '@aviobook/_shared/input/datePicker/DatePicker';
import classNames from 'classnames';
import { FlightroomFilterType, getFlightroomFilter, useFlightroomsContext } from 'shared';
import { COLORS } from 'styles';
import { FlightRoomFilterState } from 'types';

import './filters.scss';

export const Filters: FC<Omit<FlightRoomFilter, 'appliedFlightRoomFilters'>> = ({
  filterFormState,
  handleFlightRoomFilterChange,
  handleFlightRoomFilterClose,
  handleFlightRoomFilterReset,
  handleFlightRoomFilterSubmit,
}) => {
  const { t } = useTranslation();
  const { closeFilters, isFiltersOpen } = useFlightroomsContext();

  const handleSubmit = () => {
    handleFlightRoomFilterSubmit();
    closeFilters();
  };

  const getFilterURL = (filterType: FlightroomFilterType): string => {
    return getFlightroomFilter(filterType);
  };

  return (
    <div
      className={classNames({ 'filters-container': true, 'filters-container--hidden': !isFiltersOpen })}
      data-test="filters-container"
    >
      <div className="filters-title" data-test="filters-title">
        <Text as="h4" color={COLORS.zulu.$08} uppercase weight="bold">
          {t('FILTERS.TITLE')}
        </Text>
        <Icon name="closeCross" onClick={handleFlightRoomFilterClose} size={2.5} testId="filters-close-icon" />
      </div>
      <form className="filters-form">
        <div className="filters-form__block filters-form__row filters-form__row--gap">
          <div className="filters-form__col filters-form__col--gap">
            <Text as="span" className="filters-form__label" color={COLORS.zulu.$06} size="XS" weight="bold">
              {t('FILTERS.FILTER_FLIGHTS_IN')}
            </Text>
            <div style={{ display: 'flex' }}>
              <Checkbox
                checked={filterFormState.activeFlights}
                className="filters-form__checkbox"
                label={t('FILTERS.ACTIVE')}
                name="activeFlights"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                testId="filters-active-flights"
              />
              <Checkbox
                checked={filterFormState.landedFlights}
                className="filters-form__checkbox"
                label={t('FILTERS.LANDED')}
                name="landedFlights"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
              />
              <Checkbox
                checked={filterFormState.tasks}
                className="filters-form__checkbox"
                label={t('FILTERS.TASKS')}
                name="tasks"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
              />
            </div>
          </div>
          <div className="filters-form__col filters-form__col--gap">
            <Typeahead
              label={t('FILTERS.DESK_NUMBER')}
              name="desks"
              onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
              selectedOptions={filterFormState.desks}
              typeaheadConfig={{
                placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                url: getFilterURL(FlightroomFilterType.DESK),
              }}
            />
          </div>
        </div>
        <div className="filters-form__block">
          <Text as="h5" className="filters-form__block--title" color={COLORS.zulu.$08} size="SM" uppercase weight="bold">
            {t('FILTERS.FLIGHT_AIRCRAFT')}
          </Text>
          <div className="filters-form__row">
            <div className="filters-form__col">
              <Typeahead
                label={t('FILTERS.FLIGHT_NUMBER')}
                name="flightNumbers"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedOptions={filterFormState.flightNumbers}
                typeaheadConfig={{
                  placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                  url: getFilterURL(FlightroomFilterType.FLIGHT_NUMBER),
                }}
              />
            </div>
            <div className="filters-form__col">
              <Typeahead
                label={t('FILTERS.CALL_SIGN')}
                name="callSigns"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedOptions={filterFormState.callSigns}
                typeaheadConfig={{
                  placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                  url: getFilterURL(FlightroomFilterType.ATC_CALL_SIGN),
                }}
              />
            </div>
          </div>
          <div className="filters-form__row">
            <div className="filters-form__col">
              <Typeahead
                label={t('FILTERS.AIRCRAFT_REGISTRATION')}
                name="aircraftRegistrations"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedOptions={filterFormState.aircraftRegistrations}
                typeaheadConfig={{
                  placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                  url: getFilterURL(FlightroomFilterType.AIRCRAFT),
                }}
              />
            </div>
          </div>
        </div>
        <div className="filters-form__block">
          <Text as="h5" className="filters-form__block--title" color={COLORS.zulu.$08} size="SM" uppercase weight="bold">
            {t('FILTERS.AIRPORTS')}
          </Text>
          <div className="filters-form__row">
            <div className="filters-form__col">
              <Typeahead
                label={t('FILTERS.DEPARTURE_ICAO')}
                name="departureIcaos"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedOptions={filterFormState.departureIcaos}
                typeaheadConfig={{
                  placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                  url: getFilterURL(FlightroomFilterType.DEPARTURE_AIRPORT_ICAO),
                }}
              />
            </div>
            <div className="filters-form__col filters-form__col--small">
              <Typeahead
                label={t('FILTERS.DEPARTURE_GATE')}
                name="departureGates"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedOptions={filterFormState.departureGates}
                typeaheadConfig={{
                  placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                  url: getFilterURL(FlightroomFilterType.DEPARTURE_GATE),
                }}
              />
            </div>
            <div className="filters-form__col">
              <Typeahead
                label={t('FILTERS.DESTINATION_ICAO')}
                name="destinationIcaos"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedOptions={filterFormState.destinationIcaos}
                typeaheadConfig={{
                  placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                  url: getFilterURL(FlightroomFilterType.DESTINATION_AIRPORT_ICAO),
                }}
              />
            </div>
            <div className="filters-form__col filters-form__col--small">
              <Typeahead
                label={t('FILTERS.DESTINATION_GATE')}
                name="destinationGates"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedOptions={filterFormState.destinationGates}
                typeaheadConfig={{
                  placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                  url: getFilterURL(FlightroomFilterType.DESTINATION_GATE),
                }}
              />
            </div>
          </div>
        </div>
        <div className="filters-form__block">
          <Text as="h5" className="filters-form__block--title" color={COLORS.zulu.$08} size="SM" uppercase weight="bold">
            {t('FILTERS.DEPARTURE_ARRIVAL')}
          </Text>
          <div className="filters-form__row">
            <div className="filters-form__col">
              <DatePicker
                label={t('FILTERS.DEPARTURE_FROM')}
                name="departureFromDate"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedValue={filterFormState.departureFromDate}
              />
            </div>
            <div className="filters-form__col filters-form__col--small filters-form__col--align-end">
              <TimePicker
                name="departureFromTime"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedValue={filterFormState.departureFromTime}
              />
            </div>
            <div className="filters-form__col">
              <DatePicker
                label={t('FILTERS.DEPARTURE_TO')}
                name="departureToDate"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedValue={filterFormState.departureToDate}
              />
            </div>
            <div className="filters-form__col filters-form__col--small filters-form__col--align-end">
              <TimePicker
                name="departureToTime"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedValue={filterFormState.departureToTime}
              />
            </div>
          </div>
          <div className="filters-form__row">
            <div className="filters-form__col">
              <DatePicker
                label={t('FILTERS.ARRIVAL_FROM')}
                name="arrivalFromDate"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedValue={filterFormState.arrivalFromDate}
              />
            </div>
            <div className="filters-form__col filters-form__col--small filters-form__col--align-end">
              <TimePicker
                name="arrivalFromTime"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedValue={filterFormState.arrivalFromTime}
              />
            </div>
            <div className="filters-form__col">
              <DatePicker
                label={t('FILTERS.ARRIVAL_TO')}
                name="arrivalToDate"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedValue={filterFormState.arrivalToDate}
              />
            </div>
            <div className="filters-form__col filters-form__col--small filters-form__col--align-end">
              <TimePicker
                name="arrivalToTime"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedValue={filterFormState.arrivalToTime}
              />
            </div>
          </div>
        </div>
      </form>
      <div className="filters-button">
        <Button isOutline onClick={handleFlightRoomFilterReset} testId="filters-reset" theme="negative" type="reset">
          {t('FILTERS.RESET_FILTER')}
        </Button>
        <Button onClick={handleSubmit} testId="filters-submit" type="submit">
          {t('FILTERS.APPLY_FILTER')}
        </Button>
      </div>
    </div>
  );
};
