const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface DateTimeFormatModel {
  amPm: string;
  day: string;
  dayName: string;
  dayNoLeadingZero: string;
  hour: string;
  hourAmerican: string;
  millisecond: string;
  minute: string;
  month: string;
  monthName: string;
  second: string;
  year: string;
}

const formatDate = (date: DateTimeFormatModel, arg: string) =>
  arg
    .replace(/\byyyy\b/, date.year)
    .replace(/\bYYYY\b/, date.year)
    .replace(/\ba\b/, date.amPm)
    .replace(/\bMMMM\b/, date.monthName)
    .replace(/\bMMM\b/, date.monthName.slice(0, 3))
    .replace(/\bMM\b/, date.month)
    .replace(/\bHH\b/, `00${date.hour}`.slice(-2))
    .replace(/\bH\b/, date.hour)
    .replace(/\bhh\b/, `00${date.hourAmerican}`.slice(-2))
    .replace(/\bh\b/, date.hourAmerican)
    .replace(/\bdddd\b/, date.dayName)
    .replace(/\bddd\b/, date.dayName.slice(0, 3))
    .replace(/\bdd\b/, date.day)
    .replace(/\bDD\b/, date.day)
    .replace(/\bD\b/, date.dayNoLeadingZero)
    .replace(/\bmm\b/, date.minute)
    .replace(/\bss\b/, date.second)
    .replace(/\bSSS\b/, date.millisecond);

export const formatUTC = (date: Date, arg: string) => {
  const dateObject = {
    amPm: date.getUTCHours() > 12 ? 'pm' : 'am',
    day: `00${String(date.getUTCDate())}`.slice(-2),
    dayName: weekdays[date.getUTCDay()],
    dayNoLeadingZero: String(date.getUTCDate()),
    hour: String(date.getUTCHours()),
    hourAmerican: String(date.getUTCHours() > 12 ? date.getUTCHours() - 12 : date.getUTCHours()),
    millisecond: `000${String(date.getUTCMilliseconds())}`.slice(-3),
    minute: `00${String(date.getUTCMinutes())}`.slice(-2),
    month: `00${String(date.getUTCMonth() + 1)}`.slice(-2),
    monthName: monthNames[date.getUTCMonth()],
    second: `00${String(date.getUTCSeconds())}`.slice(-2),
    year: date.getUTCFullYear().toString(),
  };

  return formatDate(dateObject, arg);
};

export const format = (date: Date, arg: string) => {
  const dateObject = {
    amPm: date.getHours() > 12 ? 'pm' : 'am',
    day: `00${String(date.getDate())}`.slice(-2),
    dayName: weekdays[date.getDay()],
    dayNoLeadingZero: String(date.getDate()),
    hour: String(date.getHours()),
    hourAmerican: String(date.getHours() > 12 ? date.getHours() - 12 : date.getHours()),
    millisecond: `000${String(date.getMilliseconds())}`.slice(-3),
    minute: `00${String(date.getMinutes())}`.slice(-2),
    month: `00${String(date.getMonth() + 1)}`.slice(-2),
    monthName: monthNames[date.getMonth()],
    second: `00${String(date.getSeconds())}`.slice(-2),
    year: date.getFullYear().toString(),
  };

  return formatDate(dateObject, arg);
};

export const valueOf = (date: Date) => date.getTime();

export const toISOString = (date: Date) => date.toISOString();

export const UTC = (date: Date) => new Date(date.toUTCString());
