import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PermissionsUtil, useAuthenticationContext } from 'shared';
import { FontSize } from 'styles';
import { CombiningTranslationDict, ConnectPermission } from 'types';

import { TabBar } from '../../_routing/tabbar/TabBar';

export type Tab = {
  label: string;
  name: string;
  order: number;
  permissions: ConnectPermission[];
  url: string;
};

type Props = {
  navBarClassName?: 'top-nav-bar' | 'nav-bar';
  tabs: Tab[];
  textSize?: FontSize;
};

export const TabRouter: FC<Props> = ({ navBarClassName = 'nav-bar', tabs, textSize = 'MD' }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthenticationContext();

  const permissions = useMemo(() => {
    return new PermissionsUtil(user);
  }, [user]);

  const routes = useMemo(() => {
    return tabs
      .filter(tab => permissions.hasPermissions(tab.permissions, CombiningTranslationDict.OR))
      .sort((a, b) => a.order - b.order);
  }, [permissions, tabs]);

  const onSetTab = (tab: number) => {
    const route = tabs[tab];
    navigate(route.url);
  };

  const selectedTab = useMemo(() => {
    const tabIndex = routes.findIndex(({ url }) => location.pathname.startsWith(url));
    return tabIndex === -1 ? 0 : tabIndex;
  }, [location.pathname, routes]);

  return (
    <TabBar
      currentPage={selectedTab}
      navBarClassName={navBarClassName}
      setTab={onSetTab}
      tabRoutes={routes}
      textSize={textSize}
    />
  );
};
