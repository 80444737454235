import React, { FC } from 'react';
import { Avatar, Icon, Text } from '@aviobook/_shared';
import { IconName } from 'assets';
import classNames from 'classnames';
import { COLORS } from 'styles';

import './flightroomInfoItem.scss';

type Props = {
  avatar: IconName;
  onClick?: () => void;
  rightSection?: JSX.Element;
  subValue?: string | null;
  title: string;
  titleIcon?: JSX.Element | null;
  value: string;
};

export const FlightroomInfoItem: FC<Props> = ({ avatar, onClick, rightSection, subValue, title, titleIcon, value }) => {
  const isClickable = !!onClick;

  const handleClick = () => {
    if (!isClickable) {
      return;
    }
    onClick?.();
  };

  return (
    <div
      className={classNames('flightroom-info-item-container', isClickable && 'clickable')}
      data-test="flightroom-crewmember-item"
      onClick={handleClick}
    >
      <section className="flightroom-info-icon">
        <Avatar icon={avatar} isDisabled />
      </section>
      <section className="flightroom-info-text">
        <div className="flex-row">
          {titleIcon}
          <Text color={COLORS.romeo.$07} size="SM" testID="flightroom-info-item--title" weight="bold">
            {title}
          </Text>
        </div>
        <Text color={COLORS.zulu.$08} size="SM" testID="flightroom-info-item--value">
          {value}
        </Text>
        {subValue ? (
          <Text color={COLORS.zulu.$08} size="XS" testID="flightroom-info-item--sub-value">
            {subValue}
          </Text>
        ) : null}
      </section>
      <section className="flightroom-info-right">
        {rightSection}
        {isClickable && <Icon colorName={COLORS.bogota.$04} name="chevronRight" />}
      </section>
    </div>
  );
};
