import { FC, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useForm, { FormValidationErrors } from '@aviobook/_hooks/useForm';
import { Spinner } from '@aviobook/_shared';
import { RoleForm } from '@aviobook/cms/connect/_screens/roles/role/_tabs/roleDetails/roleform/RoleForm';
import { FormValidator, ValidatorResponse } from 'shared';
import { Role } from 'types';

import { ModalForm } from '../../../_components';
import { useGetRoles, useUpdateRole } from '../../../_queries';

type Props = {
  data: Role;
  isLoadingRole: boolean;
};

const validateForm = (values: Role): FormValidationErrors<Role> => {
  const validator = new FormValidator();

  return {
    // The validation logic needs a complete rethink, to also be done on change of the form
    airlineRole: validator.required(values.airlineRole),
    displayName: ((value: string): ValidatorResponse => {
      const requiredValidation = validator.required(value);
      if (!requiredValidation.isValid) {
        return requiredValidation;
      }

      const maxLengthValidation = validator.maxLength(value, 25);
      if (!maxLengthValidation.isValid) {
        return maxLengthValidation;
      }

      return validator.isAlphaNumeric(value);
    })(values.displayName),
  };
};

export const RoleDetails: FC<Props> = ({ data, isLoadingRole }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const onSuccess = () => navigate('/admin/connect/roles');
  const { isLoading: isUpdatingRole, mutate } = useUpdateRole(onSuccess);
  const { data: roles } = useGetRoles();

  const form = useForm<Role>({
    initialForm: data,
    submitForm: values => mutate(values),
    validateForm: values => validateForm(values),
  });

  if (isUpdatingRole || isLoadingRole) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return (
    <main className="update-role-container">
      <RoleForm
        form={form}
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          if (form.values.displayName !== data.displayName && roles?.find(role => role.displayName === form.values.displayName)) {
            event.preventDefault();

            setIsModalVisible(true);

            return;
          }

          form.submit(event);
        }}
      />
      {isModalVisible ? (
        <ModalForm
          disabled={false}
          headerTitle={t('ERRORS.WARNING')}
          isSubmitting={isUpdatingRole}
          onCancel={() => setIsModalVisible(false)}
          onSubmit={form.submit}
          submitButtonText={t('SHARED.BUTTONS.PROCEED')}
          testId="update-role-modal-confirmation"
        >
          {t('TAB.ROLES.MODAL_CONFIRMATION', { displayName: form.values.displayName })}
        </ModalForm>
      ) : null}
    </main>
  );
};
