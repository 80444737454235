import { FC, FormEvent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FlexComponent } from '@aviobook/cms/_shared';
import Modal from '@aviobook/modal/Modal';

type ModalFormProps = PropsWithChildren & {
  disabled: boolean;
  headerTitle: string;
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit: (event: FormEvent) => void;
  submitButtonText?: string;
  testId?: string;
};

export const ModalForm: FC<ModalFormProps> = ({
  children,
  disabled,
  headerTitle,
  isSubmitting,
  onCancel,
  onSubmit,
  submitButtonText,
  testId,
}) => {
  const { t } = useTranslation();

  return (
    <Modal testId={testId}>
      <Modal.Header onCancel={onCancel}>{headerTitle}</Modal.Header>
      <form onSubmit={onSubmit}>
        <FlexComponent flexDirection="column" gap={1} padding={2} width="full">
          {children}
        </FlexComponent>
        <Modal.Buttons>
          <Button isOutline onClick={onCancel} theme="neutral">
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
          <Button disabled={isSubmitting || disabled} loading={isSubmitting} theme="primary" type="submit">
            {submitButtonText ?? t('SHARED.BUTTONS.ADD')}
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
};
