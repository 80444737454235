import { Link } from 'react-router-dom';
import { Text } from '@aviobook/_shared';
import { CellContext } from '@tanstack/react-table';
import { COLORS } from 'styles';
import { Role } from 'types';

import './roles.scss';

export const RoleColumn = (props: CellContext<Role, string>) => {
  return (
    <Link to={`${props.row.original.id}`}>
      <Text as="span" color={COLORS.zulu.$06} size="MD" width="partial-width">
        {props.getValue()}
      </Text>
    </Link>
  );
};
