import { FlightArrivalStatus, FlightRoom, FlightRoomFilterState, NestedObjectsPathKeys, Option } from 'types';

import {
  AndFiql,
  AvioDateTime,
  EqualFiqlNode,
  GreaterThenOrEqualToFiqlNode,
  LessThenOrEqualToFiqlNode,
  OrFiql,
  RootFiql,
} from '../../../utils';

const getYesterday = () => AvioDateTime().subtract({ day: 1 }).toISO8601String();
const getToday = () => AvioDateTime().toISO8601String();

const getActiveFiql = (filters: FlightRoomFilterState | null = null) => {
  const filterFiql = filters?.activeFlights ? getFilterQueryFiql(filters) : '';
  const activeFiql = `(closed==false;schedule.processedArrivalTime=ge=${getToday()})`;
  return filterFiql ? `${activeFiql};${filterFiql}` : activeFiql;
};
const getCompletedFiql = (filters: FlightRoomFilterState | null = null) => {
  const filterFiql = filters?.landedFlights ? getFilterQueryFiql(filters) : '';
  const completedFiql = `(closed==false;schedule.processedArrivalTime=ge=${getYesterday()};schedule.processedArrivalTime=le=${getToday()})`;
  return filterFiql ? `${completedFiql};${filterFiql}` : completedFiql;
};
const getActiveAndCompletedFiql = () => `(${getActiveFiql()}),(${getCompletedFiql()})`;

export const getRoomArrivalFiql = (arrivalStatus: FlightArrivalStatus, filters: FlightRoomFilterState | null): string => {
  // TODO: convert to RootFiql and fix tests
  let roomArrivalFiql: string;

  switch (arrivalStatus) {
    case FlightArrivalStatus.COMPLETED:
      roomArrivalFiql = getCompletedFiql(filters);
      break;
    case FlightArrivalStatus.ACTIVE:
      roomArrivalFiql = getActiveFiql(filters);
      break;
    case FlightArrivalStatus.ALL_WITHIN_RANGE:
      roomArrivalFiql = getActiveAndCompletedFiql();
      break;
  }

  return roomArrivalFiql;
};

export const getSearchFiql = (search?: string): string => {
  // TODO: convert to RootFiql and fix tests
  if (!search) {
    return getActiveAndCompletedFiql();
  }

  const flightNumberRegexFiql = `flightNumber=caseinsensitive=${search}`;
  return `(${getActiveAndCompletedFiql()});(${flightNumberRegexFiql})`;
};

export const getFlightroomByIdFiql = (id: string): string => {
  return `(${getActiveAndCompletedFiql()});(externalId==${id})`;
};

const getFilterQueryFiql = (filters: FlightRoomFilterState): string => {
  const fiqlQuery: (OrFiql<FlightRoom> | AndFiql<FlightRoom>)[] = [];

  if (filters) {
    const addOrFiql = (values: readonly Option[], field: NestedObjectsPathKeys<Required<FlightRoom>>) => {
      if (values.length > 0) {
        fiqlQuery.push(new OrFiql(values.map(option => new EqualFiqlNode<FlightRoom>(field, option.value))));
      }
    };

    const addDateRangeFiql = (
      fromDate: Date | null,
      fromTime: string,
      toDate: Date | null,
      toTime: string,
      fields: NestedObjectsPathKeys<Required<FlightRoom>>[],
    ) => {
      if (fromDate) {
        const fromDateTime = AvioDateTime(fromDate).setHoursWithMinutes(fromTime).toISOString();
        fiqlQuery.push(new OrFiql(fields.map(field => new GreaterThenOrEqualToFiqlNode<FlightRoom>(field, fromDateTime))));
      }

      if (toDate) {
        const toDateTime = AvioDateTime(toDate).setHoursWithMinutes(toTime).toISOString();
        fiqlQuery.push(new OrFiql(fields.map(field => new LessThenOrEqualToFiqlNode<FlightRoom>(field, toDateTime))));
      }
    };

    addOrFiql(filters.desks, 'desk');
    addOrFiql(filters.flightNumbers, 'flightNumber');
    addOrFiql(filters.callSigns, 'atcCallSign');
    addOrFiql(filters.aircraftRegistrations, 'aircraft');

    if (filters.departureIcaos.length) {
      addOrFiql(filters.departureIcaos, 'departure.airport.icao');
      addOrFiql(filters.departureGates, 'departure.gate');
    }

    if (filters.destinationIcaos.length) {
      addOrFiql(filters.destinationIcaos, 'destination.airport.icao');
      addOrFiql(filters.destinationGates, 'destination.gate');
    }

    addDateRangeFiql(
      filters.departureFromDate,
      filters.departureFromTime?.value ?? '00:00',
      filters.departureToDate,
      filters.departureToTime?.value ?? '00:00',
      ['schedule.std', 'schedule.etd', 'schedule.atd'],
    );

    addDateRangeFiql(
      filters.arrivalFromDate,
      filters.arrivalFromTime?.value ?? '00:00',
      filters.arrivalToDate,
      filters.arrivalToTime?.value ?? '00:00',
      ['schedule.sta', 'schedule.eta', 'schedule.ata'],
    );
    return new RootFiql(new AndFiql(fiqlQuery)).parse();
  }
  return '';
};
