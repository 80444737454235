import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Pill } from '@aviobook/_shared';
import { FlightroomInfoItem } from '@aviobook/flightrooms/_components/flightroomInfoItem/FlightroomInfoItem';
import { COLORS } from 'styles';
import { Mention } from 'types';

type Props = {
  mention: Mention;
};

export const ExternalMentionInfoItem: FC<Props> = ({ mention }) => {
  const { t } = useTranslation();

  return (
    <FlightroomInfoItem
      avatar={'roleDefault'}
      rightSection={
        <Pill
          backgroundColor={COLORS.zulu.$04}
          fontSize={'XXS'}
          text={t('CHAT.MENTIONS.STATUS.PENDING')}
          textColor={COLORS.yuma.$01}
        />
      }
      title={mention.label.toUpperCase()}
      value={t('CHAT.MENTIONS.NOTIFIED')}
    />
  );
};
