import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toastError } from '@aviobook/Toastify';
import {
  EqualFiqlNode,
  FlightRoomUserWithName,
  OrFiql,
  RootFiql,
  useEnrichedFlightRoomUsers,
  useFlightroomsContext,
  useGetUsers,
  useGrantAccess,
} from 'shared';
import { COLORS } from 'styles';
import { ApiError, FlightRoomUserStatus, UserV1 } from 'types';

import { Button, Checkbox, Spinner, Text } from '../../_shared';
import Modal from '../Modal';
import { ModalOpener } from '../ModalOpener';

import './userRequestAccessModal.scss';

type Props = {
  onSuccess: () => void;
};

type SelectableUser = FlightRoomUserWithName & {
  selected: boolean;
};

export const UserRequestAccessModal: FC<Props> = ({ onSuccess }) => {
  const { selectedFlightroom: room } = useFlightroomsContext();
  const { t } = useTranslation();
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isDenyLoading, setIsDenyLoading] = useState(false);

  // Memoize requests to prevent unnecessary re-renders
  const pendingRequests = useMemo(() => {
    return room?.users?.filter(({ status }) => status === FlightRoomUserStatus.PENDING) ?? [];
  }, [room?.users]);

  // Fetch users based on requests
  const { data: users, isLoading: isLoadingUsers } = useGetUsers({
    // @ts-expect-error FIQL...
    query: new RootFiql(new OrFiql(pendingRequests.map(({ externalId }) => new EqualFiqlNode<UserV1>('externalId', externalId)))),
  });

  //close popup when no requests are available
  useEffect(() => {
    if (pendingRequests.length === 0) {
      onSuccess();
    }
  }, [pendingRequests, onSuccess]);

  const enrichedUsers = useEnrichedFlightRoomUsers(pendingRequests, users?.items);

  const [selectedUsers, setSelectedUsers] = useState<SelectableUser[]>([]);

  // Effect to set selected users when requests or users change
  useEffect(() => {
    setSelectedUsers(enrichedUsers.map(user => ({ ...user, selected: false })));
  }, [enrichedUsers, pendingRequests]);

  const { isLoading: isLoadingAccess, mutateAsync: grantAccess } = useGrantAccess({ onSuccess });

  const selected = selectedUsers.filter(({ selected }) => selected);

  const areSomeSelected = selected.length > 0;

  const areAllSelected = selected.length === selectedUsers.length;

  const toggleAll = () => {
    setSelectedUsers(prevSelectedUsers =>
      prevSelectedUsers.map(user => ({
        ...user,
        selected: !areAllSelected,
      })),
    );
  };

  const toggleSelect = (needle: SelectableUser) => {
    setSelectedUsers(prevSelectedUsers => {
      const index = prevSelectedUsers.findIndex(haystack => haystack.externalId === needle.externalId);
      return [
        ...prevSelectedUsers.slice(0, index),
        { ...needle, selected: !needle.selected },
        ...prevSelectedUsers.slice(index + 1),
      ];
    });
  };

  const handleAccessPermission = async (hasBeenGrantedAccess: boolean) => {
    if (selected.length === 0 || !room) {
      return;
    }

    await Promise.all(
      selected.map(({ externalId }) =>
        grantAccess({
          externalId: externalId,
          hasBeenGrantedAccess,
          roomId: room.externalId,
        }),
      ),
    )
      .then(
        () => {},
        () => toastError(t('ERRORS.ACCESS_REQUEST_FAILED')),
      )
      .catch((e: ApiError) => toastError(t(e.message ?? '')))
      .finally(() => ModalOpener.instance.close());
  };

  const handleApproveClicked = () => {
    setIsApproveLoading(true);
    handleAccessPermission(true);
  };

  const handleDenyClicked = () => {
    setIsDenyLoading(true);
    handleAccessPermission(false);
  };

  return (
    <Modal>
      <Modal.Header>
        {t(
          selectedUsers.length > 1
            ? 'FLIGHTROOMS.USER_REQUESTS_ACCESS.MODAL.TITLE'
            : 'FLIGHTROOMS.USER_REQUEST_ACCESS.MODAL.TITLE',
          {
            amount: selectedUsers.length,
            flightroomName: room?.flightNumber,
          },
        )}
      </Modal.Header>
      {isLoadingUsers ? (
        <Spinner testId="grant-request-modal-spinner" />
      ) : (
        <div className="flightroom-request-modal-center">
          <a className="flightroom-request-modal-select-all" data-test="modal-select-all" onClick={toggleAll} type="button">
            <Text color={COLORS.romeo.$07} size="LG" weight="bold">
              {t(
                areAllSelected
                  ? 'FLIGHTROOMS.USER_REQUEST_ACCESS.MODAL.DESELECT'
                  : 'FLIGHTROOMS.USER_REQUEST_ACCESS.MODAL.SELECT',
              )}
            </Text>
          </a>
          {selectedUsers.map(user => (
            <div className="flightroom-request-modal-content" key={user.externalId}>
              <Checkbox
                checked={user.selected}
                className="flightroom-request-checkbox"
                label={`${user.division} ${user.name}`}
                labelClassName="flightroom-request-checkbox-label"
                name={t('APPROVE_ACCESS.ALERT.TITLE', { name: user.name })}
                onChange={() => toggleSelect(user)}
                testId={`modal-checkbox-${user.name}`}
              />
            </div>
          ))}
        </div>
      )}
      <Modal.Buttons>
        <Button
          disabled={!areSomeSelected || isLoadingAccess || isLoadingUsers}
          isOutline
          loading={isDenyLoading}
          onClick={handleDenyClicked}
          testId="modal-button-deny"
          theme="negative"
        >
          {t('APPROVE_ACCESS.SHEET.DENY')}
        </Button>
        <Button
          disabled={!areSomeSelected || isLoadingAccess || isLoadingUsers}
          loading={isApproveLoading}
          onClick={handleApproveClicked}
          testId="modal-button-aprove"
          theme="primary"
        >
          {t('APPROVE_ACCESS.SHEET.APPROVE')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};
