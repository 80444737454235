import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { NotificationV1 } from 'types';

import { useGetNotifications, useSocketEvent } from '../../hooks';
import { sendOverSocket } from '../../sockets/socket';
import { useSocket } from '../SocketContext';
import { NotificationsContextType } from './NotificationsContext.type';
import { NotificationsActionType } from './store/notifications.actions';
import { notificationReducer } from './store/notifications.reducer';

const initialNotificationsContext: NotificationsContextType = {
  error: null,
  fetchOlderNotifications: () => {},
  hasOlderNotifications: false,
  isFetchingNotifications: false,
  isRefetchingNotifications: false,
  notifications: [],
  refetchNotifications: () => {},
  removeNotification: () => {},
  unreadNotificationsCount: 0,
};

const NotificationsContext = createContext<NotificationsContextType>(initialNotificationsContext);

export const useNotificationsContext = () => useContext(NotificationsContext);

export const NotificationsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data, error, fetchNextPage, hasNextPage, isFetching, isRefetching, refetch, unreadNotificationsCount } =
    useGetNotifications();
  const [state, dispatch] = useReducer(notificationReducer, { notifications: new Map(), unreadNotificationsCount: 0 });
  const { socket } = useSocket();

  const removeNotification = useCallback((notification: NotificationV1) => {
    sendOverSocket('notification.update', { ...notification, isVisible: false });
  }, []);

  const handleNotificationCreate = useCallback((notification: NotificationV1) => {
    dispatch({ payload: notification, type: NotificationsActionType.ADD_NOTIFICATION });
  }, []);

  const handleNotificationUpdate = useCallback((notification: NotificationV1) => {
    dispatch({ payload: notification, type: NotificationsActionType.UPDATE_NOTIFICATION });
  }, []);

  useEffect(() => {
    if (data) {
      dispatch({
        payload: { notifications: data, unreadCount: unreadNotificationsCount },
        type: NotificationsActionType.ADD_NOTIFICATIONS,
      });
    }
  }, [data, unreadNotificationsCount]);

  useSocketEvent(socket, 'notification.create', handleNotificationCreate);
  useSocketEvent(socket, 'notification.update', handleNotificationUpdate);

  return useMemo(() => {
    return (
      <NotificationsContext.Provider
        value={{
          error,
          fetchOlderNotifications: fetchNextPage,
          hasOlderNotifications: hasNextPage ?? false,
          isFetchingNotifications: isFetching,
          isRefetchingNotifications: isRefetching,
          notifications: Array.from(state.notifications.values()),
          refetchNotifications: refetch,
          removeNotification,
          unreadNotificationsCount: state.unreadNotificationsCount,
        }}
      >
        {children}
      </NotificationsContext.Provider>
    );
  }, [
    children,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isRefetching,
    refetch,
    removeNotification,
    state.notifications,
    state.unreadNotificationsCount,
  ]);
};
