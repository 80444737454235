import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputToolbarProps } from 'react-native-gifted-chat';
import { isValidImageMimeType, toBase64 } from '@aviobook/_utils';
import { isMacOS } from '@aviobook/_utils/detect-device';
import { MentionInput, MentionInputRef, QuickReplies } from '@aviobook/chat/_components';
import { SendMessageModal } from '@aviobook/modal/sendMessageModal/SendMessageModal';
import convert from 'heic-convert/browser';
import prettyBytes from 'pretty-bytes';
import { getValidExtensionsString, MAX_IMAGE_SIZE, useGetQuickReplies, useMentions } from 'shared';
import { APPLE_ONLY_FILE_TYPES } from 'shared/src/constants/file/file';
import { COLORS } from 'styles';
import { GiftedMessage, Mention } from 'types';

import { useAppContext } from '../../../_context';
import { Button, Icon } from '../../../_shared';
import Modal from '../../../modal/Modal';
import { ModalOpener } from '../../../modal/ModalOpener';

import './inputToolbar.scss';

type Props = InputToolbarProps<GiftedMessage> & {
  onSend: (message: string, attachment?: File | null) => void;
};

export const InputToolbar: React.FC<Props> = ({ onSend }) => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const inputMentionRef = useRef<MentionInputRef>(null);

  const { t } = useTranslation();
  const { data: quickReplies = [] } = useGetQuickReplies();
  const { getUsedMentions } = useMentions();

  const { imagePreview, setImagePreview } = useAppContext();
  const [image, setImage] = useState<File | null>(null);
  const [message, setMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const validExtensionsString = useMemo(() => {
    return getValidExtensionsString(!isMacOS() ? APPLE_ONLY_FILE_TYPES : []);
  }, []);

  const onPressQuickReply = (item: string) => {
    inputMentionRef.current?.sendText(item);
  };

  const onSubmit = () => {
    const usedMentions = getUsedMentions(message);
    if (!isModalVisible && usedMentions.some((mention: Mention) => mention.isExternal)) {
      setIsModalVisible(true);
      ModalOpener.instance.open({
        render: () => (
          <SendMessageModal
            message={t('CHAT.MENTIONS.MODAL.MESSAGE')}
            onClose={() => {
              setIsModalVisible(false);
              ModalOpener.instance.close();
            }}
            onConfirm={sendMessage}
            title={t('CHAT.MENTIONS.MODAL.TITLE')}
          />
        ),
      });
      return;
    }
    sendMessage();
  };

  const sendMessage = () => {
    setIsModalVisible(false);
    ModalOpener.instance.close();
    onSend(message, imagePreview ? image : null);
    setMessage('');
    setImage(null);
    setImagePreview('');
  };

  const showAttachmentErrorModal = (title: string, message: string) => {
    ModalOpener.instance.open({
      render: () => (
        <Modal>
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content>{message}</Modal.Content>
          <Modal.Buttons>
            <Button onClick={() => ModalOpener.instance.close()} theme="primary">
              {t('SHARED.BUTTONS.OK')}
            </Button>
          </Modal.Buttons>
        </Modal>
      ),
    });
  };

  const handlePickAttachment = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file?.size && file.size > MAX_IMAGE_SIZE) {
      showAttachmentErrorModal(
        t('CHAT.INPUT.ALERT.ATTACHMENT_TOO_LARGE.TITLE'),
        t('CHAT.INPUT.ALERT.ATTACHMENT_TOO_LARGE.DESCRIPTION', {
          maxSize: prettyBytes(MAX_IMAGE_SIZE, {
            binary: true,
            space: false,
          }),
        }).replace('MiB', 'MB'),
      );
    } else if (file && !(await isValidImageMimeType(file))) {
      showAttachmentErrorModal(
        t('CHAT.INPUT.ALERT.INVALID_IMAGE_TYPE.TITLE'),
        t('CHAT.INPUT.ALERT.INVALID_IMAGE_TYPE.DESCRIPTION', { allowedTypes: validExtensionsString }),
      );
    } else if (file) {
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const arrayBuffer = await file.arrayBuffer();
        try {
          const buffer = new Uint8Array(arrayBuffer);
          const outputBuffer = await convert({
            buffer,
            format: 'JPEG',
            quality: 0.5,
          });
          const convertedFile = new File(
            [new Blob([outputBuffer], { type: 'image/jpeg' })],
            file.name.replace(/\.\w+$/, '.jpeg'),
            {
              type: 'image/jpeg',
            },
          );
          const convertedPreview = URL.createObjectURL(convertedFile);
          setImage(convertedFile);
          setImagePreview(convertedPreview);
        } catch (error) {
          showAttachmentErrorModal(t('CHAT.INPUT.ALERT.UNKNOWN_ERROR.TITLE'), t('CHAT.INPUT.ALERT.UNKNOWN_ERROR.DESCRIPTION'));
        }
      } else {
        const base64Preview = await toBase64(file);
        setImage(file);
        setImagePreview(base64Preview);
      }
    }
    event.target.value = '';
  };

  const sendDisabled = !message && !imagePreview;

  const openPicker = () => {
    inputFile.current?.click();
  };

  return (
    <div className="chat-input-toolbar-container">
      <QuickReplies onPressQuickReply={onPressQuickReply} quickReplies={quickReplies} />
      <div className="input-container">
        <MentionInput
          // @ts-expect-error expects ReactNode instead of Element
          icon={
            <Icon
              className={'open-attachment-picker-button'}
              colorName={COLORS.zulu.$08}
              name="circularPlusOutline"
              onClick={openPicker}
              size={2.5}
            />
          }
          message={message}
          ref={inputMentionRef}
          sendDisabled={sendDisabled}
          sendMessage={onSubmit}
          setMessage={setMessage}
        />
        <Button className="send-button" disabled={sendDisabled} onClick={onSubmit}>
          <Icon name="paperplaneOutline" size={3} />
        </Button>
      </div>
      <input
        accept={validExtensionsString}
        id="file"
        onChange={handlePickAttachment}
        ref={inputFile}
        style={{ display: 'none' }}
        type="file"
      />
    </div>
  );
};
