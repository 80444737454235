import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputField } from '../index';

import './searchField.scss';

type Props = {
  disabled: boolean;
  placeholder?: string;
  search?: string;
  setSearch: (search: string) => void;
};

export const SearchField: FC<Props> = ({ disabled, placeholder, search, setSearch }) => {
  const { t } = useTranslation();

  return (
    <InputField
      autoComplete="off"
      className="search-field"
      disabled={disabled}
      hasClearButton
      iconName="magnifyingSearchFilled"
      name="search"
      onChange={(value: string | number) => setSearch(`${value}`)}
      onClearClick={() => setSearch('')}
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      placeholder={placeholder || t('SHARED.PLACEHOLDER.SEARCH')}
      value={search}
    />
  );
};
