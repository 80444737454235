import { useParams } from 'react-router-dom';
import { ApiKeysPermissions, ConnectPermission } from 'types';

import { useGetRole, useUpdateRole } from '../../../../_queries';

const filterPermissions = (permissions: Set<ConnectPermission>, permissionsToRemove: string[]): Set<ConnectPermission> => {
  return new Set(Array.from(permissions).filter(permission => !permissionsToRemove.includes(permission)));
};

export const usePermissions = () => {
  const { roleId } = useParams();
  const { data: role, isLoading: isDataLoading } = useGetRole(roleId ?? '');
  const { isLoading: IsUpdateLoading, mutate } = useUpdateRole();

  const permissionsToRemove = [...Object.values(ApiKeysPermissions), 'UPDATE_QUICK_REPLIES'];

  const filteredPermissions = filterPermissions(new Set(role?.permissions), permissionsToRemove);

  return {
    isLoading: isDataLoading || IsUpdateLoading,
    permissions: filteredPermissions,
    updatePermissions: (permissions: Set<ConnectPermission>) => {
      const updatedPermissions = filterPermissions(permissions, permissionsToRemove);
      if (role) {
        mutate({
          ...role,
          permissions: Array.from(updatedPermissions),
        });
      }
    },
  };
};
