const DATE_TIME_FORMATS: DateTimeFormatsInterface = {
  BACKEND_TIME: 'HH:mm:ss.SSS[Z]',
  DATE: 'YYYY-MM-DD',
  DATE_DAY: 'ddd DD MMM',
  DATE_LONG: 'DD MMM YYYY',
  DATE_TIME: 'YYYY-MM-DD - HH:mm',
  DATE_TIME_LONG: 'DD MMM YYYY HH:mm[Z]',
  DATE_TIME_LONG_NO_ZONE: 'YYYY MMM DD - HH:mm',
  DATE_TIME_LONG_NO_ZONE_FORMAT: 'ddd DD MMM YYYY - HH:mm',
  END_TIME_OF_DAY: '23:59',
  ISO8601_TIME_DURATION_REGEX: /^PT([0-9]{1,}[SMH]{1}){1,}$/,
  PARSE_REGEX: /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/,
  SHORT_DATE: 'D MMM',
  START_TIME_OF_DAY: '00:00',
  TIME: 'HH:mm',
  TIME_DATE: 'HH:mm - DD MMM YYYY',
};

export interface DateTimeFormatsInterface {
  BACKEND_TIME: string;
  DATE: string;
  DATE_DAY: string;
  DATE_LONG: string;
  DATE_TIME: string;
  DATE_TIME_LONG: string;
  DATE_TIME_LONG_NO_ZONE: string;
  DATE_TIME_LONG_NO_ZONE_FORMAT: string;
  END_TIME_OF_DAY: string;
  ISO8601_TIME_DURATION_REGEX: RegExp;
  PARSE_REGEX: RegExp;
  SHORT_DATE: string;
  START_TIME_OF_DAY: string;
  TIME: string;
  TIME_DATE: string;
}

export default DATE_TIME_FORMATS;
