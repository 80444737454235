import { FlightRoom } from 'types';

import { isRoomCompleted, sortFlightroomsByStd } from '../../utils';
import { FlightRoomAction, FlightRoomActionType } from './FlightRooms.actions';

type FlightroomMap = Map<FlightRoom['externalId'], FlightRoom>;

type State = {
  activeFlightrooms: FlightroomMap;
  completedFlightrooms: FlightroomMap;
};

const getFlightRoom = (
  activeFlightrooms: Map<string, FlightRoom>,
  completedFlightrooms: Map<string, FlightRoom>,
  roomId: string,
) => {
  return activeFlightrooms.get(roomId) ?? completedFlightrooms.get(roomId);
};

const incrementUnreadCount = (state: State, room: FlightRoom) => {
  const activeFlightrooms = new Map(state.activeFlightrooms);
  const completedFlightrooms = new Map(state.completedFlightrooms);
  const updatedMessageCount = (room.unreadMessagesCount ?? 0) + 1;
  const updatedRoom = { ...room, unreadMessagesCount: updatedMessageCount };
  if (isRoomCompleted(room)) {
    completedFlightrooms.set(room.externalId, updatedRoom);
  } else {
    activeFlightrooms.set(room.externalId, updatedRoom);
  }
  return { ...state, activeFlightrooms, completedFlightrooms };
};

const updateFlightRoom = (existingRoom: FlightRoom | undefined, updatedRoom: FlightRoom): FlightRoom => {
  if (existingRoom) {
    return {
      ...existingRoom,
      ...updatedRoom,
      // Don't override the unreadMessageCount since this is handled by INCREMENT_FLIGHTROOM_MESSAGE_COUNT action
      unreadMessagesCount: existingRoom.unreadMessagesCount,
    };
  } else {
    return {
      ...updatedRoom,
      unreadMessagesCount: updatedRoom.unreadMessagesCount ?? 0,
    };
  }
};

export const flightRoomReducer = (state: State, action: FlightRoomAction) => {
  let activeFlightrooms: Map<string, FlightRoom>;
  let completedFlightrooms: Map<string, FlightRoom>;
  let existingRoom: FlightRoom | undefined;

  switch (action.type) {
    case FlightRoomActionType.ADD_FLIGHTROOM:
    case FlightRoomActionType.UPDATE_FLIGHTROOM: {
      activeFlightrooms = new Map(state.activeFlightrooms);
      completedFlightrooms = new Map(state.completedFlightrooms);
      existingRoom = getFlightRoom(activeFlightrooms, completedFlightrooms, action.payload.externalId);
      const updatedRoom = updateFlightRoom(existingRoom, action.payload);
      if (isRoomCompleted(updatedRoom)) {
        completedFlightrooms.set(updatedRoom.externalId, updatedRoom);
        completedFlightrooms = new Map(Array.from(completedFlightrooms).sort((a, b) => sortFlightroomsByStd(a[1], b[1])));
        activeFlightrooms.delete(updatedRoom.externalId);
      } else {
        activeFlightrooms.set(updatedRoom.externalId, updatedRoom);
        activeFlightrooms = new Map(Array.from(activeFlightrooms).sort((a, b) => sortFlightroomsByStd(a[1], b[1])));
        completedFlightrooms.delete(updatedRoom.externalId);
      }

      return {
        ...state,
        activeFlightrooms,
        completedFlightrooms,
      };
    }

    case FlightRoomActionType.SET_ACTIVE_FLIGHTROOMS:
      activeFlightrooms = new Map();
      action.payload.forEach(room => activeFlightrooms.set(room.externalId, room));
      return { ...state, activeFlightrooms };

    case FlightRoomActionType.UPDATE_ACTIVE_FLIGHTROOMS:
      activeFlightrooms = new Map(state.activeFlightrooms);
      action.payload.forEach(room => activeFlightrooms.set(room.externalId, room));
      return { ...state, activeFlightrooms };

    case FlightRoomActionType.SET_COMPLETED_FLIGHTROOMS:
      completedFlightrooms = new Map();
      action.payload.forEach(room => completedFlightrooms.set(room.externalId, room));
      return { ...state, completedFlightrooms };

    case FlightRoomActionType.UPDATE_COMPLETED_FLIGHTROOMS:
      completedFlightrooms = new Map(state.completedFlightrooms);
      action.payload.forEach(room => completedFlightrooms.set(room.externalId, room));
      return { ...state, completedFlightrooms };

    case FlightRoomActionType.REMOVE_FLIGHTROOM:
      activeFlightrooms = new Map(state.activeFlightrooms);
      completedFlightrooms = new Map(state.completedFlightrooms);
      completedFlightrooms.delete(action.payload.externalId);
      activeFlightrooms.delete(action.payload.externalId);
      return { activeFlightrooms, completedFlightrooms };

    case FlightRoomActionType.UPDATE_FLIGHTROOM_MESSAGE_COUNT:
      // State is updated directly here because we dont want rerenders for this, this is needed when we receive flightroom updates
      existingRoom = getFlightRoom(state.activeFlightrooms, state.completedFlightrooms, action.payload.roomId);
      if (existingRoom) {
        const updatedRoom = { ...existingRoom, unreadMessagesCount: action.payload.count };
        if (state.activeFlightrooms.has(action.payload.roomId)) {
          state.activeFlightrooms.set(action.payload.roomId, updatedRoom);
        } else {
          state.completedFlightrooms.set(action.payload.roomId, updatedRoom);
        }
      }
      return { ...state };

    case FlightRoomActionType.INCREMENT_FLIGHTROOM_MESSAGE_COUNT:
      existingRoom = getFlightRoom(state.activeFlightrooms, state.completedFlightrooms, action.payload.roomId);
      if (existingRoom) {
        return incrementUnreadCount(state, existingRoom);
      }
      return { ...state };

    default:
      return state;
  }
};
