import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Mention } from 'types';

import { MessageUnion, sortMessagesByDate, useAuthenticatedUser, useGetMessages } from '../../hooks';
import { useSocket } from '../SocketContext';
import { chatMessagesReducer } from './chatMessages.reducer';
import { ChatMessagesActionType } from './chatMessagesAction.enum';
import { ChatMessagesContextType } from './chatMessagesContext.type';
import { useChatMessageSocketEvents } from './hooks/useChatMessageSocketEvents';
import { useSendChatMessage } from './hooks/useSendChatMessage';

type Props = {
  children: ReactNode;
  flightroomId: string;
};

export const ChatMessagesContext = createContext<ChatMessagesContextType>({
  chat: [],
  error: null,
  fetchOlderMessages: () => {},
  fetchingError: null,
  hasOlderMessages: false,
  isFetching: false,
  remove: () => {},
  retry: () => {},
  selectMention: () => {},
  selectedMentions: [],
  send: () => {},
});

export const useChatMessagesContext = () => {
  const context = useContext(ChatMessagesContext);
  if (!context) {
    throw new Error('useChatMessagesContext must be used within a ChatMessagesProvider');
  }
  return context;
};

export const ChatMessagesContextProvider: FC<Props> = ({ children, flightroomId }) => {
  const [state, dispatch] = useReducer(chatMessagesReducer, { error: null, messages: new Map() });
  const { socket } = useSocket();
  const [chat, setChat] = useState<MessageUnion[]>([]);
  const [selectedMentions, setSelectedMentions] = useState<Mention[]>([]);
  const {
    error: fetchingError,
    fetchNextPage: fetchOlderMessages,
    hasNextPage: hasOlderMessages,
    isFetching,
    messages: newlyFetchedMessages,
  } = useGetMessages(flightroomId, () => {});

  useChatMessageSocketEvents(socket, dispatch, flightroomId);

  const { canMentionExternally } = useAuthenticatedUser();

  const { remove, retry, send } = useSendChatMessage(dispatch, flightroomId);

  const selectMention = (mention: Mention) => {
    setSelectedMentions(prevState => [...prevState, mention]);
  };

  useEffect(() => {
    const sortedChat = Array.from(state.messages.values()).sort(sortMessagesByDate);
    setChat(sortedChat);
  }, [state.messages]);

  useEffect(() => {
    if (newlyFetchedMessages) {
      dispatch({ payload: newlyFetchedMessages, type: ChatMessagesActionType.ADD_MESSAGES });
    }
  }, [newlyFetchedMessages]);

  // AVIO-44078
  const selectedMentionsFiltered = useMemo(() => {
    return [...(canMentionExternally ? selectedMentions : selectedMentions.filter(mention => !mention.isExternal))];
  }, [canMentionExternally, selectedMentions]);

  const memoizedChatMessageContextProvider = useMemo(() => {
    return (
      <ChatMessagesContext.Provider
        value={{
          chat,
          error: state.error,
          fetchOlderMessages,
          fetchingError,
          hasOlderMessages,
          isFetching,
          remove,
          retry,
          selectMention,
          selectedMentions: selectedMentionsFiltered,
          send,
        }}
      >
        {children}
      </ChatMessagesContext.Provider>
    );
  }, [
    chat,
    children,
    fetchOlderMessages,
    fetchingError,
    hasOlderMessages,
    isFetching,
    remove,
    retry,
    selectedMentionsFiltered,
    send,
    state.error,
  ]);

  return memoizedChatMessageContextProvider;
};
