import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { queryClient } from '@aviobook/_http/queryClient';
import { Button, SideModal } from '@aviobook/_shared';
import { CrewMemberInfoItem } from '@aviobook/flightrooms/_components/crewMemberInfoItem/CrewMemberInfoItem';
import ConfirmationModal from '@aviobook/modal/confirmation/ConfirmationModal';
import OneButtonModal from '@aviobook/modal/oneButtonModal/OneButtonModal';
import {
  checkIsUserScheduledForRoom,
  sortFlightroomUsersByRole,
  useAuthenticationContext,
  useFlightroomsContext,
  useLeaveFlightroom,
} from 'shared';
import { FlightRoom, FlightRoomUserStatus, QueryKeys } from 'types';

type FlightRoomInfoSideModalProps = {
  isVisible: boolean;
  onClose: () => void;
  room: FlightRoom;
};

export const FlightRoomInfoSideModal: FC<FlightRoomInfoSideModalProps> = ({ isVisible, onClose, room }) => {
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { user } = useAuthenticationContext();
  const { setSelectedFlightroom } = useFlightroomsContext();

  const isUserScheduledForRoom = user && checkIsUserScheduledForRoom(user.externalId, room);

  const [isLeaveRoomModalOpen, setIsLeaveRoomModalOpen] = useState(false);
  const [isErrorLeavingRoomOpen, setIsErrorLeavingRoomOpen] = useState(false);

  const { isLoading: isLoadingLeaveFlightroom, mutate: leaveFlightroom } = useLeaveFlightroom({
    onError: () => {
      setIsLeaveRoomModalOpen(false);
      setIsErrorLeavingRoomOpen(true);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKeys.FLIGHTROOMS]);
      setIsLeaveRoomModalOpen(false);
      setSelectedFlightroom(null);
      setSearchParams();
    },
  });

  const activeUsers = useMemo(() => {
    return room?.users?.filter(({ status }) => status === FlightRoomUserStatus.ACTIVE).sort(sortFlightroomUsersByRole) ?? [];
  }, [room]);

  return (
    <>
      <SideModal
        bottomButtons={
          !isUserScheduledForRoom && (
            <Button isOutline onClick={() => setIsLeaveRoomModalOpen(prevState => !prevState)} theme="negative" type="button">
              {t('FLIGHTROOM.LEAVE.BUTTON')}
            </Button>
          )
        }
        content={
          <>
            {activeUsers.map(crewMember => (
              <CrewMemberInfoItem crewMember={crewMember} key={crewMember.externalId} />
            ))}
          </>
        }
        header={{
          rightIcon: { iconName: 'closeCross', onClick: onClose },
          title: { text: t('FLIGHTROOM.DETAIL.TITLE') },
        }}
        isVisible={isVisible}
        onBackgroundClick={onClose}
      />
      {isLeaveRoomModalOpen ? (
        <ConfirmationModal
          confirmText={t('FLIGHTROOM.LEAVE.ALERT.SUBMIT')}
          content={t('FLIGHTROOM.LEAVE.ALERT.DESCRIPTION')}
          isLoading={isLoadingLeaveFlightroom}
          onCancel={() => setIsLeaveRoomModalOpen(false)}
          onConfirm={() => leaveFlightroom(room.externalId)}
          title={t('FLIGHTROOM.LEAVE.ALERT.TITLE', { name })}
        />
      ) : null}
      {isErrorLeavingRoomOpen ? (
        <OneButtonModal
          confirmText={t('SHARED.BUTTONS.OK')}
          content={t('FLIGHTROOM.LEAVE.ERROR_ALERT.DESCRIPTION')}
          onConfirm={() => setIsErrorLeavingRoomOpen(false)}
          title={t('FLIGHTROOM.LEAVE.ERROR_ALERT.TITLE')}
        />
      ) : null}
    </>
  );
};
