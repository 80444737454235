import { FC } from 'react';
import { MessageStatusIcon, StatusDot } from '@aviobook/_shared';
import { getIconForOccupation } from '@aviobook/_utils';
import { FlightroomInfoItem } from '@aviobook/flightrooms/_components/flightroomInfoItem/FlightroomInfoItem';
import { replaceJobTitlePlaceholders, UserV1Util, useUsersContext } from 'shared';
import { formatDateToTimeDate } from 'shared/src/utils/date-time/lib/avio-date-time.util';
import { Iso8601DateString, MessageStatus } from 'types';
import { FlightRoomUser } from 'types/src/room';

type Props = {
  crewMember: FlightRoomUser;
  messageDeliveryStatus?: MessageStatus;
  onClick?: (user: FlightRoomUser) => void;
  receiptUpdatedAt?: Iso8601DateString;
};

export const CrewMemberInfoItem: FC<Props> = ({ crewMember, messageDeliveryStatus, onClick, receiptUpdatedAt }) => {
  const { getUserById } = useUsersContext();

  const user = getUserById(crewMember.externalId);

  return (
    <FlightroomInfoItem
      avatar={getIconForOccupation(crewMember.scheduled ? crewMember.type.role : user?.jobTitle)}
      onClick={onClick && user && crewMember ? () => onClick?.(crewMember) : undefined}
      rightSection={<StatusDot size="small" status={user?.connectivityStatus} userId={user?.externalId} />}
      subValue={receiptUpdatedAt ? formatDateToTimeDate(receiptUpdatedAt) : ''}
      title={
        crewMember.scheduled ? replaceJobTitlePlaceholders(crewMember.type.name) : UserV1Util.getGuestUserString(user?.jobTitle)
      }
      titleIcon={messageDeliveryStatus ? <MessageStatusIcon status={messageDeliveryStatus} /> : null}
      value={UserV1Util.getFullName(user)}
    />
  );
};
