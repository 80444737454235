import { BaseSyntheticEvent, FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Spinner } from '@aviobook/_shared';
import { IconName } from 'assets';
import classnames from 'classnames';
import { ColorName } from 'styles';

import './button.scss';

type ButtonProps = {
  asText?: boolean;
  children: ReactNode;
  className?: string;
  color?: ColorName;
  disabled?: boolean;
  href?: string;
  iconName?: IconName;
  isOutline?: boolean;
  loading?: boolean;
  onClick?: (event?: BaseSyntheticEvent) => void;
  testId?: string;
  theme?: 'neutral' | 'primary' | 'login' | 'negative' | 'dark' | 'secondary' | 'transparent' | 'delete';
  type?: 'button' | 'submit' | 'reset';
};

const Button: FC<ButtonProps> = ({
  asText,
  children,
  className = '',
  disabled,
  href,
  iconName,
  isOutline = false,
  loading,
  onClick = () => {},
  testId,
  theme = 'neutral',
  type = 'button',
}) => {
  const cssClasses = classnames(asText ? 'as-text' : 'buttonCMS', className, theme, { disabled, loading, outline: isOutline });

  const renderSpinner = () => {
    if (!loading) {
      return null;
    }
    return (
      <div className="spinner-wrapper">
        <Spinner theme={(asText ?? isOutline) ? 'normal' : 'inversed'} />
      </div>
    );
  };

  if (href) {
    return (
      <Link className={cssClasses} to={href} {...(testId && { 'data-test': testId })}>
        {iconName ? <Icon name={iconName} /> : null}
        {children}
        {renderSpinner()}
      </Link>
    );
  }
  return (
    <button
      className={cssClasses}
      disabled={disabled}
      onClick={onClick || (() => {})}
      type={type}
      {...(testId && { 'data-test': testId })}
    >
      {iconName ? <Icon name={iconName} /> : null}
      {children}
      {renderSpinner()}
    </button>
  );
};

export default Button;
