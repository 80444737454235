import { useCallback, useMemo } from 'react';
import { Platform } from 'react-native';
import { MessageUnion, useAuthenticationContext, useFlightroomsContext, useSettings, useSocket, useSocketEvent } from 'shared';
import {
  FlightRoom,
  FlightRoomUserStatus,
  MessageSubTypes,
  MessageTypes,
  NotificationType,
  NotificationV1,
  UserMessageV1,
} from 'types';

export const useNotificationSound = () => {
  const { socket } = useSocket();
  const { user: currentUser } = useAuthenticationContext();
  const { getRoomById, selectedFlightroom } = useFlightroomsContext();
  const { settings } = useSettings();

  const sound = useMemo(() => (Platform.OS === 'web' ? new Audio('/ding.mp3') : null), []);

  const playSound = useCallback(async () => {
    try {
      await sound?.play();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [sound]);

  const isSystemNotificationEnabled = useCallback((): boolean => {
    return settings?.pushNotifications.find(setting => setting.key === 'SYSTEM_MESSAGES')?.isEnabled ?? false;
  }, [settings?.pushNotifications]);

  const isActiveUserInRoom = useCallback(
    (room: FlightRoom): boolean => {
      return !!room.users?.find(
        user => user?.externalId === currentUser?.externalId && user?.status === FlightRoomUserStatus.ACTIVE,
      );
    },
    [currentUser?.externalId],
  );

  const verifyPermissionsAndRoom = useCallback(
    async (roomId: string): Promise<boolean> => {
      if (Platform.OS !== 'web') {
        return false;
      }
      if (Notification.permission !== 'granted') {
        return false;
      }

      const room = await getRoomById(roomId);

      if (!room) {
        return false;
      }

      if (!isActiveUserInRoom(room)) {
        return false;
      }

      return true;
    },
    [getRoomById, isActiveUserInRoom],
  );

  const shouldPlaySoundForUserMessage = useCallback(
    (message: MessageUnion): boolean => {
      if (settings?.pushNotifications.find(setting => setting.key === 'USERS')?.isEnabled) {
        return true;
      }

      if (settings?.pushNotifications.find(setting => setting.key === 'MENTIONS')?.isEnabled) {
        return !!message.mentions?.find(
          mention => currentUser?.externalId && mention.users && mention.users.indexOf(currentUser?.externalId) > -1,
        );
      }
      return false;
    },
    [settings?.pushNotifications, currentUser?.externalId],
  );

  const shouldPlaySoundForMessage = useCallback(
    async (message: MessageUnion): Promise<boolean> => {
      const permissionsAndRoomOk = await verifyPermissionsAndRoom(message.room);

      if (!permissionsAndRoomOk) {
        return false;
      }

      const isCurrentUser = (message as UserMessageV1).user === currentUser?.externalId;
      const isDocumentHidden = document?.hidden;

      if (!isDocumentHidden && message.room === selectedFlightroom?.externalId) {
        return false;
      }
      if (isCurrentUser) {
        return false;
      }

      //check type of message
      const isSmartActionMessage = message.subType === MessageSubTypes.SMART_ACTIONS;
      const isUserMessage = message.type === MessageTypes.USER && !message.subType;
      const isSystemMessage = message.type === MessageTypes.SYSTEM && !message.subType;

      // notification settings
      const isSmartActionNotificationEnabled = settings?.pushNotifications.find(
        setting => setting.key === 'SMART_ACTIONS',
      )?.isEnabled;

      // then here we check what kind of message it is and if we enabled it
      if (
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        (isSmartActionMessage && isSmartActionNotificationEnabled) ||
        (isUserMessage && shouldPlaySoundForUserMessage(message)) ||
        (isSystemMessage && isSystemNotificationEnabled())
      ) {
        return true;
      }

      return false;
    },
    [
      verifyPermissionsAndRoom,
      currentUser?.externalId,
      isSystemNotificationEnabled,
      selectedFlightroom,
      settings?.pushNotifications,
      shouldPlaySoundForUserMessage,
    ],
  );

  const shouldPlaySoundForNotification = useCallback(
    async (notification: NotificationV1): Promise<boolean> => {
      const permissionsAndRoomOk = await verifyPermissionsAndRoom(notification.room);

      return permissionsAndRoomOk && notification.type === NotificationType.ACCESS_REQUEST && isSystemNotificationEnabled();
    },
    [isSystemNotificationEnabled, verifyPermissionsAndRoom],
  );

  const processMessageForRoom = useCallback(
    async (message: MessageUnion) => {
      if (await shouldPlaySoundForMessage(message)) {
        playSound();
      }
    },
    [shouldPlaySoundForMessage, playSound],
  );

  const processNotification = useCallback(
    async (notification: NotificationV1) => {
      if (await shouldPlaySoundForNotification(notification)) {
        playSound();
      }
    },
    [shouldPlaySoundForNotification, playSound],
  );

  useSocketEvent(socket, 'message.create', processMessageForRoom);
  useSocketEvent(socket, 'notification.create', processNotification);
};
