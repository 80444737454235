import React, { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useWindowDimensions } from '@aviobook/_hooks';
import { Icon } from '@aviobook/_shared';
import { RelevantMentionSection } from '@aviobook/chat/_components';
import { COLORS } from 'styles';
import { Mention } from 'types';

import './mentionOptions.scss';

type MentionOptionsProps = {
  data: RelevantMentionSection[];
  maxRightPosition?: number;
  onSelect: (mention: Mention) => void;
  startPositionList?: number;
  visible: boolean;
};

export type MentionOptionsRef = {
  containsNode: (node: Node) => boolean;
  index: number;
  setIndex: (index: number) => void;
};

export const MentionOptions = forwardRef<MentionOptionsRef, MentionOptionsProps>(
  ({ data, maxRightPosition = 0, onSelect, startPositionList = 0, visible }, ref) => {
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);

    const { windowHeight } = useWindowDimensions();

    const suggestionListRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      containsNode: (node: Node) => {
        return !!suggestionListRef.current?.contains(node);
      },
      index: suggestionIndex,
      setIndex: (index: number) => {
        setSuggestionIndex(index);
      },
    }));

    useLayoutEffect(() => {
      const updateMaxWidth = () => {
        const currentWidth = suggestionListRef.current?.getBoundingClientRect().width;
        if (currentWidth) {
          setContainerWidth(currentWidth);
        }
      };

      updateMaxWidth();

      const observer = new ResizeObserver(() => {
        updateMaxWidth();
      });

      if (visible && suggestionListRef.current) {
        observer.observe(suggestionListRef.current);
      }

      return () => {
        observer.disconnect();
      };
    }, [containerWidth, visible]);

    const handleOnHoverSuggestion = (index: number) => {
      setSuggestionIndex(index);
    };

    const renderSuggestionSectionListHeader = (title: string) => {
      return (
        <div className={'section-header-container'}>
          <Icon colorName={COLORS.zulu.$08} name="peopleProfileTrioCenterTemplateOutline" />
          <span className={'section-header-title'}>{title}</span>
        </div>
      );
    };

    const renderSuggestionSectionListItem = useCallback(
      (mention: Mention, index: number, suggestionIndex: number) => {
        return (
          <li className={'suggestion-item' + (index === suggestionIndex ? ' item-active' : '')} key={index}>
            <button onClick={() => onSelect(mention)} onMouseEnter={() => handleOnHoverSuggestion(index)}>
              @{mention.label}
            </button>
          </li>
        );
      },
      [onSelect],
    );

    const suggestionSectionList = useMemo(() => {
      let index = 0;
      let startPointList = startPositionList;
      if (startPointList + containerWidth >= maxRightPosition) {
        startPointList = maxRightPosition - containerWidth;
      }
      return visible ? (
        <div
          className={'suggestions-list-container'}
          ref={suggestionListRef}
          style={{ left: startPointList, maxHeight: `${windowHeight - 116}px` }}
        >
          <ul className={'suggestions-list'}>
            {data.map(value => {
              if (!value.data.length) {
                return null;
              }
              return (
                <div key={`section-${value.title}`}>
                  {renderSuggestionSectionListHeader(value.title)}
                  <ul>
                    {value.data.map(item => {
                      const currentIndex = index;
                      index++;

                      return renderSuggestionSectionListItem(item, currentIndex, suggestionIndex);
                    })}
                  </ul>
                </div>
              );
            })}
          </ul>
        </div>
      ) : null;
    }, [
      containerWidth,
      data,
      maxRightPosition,
      renderSuggestionSectionListItem,
      startPositionList,
      suggestionIndex,
      visible,
      windowHeight,
    ]);

    return suggestionSectionList;
  },
);
