export const areArraysEqual = <T>(arr1: Array<T>, arr2: Array<T>, sort = false): boolean => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }

  if (sort) {
    arr1 = arr1.sort();
    arr2 = arr2.sort();
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

const isArray = <T>(value: T[]) => Array.isArray(value);

export const isEmptyArray = <T>(value: T[]): boolean => {
  if (!value) {
    return false;
  }
  return isArray(value) && value.length === 0;
};

export const removeDuplicateStrings = (array: string[], caseSensitive = true): string[] => {
  if (caseSensitive) {
    return [...new Set(array)];
  }
  return array.filter(
    (value, index, self) => self.findIndex(selfValue => selfValue.toLowerCase() === value.toLowerCase()) === index,
  );
};
