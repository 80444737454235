import { SUPPORTED_MIME_TYPES } from 'shared';
import { isValidFileSignature as stringCheckIsValidFileSignature } from 'shared/src/utils/';

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const isValidImageMimeType = async (file: File): Promise<boolean> => {
  if (!SUPPORTED_MIME_TYPES.map(entry => entry.mimeType).includes(file.type)) {
    return false;
  }

  return await isValidFileSignature(file);
};

const getFileHeader = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      if (!e.target?.result) {
        return reject(new Error('Failed to read file'));
      }
      const arrayBuffer = e.target.result as ArrayBuffer;
      const uint8Array = new Uint8Array(arrayBuffer).subarray(0, 12); // Read first 12 bytes
      const header = Array.from(uint8Array)
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('')
        .toUpperCase();
      resolve(header);
    };
    reader.onerror = () => reject(new Error('Error reading file'));
    reader.readAsArrayBuffer(file.slice(0, 12)); // Read first 12 bytes
  });
};

const isValidFileSignature = async (file: File) => {
  if (file.type === 'image/svg+xml') {
    const text = await file.text();
    if (text.trim().startsWith('<?xml') || text.trim().startsWith('<svg')) {
      return true;
    }
  }

  return stringCheckIsValidFileSignature(await getFileHeader(file));
};
