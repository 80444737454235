import { FC, PropsWithChildren } from 'react';
import { Text } from '@aviobook/_shared';
import { ColorName, FontSize } from 'styles';

import './pill.scss';

type PillProps = PropsWithChildren & {
  backgroundColor?: ColorName;
  fontSize?: FontSize;
  text: string;
  textColor?: ColorName;
};

export const Pill: FC<PillProps> = ({ backgroundColor, children, fontSize = 'SM', text, textColor }) => {
  return (
    <div className="pill-container" style={{ backgroundColor: backgroundColor }}>
      <Text color={textColor} size={fontSize} uppercase weight="bold">
        {text}
      </Text>
      {children}
    </div>
  );
};
