import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SideModal } from '@aviobook/_shared';
import { CrewMemberInfoItem } from '@aviobook/flightrooms/_components/crewMemberInfoItem/CrewMemberInfoItem';
import { sortFlightroomUsersByRole, useAuthenticationContext } from 'shared';
import { FlightRoom, FlightRoomUserStatus, MessageStatus, MessageV1 } from 'types';

type MessageInfoSideModalProps = {
  message?: MessageV1 | null;
  onClose: () => void;
  room: FlightRoom;
};

export const MessageInfoSideModal: FC<MessageInfoSideModalProps> = ({ message, onClose, room }) => {
  const { t } = useTranslation();
  const { user } = useAuthenticationContext();

  const renderFlightRoomUsers = () => {
    const flightRoomUsers = room.users
      ?.filter(({ externalId, status }) => externalId !== user?.externalId && status === FlightRoomUserStatus.ACTIVE)
      .sort(sortFlightroomUsersByRole);

    return flightRoomUsers?.map(crewMember => {
      if (message) {
        const receipt = message.receipts?.find(r => crewMember.externalId === r.user);
        const status = receipt?.status ?? MessageStatus.SENT;
        const receiptUpdatedAt = receipt?.updatedAt ?? message.createdAt;
        return (
          <CrewMemberInfoItem
            crewMember={crewMember}
            key={crewMember.externalId}
            messageDeliveryStatus={status}
            receiptUpdatedAt={receiptUpdatedAt}
          />
        );
      }
      return <CrewMemberInfoItem crewMember={crewMember} key={crewMember.externalId} />;
    });
  };

  return (
    <SideModal
      content={<>{renderFlightRoomUsers()}</>}
      header={{ rightIcon: { iconName: 'closeCross', onClick: onClose }, title: { text: t('MESSAGE.MESSAGE_INFO.TITLE') } }}
      isVisible={!!message}
      onBackgroundClick={onClose}
    />
  );
};
