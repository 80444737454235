import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SideModal, SideModalHeader } from '@aviobook/_shared';
import { CrewMemberInfoItem } from '@aviobook/flightrooms/_components/crewMemberInfoItem/CrewMemberInfoItem';
import { ExternalMentionInfoItem } from '@aviobook/flightrooms/_components/externalMentionInfoItem/ExternalMentionInfoItem';
import { CrewProfile } from '@aviobook/profile/CrewProfile';
import { useFlightroomsContext } from 'shared';
import { COLORS } from 'styles';
import { FlightRoomUser, Mention } from 'types';

type MentionInfoSideModalProps = {
  activeMention?: Mention | null;
  onClose: () => void;
};

type Screen = 'MENTION_INFO' | 'MENTION_DETAILS';

export const MentionInfoSideModal: FC<MentionInfoSideModalProps> = ({ activeMention, onClose }) => {
  const { selectedFlightroom } = useFlightroomsContext();
  const { t } = useTranslation();

  const [screenToDisplay, setScreenToDisplay] = useState<Screen>('MENTION_INFO');
  const [activeUser, setActiveUser] = useState<FlightRoomUser | null>(null);

  useEffect(() => {
    if (!activeMention) {
      setScreenToDisplay('MENTION_INFO');
    }
  }, [activeMention]);

  const getUsers = () => {
    const mention = activeMention;
    return mention && selectedFlightroom?.users?.filter(user => mention?.users?.includes(user.externalId));
  };

  const handleUserClick = (user: FlightRoomUser) => {
    setActiveUser(user);
    setScreenToDisplay('MENTION_DETAILS');
  };

  const renderMentionInfo = () => {
    if (activeMention) {
      if (activeMention.isExternal) {
        return <ExternalMentionInfoItem mention={activeMention} />;
      }
      return renderInternalMention();
    }
  };

  const renderInternalMention = () => {
    const flightRoomUsers = getUsers();
    return (
      flightRoomUsers && (
        <>
          {flightRoomUsers?.map(crewMember => (
            <CrewMemberInfoItem crewMember={crewMember} key={crewMember.externalId} onClick={handleUserClick} />
          ))}
        </>
      )
    );
  };

  const renderMentionDetails = () => {
    return activeUser ? <CrewProfile crewMember={activeUser} /> : null;
  };

  const renderContent = () => {
    switch (screenToDisplay) {
      case 'MENTION_DETAILS':
        return renderMentionDetails();
      case 'MENTION_INFO':
      default:
        return renderMentionInfo();
    }
  };

  const renderHeader = (): SideModalHeader => {
    switch (screenToDisplay) {
      case 'MENTION_DETAILS':
        return {
          backgroundColor: COLORS.victor.$03,
          leftIcon: {
            iconName: 'arrowLeft',
            onClick: () => setScreenToDisplay('MENTION_INFO'),
          },
          title: { props: { uppercase: false }, text: t('PROFILE.HEADER_TITLE') },
        };
      case 'MENTION_INFO':
      default:
        return {
          rightIcon: { iconName: 'closeCross', onClick: onClose },
          title: { props: { uppercase: false }, text: activeMention?.label ? `@${activeMention.label}` : '' },
        };
    }
  };

  return (
    <SideModal
      content={renderContent()}
      fullwidth={screenToDisplay === 'MENTION_DETAILS'}
      header={renderHeader()}
      isVisible={!!activeMention}
      onBackgroundClick={onClose}
    />
  );
};
