import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Spinner } from '@aviobook/_shared';
import { TabRouter } from '@aviobook/cms/_shared';
import { Tab } from '@aviobook/cms/_shared/tabRouter/TabRouter';
import { useRole } from '@aviobook/cms/connect/_screens/roles/role/_hooks/useRole';
import { RoleDetails } from '@aviobook/cms/connect/_screens/roles/role/_tabs/roleDetails/RoleDetails';
import { RolePermissions } from '@aviobook/cms/connect/_screens/roles/role/_tabs/rolePermissions/RolePermissions';
import { RoleQuickRepliesComponent } from '@aviobook/cms/connect/_screens/roles/role/_tabs/roleQuickReplies/RoleQuickReplies';
import { QuickRepliesPermissions, RolesPermissions } from 'types';

export const Role: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { data, isError, isLoading, updateBreadCrumbsName } = useRole();

  useEffect(() => {
    updateBreadCrumbsName();
  }, [location, updateBreadCrumbsName]);

  if (isLoading) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  if (isError) {
    return <Navigate to="/admin/connect/roles" />;
  }

  const tabs: Tab[] = [
    {
      label: t('TAB.ROLES.ROLE.DETAILS'),
      name: 'Roles',
      order: 0,
      permissions: [RolesPermissions.VIEW, RolesPermissions.UPDATE],
      url: `/admin/connect/roles/${data?.id}/details`,
    },
    {
      label: t('TAB.ROLES.ROLE.PERMISSIONS'),
      name: 'Permissions',
      order: 1,
      permissions: [RolesPermissions.UPDATE, RolesPermissions.VIEW],
      url: `/admin/connect/roles/${data?.id}/permissions`,
    },
    {
      label: t('QUICK_REPLIES.MULTI'),
      name: 'quickReplies',
      order: 2,
      permissions: [QuickRepliesPermissions.VIEW],
      url: `/admin/connect/roles/${data?.id}/quick-replies`,
    },
  ];

  return data ? (
    <>
      <TabRouter navBarClassName="top-nav-bar" tabs={tabs} textSize="XL" />
      <Routes>
        <Route element={<Navigate replace to="details" />} index />
        <Route element={<RoleDetails data={data} isLoadingRole={isLoading} />} path="details" />
        <Route element={<RolePermissions />} path="permissions" />
        <Route element={<RoleQuickRepliesComponent />} path="quick-replies" />
        <Route element={<Navigate to="details" />} path="*" />
      </Routes>
    </>
  ) : (
    <></>
  );
};
