import { FC, PropsWithChildren, useState } from 'react';
import { Icon, Text } from '@aviobook/_shared';
import { IconName } from 'assets';
import classNames from 'classnames';
import { ColorName } from 'styles';

import './collapsibleView.scss';

type CollapsibleHeaderIcon = {
  color?: ColorName;
  name: IconName;
  onClick?: () => void;
};

type CollapsibleViewProps = PropsWithChildren & {
  className?: string;
  expanded?: boolean;
  headerIcons?: CollapsibleHeaderIcon[];
  headerText: string;
};

export const CollapsibleView: FC<CollapsibleViewProps> = ({ children, className, expanded = false, headerIcons, headerText }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const onToggle = () => {
    setIsExpanded(expanded => !expanded);
  };

  return (
    <div className={classNames('collapsible-view', className)}>
      <div className="collapsible-view-header">
        <div className="collapsible-view-header-text" onClick={onToggle}>
          <Text size="SM" weight="bold">
            {headerText}
          </Text>
        </div>
        <div className="collapsible-view-header-icons">
          {headerIcons?.map((icon, i) => <Icon colorName={icon.color} key={i} name={icon.name} onClick={icon.onClick} />)}
          <Icon name={isExpanded ? 'chevronUp' : 'chevronDown'} onClick={onToggle} />
        </div>
      </div>
      <div className={classNames('collapsible-view-content', isExpanded && 'open')}>{children}</div>
    </div>
  );
};
