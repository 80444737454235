import { Mention, MentionOptionV1, MentionType } from 'types';

export const transformMentionOptionToMention = (mentionOption: MentionOptionV1): Mention => {
  return {
    isExternal: true,
    label: mentionOption.displayName,
    roles: mentionOption.roles.map(role => role.externalId),
    type: MentionType.DISPLAY_NAME,
  };
};
