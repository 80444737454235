import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CollapsibleView, Icon, Pill } from '@aviobook/_shared';
import { FlightRoomFilterValue } from '@aviobook/_types/FlightRoomFilterValue';
import {
  FilterLabel,
  filtersLabelMap,
  filtersTabMap,
  mapAppliedFlightRoomFilters,
} from '@aviobook/filters/_utils/filtersMap.util';
import { FlightroomDashboardTabs } from '@aviobook/flightrooms/flightroomDashboardTabs';
import classnames from 'classnames';
import { COLORS } from 'styles';
import { FlightRoomFilterState, Option } from 'types';

import './appliedFiltersCollapse.scss';

type Props = {
  filters: FlightRoomFilterState | null;
  handleFlightRoomFilterDelete: (value: FlightRoomFilterValue, filterKey: keyof FlightRoomFilterState) => void;
  handleFlightRoomFilterReset: () => void;
  selectedTab: FlightroomDashboardTabs;
};

export const AppliedFiltersCollapse = memo(
  ({ filters, handleFlightRoomFilterDelete, handleFlightRoomFilterReset, selectedTab }: Props) => {
    const { t } = useTranslation();

    const removeFilters = () => {
      handleFlightRoomFilterReset();
    };

    const convertValueToEmpty = (value: FlightRoomFilterValue, removedValue: string): boolean | readonly Option[] | null => {
      if (typeof value === 'boolean') {
        return false;
      }

      if (Array.isArray(value)) {
        return value.filter((v: Option) => v.value !== removedValue) as readonly Option[];
      }

      return null;
    };

    const removeFilter = (filterKey: keyof FilterLabel, removedValue: string) => {
      if (filterKey.endsWith('From') || filterKey.endsWith('To')) {
        const filterKeyDate = `${filterKey}Date` as keyof FlightRoomFilterState;
        const filterKeyTime = `${filterKey}Time` as keyof FlightRoomFilterState;
        handleFlightRoomFilterDelete(null, filterKeyDate);
        handleFlightRoomFilterDelete(null, filterKeyTime);
      } else {
        const value: FlightRoomFilterValue = filters![filterKey as keyof FlightRoomFilterState];
        handleFlightRoomFilterDelete(convertValueToEmpty(value, removedValue), filterKey as keyof FlightRoomFilterState);
      }
    };

    const filterPills = mapAppliedFlightRoomFilters(filters).map(filter => {
      return { ...filter, value: t(filtersLabelMap.get(filter.key)!, { value: filter.value }) };
    });

    const isSelectedTabFiltered = filters ? filters[filtersTabMap.get(selectedTab)!] : false;

    const renderFilterPill = ({ key, label, value }: { key: keyof FilterLabel; label: string; value: string }) => {
      const uniqueKey = `${key}${label ? '-' + label : ''}`;
      return (
        <div className="filter-pill" key={uniqueKey}>
          <Pill backgroundColor={COLORS.zulu.$08} key={uniqueKey} text={value} textColor={COLORS.yuma.$01}>
            <Icon
              colorName={COLORS.yuma.$01}
              name="close"
              onClick={() => removeFilter(key, label)}
              size={1.5}
              testId={`filter-pill-remove-${uniqueKey}`}
            />
          </Pill>
        </div>
      );
    };

    return (
      <CollapsibleView
        className={classnames({ 'filters-invisible': filterPills.length === 0 || !isSelectedTabFiltered })}
        expanded
        headerIcons={[{ color: COLORS.etna.$06, name: 'trash', onClick: removeFilters }]}
        headerText={t('FILTERS.LABEL.APPLIED', { count: filterPills.length })}
      >
        {filterPills.map(renderFilterPill)}
      </CollapsibleView>
    );
  },
);
