import {
  AttachmentUploadPermissions,
  ConnectPermission,
  ExternalMentionPermissions,
  FlightRoomPermissions,
  GeneralPermissions,
  QuickRepliesPermissions,
  RolesPermissions,
  SmartActionPermissions,
} from 'types';

const mapPermissionsToObjects = (
  input:
    | typeof FlightRoomPermissions
    | typeof SmartActionPermissions
    | typeof GeneralPermissions
    // | typeof ApiKeysPermissions
    | typeof RolesPermissions
    | typeof QuickRepliesPermissions
    | typeof ExternalMentionPermissions
    | typeof AttachmentUploadPermissions,
) =>
  Object.entries(input).map(([label, permission]: [string, ConnectPermission]) => ({
    action: label.toUpperCase(),
    permission,
  }));

const PermissionGroups: {
  key: string;
  permissions: {
    action: string;
    permission: ConnectPermission;
  }[];
}[] = [
  {
    key: 'FLIGHT_ROOMS',
    permissions: mapPermissionsToObjects(FlightRoomPermissions),
  },
  {
    key: 'SMART_ACTIONS',
    permissions: mapPermissionsToObjects(SmartActionPermissions),
  },
  // {
  //   permissions: mapPermissionsToObjects(GeneralPermissions),
  //   key: 'general',
  // },
  // {
  //   permissions: mapPermissionsToObjects(ApiKeysPermissions),
  //   key: 'api keys',
  // },
  {
    key: 'ROLES',
    permissions: mapPermissionsToObjects(RolesPermissions),
  },
  {
    key: 'QUICK_REPLIES',
    permissions: mapPermissionsToObjects(QuickRepliesPermissions),
  },
  {
    key: 'EXTERNAL_MENTIONS',
    permissions: mapPermissionsToObjects(ExternalMentionPermissions),
  },
  {
    key: 'ATTACHMENT_UPLOADS',
    permissions: mapPermissionsToObjects(AttachmentUploadPermissions),
  },
];

export const AllPermissions = PermissionGroups.map(({ permissions }) => permissions.map(({ permission }) => permission)).flat();

export default PermissionGroups;
