import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { components, DropdownIndicatorProps, MultiValue, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useInputError } from '@aviobook/_hooks';
import { Icon, Text } from '@aviobook/_shared';
import InputWrapper, { InputWrapperProps } from '@aviobook/_shared/input/InputWrapper';
import { mapToOptions } from '@aviobook/_utils/option';
import { AutocompleteClient } from 'shared';
import { COLORS } from 'styles';
import { Option } from 'types';

import { ClearIndicator } from '../clearIndicator/ClearIndicator';
import { MultiValueRemove } from '../multiValueRemove/MultiValueRemove';

import './typeAhead.scss';

export type TypeaheadConfig = {
  placeholder: string;
  url: string;
};

type TypeaheadProps = InputWrapperProps & {
  onChange: (newValue: readonly Option[], name: string) => void;
  selectedOptions: readonly Option[];
  typeaheadConfig: TypeaheadConfig;
};

const DropdownIndicator = ({ ...props }: DropdownIndicatorProps<Option, true>) => (
  <components.DropdownIndicator {...props}>
    {props.children}
    <Icon colorName={COLORS.zulu.$08} name={props.selectProps.menuIsOpen ? 'chevronUp' : 'chevronDown'} />
  </components.DropdownIndicator>
);

export const Typeahead: FC<TypeaheadProps> = ({
  label,
  onChange,
  selectedOptions,
  typeaheadConfig,
  ...wrapperProps
}: TypeaheadProps) => {
  const { t } = useTranslation();
  const { name, validation } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);
  const debounceTimeout = useRef<number>(null) as React.MutableRefObject<number>;
  const [defaultOptions, setDefaultOptions] = useState<Option[]>();

  const fetchOptions = async (search: string) => {
    try {
      const response: string[] = await AutocompleteClient.getAutoComplete(typeaheadConfig.url, search);
      return mapToOptions(response);
    } catch (error) {
      return [];
    }
  };

  const loadOptions = (search: string) =>
    new Promise<Option[]>(resolve => {
      if (search) {
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = window.setTimeout(async () => {
          const options = await fetchOptions(search);
          resolve(options);
        }, 300);
      } else {
        resolve(defaultOptions ?? []);
      }
    });

  const onChangeInternal = (newValue: SingleValue<Option> | MultiValue<Option>) => {
    newValue ? onChange(newValue as MultiValue<Option>, name) : onChange([], name);
  };

  const onFocus = async () => {
    if (!defaultOptions) {
      const options = await fetchOptions('');
      setDefaultOptions(options);
    }
  };

  return (
    <InputWrapper {...wrapperProps} showError={showError}>
      <Text as="span" className="type-ahead__label" color={COLORS.zulu.$06} size="XS" weight="bold">
        {label}
      </Text>
      <AsyncSelect
        cacheOptions={true}
        className={'type-ahead'}
        classNamePrefix="type-ahead"
        closeMenuOnSelect={false}
        components={{
          ClearIndicator,
          DropdownIndicator,
          MultiValueRemove,
        }}
        defaultOptions={defaultOptions}
        isMulti
        loadOptions={loadOptions}
        loadingMessage={() => t('TYPEAHEAD.LOADING')}
        noOptionsMessage={({ inputValue }) => (inputValue ? t('TYPEAHEAD.NO_OPTIONS') : t('TYPEAHEAD.TYPE_TO_SEARCH'))}
        onChange={(newValue: SingleValue<Option> | MultiValue<Option>) => {
          onChangeInternal(newValue);
          setDirty();
        }}
        onFocus={onFocus}
        placeholder={typeaheadConfig.placeholder}
        value={selectedOptions}
      />
    </InputWrapper>
  );
};
