import React, { FC, useRef } from 'react';
import { queryClient } from '@aviobook/_http/queryClient';
import { hasReachedScrollEnd } from '@aviobook/_utils/react/react.utils';
import { FlightroomItem } from '@aviobook/flightrooms/_components/flightroomitem/FlightroomItem';
import { FlightroomDashboardTabs } from '@aviobook/flightrooms/flightroomDashboardTabs';
import classNames from 'classnames';
import { useAuthenticatedUser, useFlightroomsContext, useJoinFlightroom } from 'shared';
import { useGetFlightRooms } from 'shared/src/queries/flightrooms/useGetFlightRooms';
import { FlightRoom, FlightRoomFilterState, QueryKeys } from 'types';

import { Spinner } from '../../_shared';

type Props = {
  filters: FlightRoomFilterState | null;
  flightrooms: FlightRoom[];
  onFlightroomClick: (room: FlightRoom) => void;
  selectedFlightroomTab: FlightroomDashboardTabs;
};

export const FlightRoomList: FC<Props> = ({ filters, flightrooms, onFlightroomClick, selectedFlightroomTab }) => {
  const { canJoinFlightRooms, canViewFlightRooms, hasPendingAccessRequest, isActiveMemberOfRoom } = useAuthenticatedUser();
  const { selectedFlightroom, setSelectedFlightroom } = useFlightroomsContext();
  const { fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useGetFlightRooms(
    selectedFlightroomTab,
    false,
    true,
    filters,
  );
  const blockUI = useRef(false);

  const { mutateAsync: joinFlightroom } = useJoinFlightroom({
    onSettled: () => {
      blockUI.current = false;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.FLIGHTROOMS]);
    },
  });

  const joinFlightRoom = async (room: FlightRoom) => {
    blockUI.current = true;
    await joinFlightroom(room.externalId);
    setSelectedFlightroom(room);
  };

  const onClickFlightroom = async (room: FlightRoom) => {
    if (hasPendingAccessRequest(room) && canJoinFlightRooms) {
      await joinFlightRoom(room);
      onFlightroomClick(room);
      return;
    }

    if (!isActiveMemberOfRoom(room)) {
      return;
    }
    onFlightroomClick(room);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (hasReachedScrollEnd(e) && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  };

  if (isLoading && canViewFlightRooms) {
    return (
      <section className={classNames('flightrooms-content-container', { 'flightrooms-content-container-loader': isLoading })}>
        <Spinner size="large" />
      </section>
    );
  }

  return (
    <section className="flightrooms-content-container" onScroll={handleScroll}>
      {flightrooms.map((room: FlightRoom) => (
        <FlightroomItem
          displayAccessState={true}
          displayJoinIcon={false}
          displayUnreadCount={isActiveMemberOfRoom(room)}
          flightroom={room}
          hasUnreadMentions={room.hasUnreadMentions}
          isSelected={room.externalId === selectedFlightroom?.externalId}
          key={`filtered-${room.externalId}`}
          onClick={onClickFlightroom}
          showEstimatedTimes={true}
          unreadCount={room.unreadMessagesCount}
        />
      ))}

      {isFetchingNextPage ? (
        <span className="flightrooms-content-fetching-spinner">
          <Spinner size="normal" />
        </span>
      ) : null}
    </section>
  );
};
