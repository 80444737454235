import { useCallback, useMemo } from 'react';
import { Division, FlightRoomUserStatus, Mention, MentionType, RoomRoleTitle } from 'types';

import { useChatMessagesContext, useFlightroomsContext, useUsersContext } from '../../context';
import { getMentionsRegex, replaceJobTitlePlaceholders, UserV1Util } from '../../utils';
import { useGetExternalMentions } from './useGetExternalMentions';

export const useMentions = (mentionInProgress: string = '') => {
  const { selectedFlightroom: flightroom } = useFlightroomsContext();
  const { users } = useUsersContext();
  const { selectedMentions } = useChatMessagesContext();

  const { externalMentions } = useGetExternalMentions(mentionInProgress);

  const groupUsersByRole = (roleMap: Map<string, Mention>, role: string, userExternalId: string, type: MentionType) => {
    if (!roleMap.has(role)) {
      roleMap.set(role, {
        isExternal: false,
        label: role,
        type,
        users: [],
      });
    }
    roleMap.get(role)!.users?.push(userExternalId);
  };

  const crewRoleMentionOptions = useMemo(() => {
    if (!flightroom?.users) {
      return [];
    }

    const roleMap = new Map<string, Mention>();

    const handleScheduledUser = (role: string, externalId: string) => {
      groupUsersByRole(roleMap, role, externalId, MentionType.CREW_ROLE);
    };

    const handleUnscheduledUser = (externalId: string) => {
      const user = users.find(u => u.externalId === externalId);
      const role = user?.jobTitle ? UserV1Util.getDivision(user.jobTitle).toUpperCase() : Division.UNKNOWN.toUpperCase();
      groupUsersByRole(roleMap, role, externalId, MentionType.CREW_ROLE);
    };

    flightroom.users.forEach(({ externalId, scheduled, status, type }) => {
      if (status !== FlightRoomUserStatus.ACTIVE) {
        return;
      }

      if (scheduled) {
        handleScheduledUser(replaceJobTitlePlaceholders(type.name || RoomRoleTitle.UNKNOWN), externalId);
      } else {
        handleUnscheduledUser(externalId);
      }
    });

    return Array.from(roleMap.values());
  }, [flightroom?.users, users]);

  const crewNameMentionOptions = useMemo(() => {
    if (!flightroom?.users) {
      return [];
    }

    return flightroom.users.reduce<Mention[]>((acc, flightRoomUser) => {
      if (flightRoomUser.status !== FlightRoomUserStatus.ACTIVE) {
        return acc;
      }
      const user = users.find(({ externalId }) => externalId === flightRoomUser.externalId);
      if (user) {
        acc.push({
          isExternal: false,
          label: `${user.firstName} ${user.lastName}`,
          type: MentionType.USER_NAME,
          users: [user.externalId],
        });
      }
      return acc;
    }, []);
  }, [flightroom, users]);

  const mentionOptions = useMemo(
    () => [...crewRoleMentionOptions, ...crewNameMentionOptions].sort((a, b) => a.label.localeCompare(b.label)), // Sort alphabetically by label
    [crewRoleMentionOptions, crewNameMentionOptions],
  );

  const filteredMentionOptions: Mention[] = useMemo(() => {
    return mentionOptions.filter(mention =>
      mention.label.toUpperCase().includes(mentionInProgress.toUpperCase().split('@')[1] || ''),
    );
  }, [mentionOptions, mentionInProgress]);

  const mentionsRegex = useMemo(() => {
    const options = [...mentionOptions, ...externalMentions, ...selectedMentions];
    if (!options?.length) {
      return '';
    }
    return getMentionsRegex(options);
  }, [mentionOptions, externalMentions, selectedMentions]);

  const getUsedMentions = useCallback(
    (message: string = ''): Mention[] => {
      const allMentionOptions = [...mentionOptions, ...selectedMentions];
      const foundMentions: string[] = [];
      let match;
      const regex = new RegExp(mentionsRegex, 'gi');

      while ((match = regex.exec(message)) !== null) {
        foundMentions.push(match[0]);
      }
      return foundMentions
        .map(mention => allMentionOptions.find(option => mention === `@${option.label}`))
        .filter((maybeMention): maybeMention is Mention => !!maybeMention);
    },
    [mentionOptions, mentionsRegex, selectedMentions],
  );

  return { externalMentions, filteredMentionOptions, getUsedMentions, mentionOptions, mentionsRegex };
};
