import { useCallback } from 'react';
import { CombiningTranslationDict, ExternalMentionPermissions, FlightRoom, FlightRoomPermissions } from 'types';
import { FlightRoomUserStatus } from 'types/src/room';

import { useAuthenticationContext } from '../../context';
import { PermissionsUtil } from '../../utils';

type UseAuthenticatedUser = {
  canJoinFlightRooms: boolean;
  canMentionExternally: boolean;
  canRequestAccess: boolean;
  canSearchFlightRooms: boolean;
  canViewFlightRooms: boolean;
  hasGrantAccess: boolean;
  hasPendingAccessRequest: (room: FlightRoom) => boolean;
  isActiveMemberOfRoom: (room: FlightRoom) => boolean;
  isNotActiveMemberOfRoom: (room: FlightRoom) => boolean;
};

export const useAuthenticatedUser = (): UseAuthenticatedUser => {
  const { user } = useAuthenticationContext();
  const hasPendingAccessRequest = useCallback(
    (room: FlightRoom) => {
      const flightRoomUser = room.users?.find(flightRoomUser => flightRoomUser.externalId === user?.externalId);

      if (flightRoomUser === undefined) {
        return false;
      }

      return flightRoomUser.status === FlightRoomUserStatus.PENDING;
    },
    [user],
  );

  const isActiveMemberOfRoom = useCallback(
    (room: FlightRoom) => {
      const flightRoomUser = room.users?.find(flightRoomUser => flightRoomUser.externalId === user?.externalId);

      if (flightRoomUser === undefined) {
        return false;
      }

      return flightRoomUser.status === FlightRoomUserStatus.ACTIVE;
    },
    [user],
  );

  const isNotActiveMemberOfRoom = useCallback(
    (room: FlightRoom) => {
      const flightRoomUser = room.users?.find(flightRoomUser => flightRoomUser.externalId === user?.externalId);
      return flightRoomUser?.status === FlightRoomUserStatus.INACTIVE;
    },
    [user],
  );

  if (user == null) {
    return {
      canJoinFlightRooms: false,
      canMentionExternally: false,
      canRequestAccess: false,
      canSearchFlightRooms: false,
      canViewFlightRooms: false,
      hasGrantAccess: false,
      hasPendingAccessRequest: () => false,
      isActiveMemberOfRoom: () => false,
      isNotActiveMemberOfRoom: () => false,
    };
  }

  const permissionUtil: PermissionsUtil = new PermissionsUtil(user);

  return {
    canJoinFlightRooms: permissionUtil.hasPermission(FlightRoomPermissions.JOIN),
    canMentionExternally: permissionUtil.hasPermission(ExternalMentionPermissions.MENTION_OTHERS),
    canRequestAccess: permissionUtil.hasPermission(FlightRoomPermissions.REQUEST_ACCESS),
    canSearchFlightRooms: permissionUtil.hasPermissions(
      [FlightRoomPermissions.JOIN, FlightRoomPermissions.REQUEST_ACCESS],
      CombiningTranslationDict.OR,
    ),
    canViewFlightRooms: permissionUtil.hasPermission(FlightRoomPermissions.VIEW),
    hasGrantAccess: permissionUtil.hasPermission(FlightRoomPermissions.GRANT_ACCESS),
    hasPendingAccessRequest,
    isActiveMemberOfRoom,
    isNotActiveMemberOfRoom,
  };
};
