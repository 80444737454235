import { SUPPORTED_MIME_TYPES } from '../../constants';

export const isValidFileSignature = (signature: string) => {
  return SUPPORTED_MIME_TYPES.some(entry => entry.validFileSignatureCheck(signature));
};

export const getValidExtensionsString = (exclude: string[] = []): string => {
  return SUPPORTED_MIME_TYPES.filter(mimeType => !exclude.some(excludeFileType => mimeType.extensions.includes(excludeFileType)))
    .map(entry => `${entry.extensions.join(', ')}`)
    .join(', ');
};

// we only do this check on mobile because Android converts image/gif to image/jpg
export const isValidFileType = (fileName: string) => {
  const splittedArray = fileName.split('.');
  return splittedArray.length > 1 && splittedArray[splittedArray.length - 1] !== 'gif';
};
