import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@aviobook/_hooks';
import { FormValidationErrors } from '@aviobook/_hooks/useForm';
import { InputField } from '@aviobook/_shared';
import { Button, FlexComponent } from '@aviobook/cms/_shared';
import Modal from '@aviobook/modal/Modal';
import { FormValidator } from 'shared';
import { Keyword } from 'types';

type Props = {
  closeModal: () => void;
  onsubmit: (keyword: Keyword) => void;
};

const validateForm = (values: Keyword): FormValidationErrors<Keyword> => {
  const validator = new FormValidator();

  return {
    description: validator.required(values.description),
    keyword: validator.required(values.keyword),
  };
};

const initialForm: Keyword = {
  description: '',
  keyword: '',
};

export const AddKeywordModal: FC<Props> = ({ closeModal, onsubmit: submitForm }) => {
  const { t } = useTranslation();

  const form = useForm<Keyword>({
    initialForm,
    submitForm: values => submitForm(values),
    validateForm: values => validateForm(values),
  });

  return (
    <Modal>
      <Modal.Header onCancel={closeModal}>{t('SMART_ACTION_FORM_KEYWORDS_ADD_BUTTON')}</Modal.Header>
      <form onSubmit={form.submit}>
        <FlexComponent flexDirection="column" gap={1} padding={2} width="full">
          <InputField
            label="text"
            name="keyword"
            onChange={form.setAttribute}
            required
            showInlineBottomError
            validation={form.validationErrors.keyword}
            value={form.values?.keyword}
          />
          <InputField
            label="description"
            name="description"
            onChange={form.setAttribute}
            required
            showInlineBottomError
            validation={form.validationErrors.description}
            value={form.values?.description}
          />
        </FlexComponent>
        <Modal.Buttons>
          <div className="buttons-left-container"></div>
          <Button isOutline onClick={closeModal} theme="transparent">
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
          <Button disabled={!form.isDirty || !form.isValid} theme="primary" type="submit">
            {t('SHARED.BUTTONS.ADD')}
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
};
