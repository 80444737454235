import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../_shared';
import Modal from '../Modal';

type Props = {
  message: string;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
};

export const SendMessageModal: FC<Props> = ({ message, onClose, onConfirm, title }) => {
  const { t } = useTranslation();

  const onCancel = () => {
    onClose();
  };

  return (
    <Modal onCancel={onCancel}>
      <Modal.Header onCancel={onCancel}>{title}</Modal.Header>
      <Modal.Content>{message}</Modal.Content>
      <Modal.Buttons>
        <Button isOutline onClick={onCancel} theme="dark">
          {t('SHARED.BUTTONS.CANCEL')}
        </Button>
        <Button onClick={onConfirm} theme="primary">
          {t('SHARED.BUTTONS.PROCEED')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};
