import { createContext, FC, PropsWithChildren, useCallback, useContext, useReducer } from 'react';
import { AuthenticatedUser } from 'types';

import { authenticationReducer } from './authentication.reducer';
import { AuthenticationActionTypes } from './authenticationAction.enum';
import { AuthenticationContextType } from './authenticationContext.type';

type Props = PropsWithChildren;

const initialState: AuthenticationContextType = {
  accessToken: null,
  enableAuthentication: false,
  enableAuthenticationCms: false,
  isLoading: true,
  reset: () => {},
  setAccessToken: () => {},
  setEnableAuthentication: () => {},
  setEnableAuthenticationCms: () => {},
  setLoading: () => {},
  setUser: () => {},
  user: null,
};

export const AuthenticationContext = createContext<AuthenticationContextType>(initialState);

export const useAuthenticationContext = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error('AuthenticationContext must be used within a AuthenticationContextProvider');
  }
  return context;
};

export const AuthenticationContextProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(authenticationReducer, {
    accessToken: null,
    enableAuthentication: true,
    enableAuthenticationCms: true,
    isLoading: true,
    user: null,
  });

  const setEnableAuthentication = useCallback(
    (enable: boolean) => {
      dispatch({
        payload: enable,
        type: AuthenticationActionTypes.ENABLE_AUTHENTICATION,
      });
    },
    [dispatch],
  );

  const setEnableAuthenticationCms = useCallback(
    (enable: boolean) => {
      dispatch({
        payload: enable,
        type: AuthenticationActionTypes.ENABLE_AUTHENTICATION_CMS,
      });
    },
    [dispatch],
  );

  const setUser = useCallback(
    (user?: AuthenticatedUser) => {
      dispatch({
        payload: user ?? null,
        type: AuthenticationActionTypes.SET_PROFILE,
      });
    },
    [dispatch],
  );

  const setAccessToken = useCallback(
    (token: string | null) => {
      dispatch({
        payload: token,
        type: AuthenticationActionTypes.SET_ACCESS_TOKEN,
      });
    },
    [dispatch],
  );

  const reset = useCallback(() => {
    dispatch({
      payload: null,
      type: AuthenticationActionTypes.RESET,
    });
  }, [dispatch]);

  const setLoading = useCallback(
    (isLoading: boolean) => {
      dispatch({
        payload: isLoading,
        type: AuthenticationActionTypes.SET_LOADING,
      });
    },
    [dispatch],
  );

  return (
    <AuthenticationContext.Provider
      value={{
        ...state,
        reset,
        setAccessToken,
        setEnableAuthentication,
        setEnableAuthenticationCms,
        setLoading,
        setUser,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
