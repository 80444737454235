import { FC, useEffect } from 'react';
import Modal from 'react-modal';
import { Route, Routes } from 'react-router-dom';
import { useFirebaseConfigContext } from '@aviobook/_context';
import { useLogout } from '@aviobook/_hooks';
import { SmartBanner } from '@aviobook/_shared';
import { AppWeb } from '@aviobook/AppWeb';
import { AppCms } from '@aviobook/cms/AppCms';
import { ModalWrapper } from '@aviobook/modal/ModalWrapper';
import { Toastify } from '@aviobook/Toastify';
import Cookies from 'js-cookie';
import { COOKIE_ACCESS_TOKEN_NAME, EventType, useAuthenticationContext } from 'shared';
import { refreshAccessToken } from 'shared/src/queries/auth';

Modal.setAppElement('#root');

export const App: FC = () => {
  const { accessToken, setAccessToken } = useAuthenticationContext();
  const { logEvent } = useFirebaseConfigContext();
  const logout = useLogout();

  useEffect(() => {
    if (!Cookies.get(COOKIE_ACCESS_TOKEN_NAME)) {
      refreshAccessToken()
        .then(() => {
          if (!Cookies.get(COOKIE_ACCESS_TOKEN_NAME)) {
            logEvent(EventType.LOGOUT, { reason: '401 on token refresh no cookie in App.tsx' });
            logout();
          } else {
            setAccessToken(Cookies.get(COOKIE_ACCESS_TOKEN_NAME)!);
          }
        })
        .catch(() => {
          logEvent(EventType.LOGOUT, { reason: '401 on token refresh catch in App.tsx' });
          logout();
        });
    } else {
      if (!accessToken) {
        setAccessToken(Cookies.get(COOKIE_ACCESS_TOKEN_NAME)!);
      }
    }
  }, [accessToken, logEvent, logout, setAccessToken]);

  return (
    <>
      <SmartBanner />
      <Routes>
        <Route element={<AppWeb />} path="*" />
        <Route element={<AppCms />} path="/admin/*" />
      </Routes>
      <ModalWrapper />
      <Toastify />
    </>
  );
};
