import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Spinner } from '@aviobook/_shared';
import { General, Roles } from '@aviobook/cms/connect/_screens';
import { CreateSmartAction, SmartActionQuickReplies, SmartActions } from '@aviobook/cms/connect/_screens/general/_tabs';
import { UpdateSmartAction } from '@aviobook/cms/connect/_screens/general/_tabs/smart-actions/smart-action/UpdateSmartAction';
import { Role } from '@aviobook/cms/connect/_screens/roles/role/Role';
import { Connect } from '@aviobook/cms/connect/Connect';
import { LicenseAgreement } from '@aviobook/license-agreement/LicenseAgreement';
import { HttpClient, PermissionsUtil, useAppConfigContext, useAuthenticate, useAuthenticationContext } from 'shared';
import {
  AuthenticatedUser,
  CombiningTranslationDict,
  QuickRepliesPermissions,
  RolesPermissions,
  SmartActionPermissions,
} from 'types';

import { AuthorizedLayout } from './_routing/authorized-layout/AuthorizedLayout';
import { Login } from './auth/login/Login';
import { LoginFeedback } from './auth/loginFeedback/LoginFeedback';

import './index-cms.scss';

export const AppCms: FC = () => {
  document.title = 'Connect Admin';
  const { t } = useTranslation();
  const { accessToken, setUser } = useAuthenticationContext();
  const location = useLocation();
  const { pathname, search } = location;
  const { appConfig, updateAppConfigAccount } = useAppConfigContext();
  const shouldAuthenticate = !search.includes('logout') && !pathname.includes('/auth/license-agreement');

  const { data: user, isLoading } = useAuthenticate(true, {
    enabled: shouldAuthenticate && !!accessToken,
    onSuccess: user => {
      updateAppConfigAccount(user.account);
      HttpClient.setHeaders({ ...appConfig, account: user.account });
      setUser(user);
    },
  });

  if (isLoading && shouldAuthenticate) {
    return <Spinner overlay>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return getRoutes(user);
};

const getRoutes = (user?: AuthenticatedUser) => {
  if (user) {
    if (user.hasAcceptedLicenseAgreement) {
      return <AuthenticatedRoutes user={user} />;
    }
    return <LicenseAgreementRoutes />;
  }
  return <NotAuthenticatedRoutes />;
};

type AuthenticatedRoutesProps = {
  user: AuthenticatedUser;
};

const AuthenticatedRoutes: FC<AuthenticatedRoutesProps> = ({ user }) => {
  document.title = 'Connect Admin';

  const permissions = useMemo(() => {
    return new PermissionsUtil(user);
  }, [user]);

  const generalAllowed = useMemo(
    () =>
      permissions.hasPermissions(
        [
          SmartActionPermissions.VIEW,
          SmartActionPermissions.ADD,
          SmartActionPermissions.DELETE,
          SmartActionPermissions.UPDATE,
          QuickRepliesPermissions.ADD,
          QuickRepliesPermissions.VIEW,
        ],
        CombiningTranslationDict.OR,
      ),
    [permissions],
  );

  const rolesAllowed = useMemo(() => permissions.hasPermission(RolesPermissions.VIEW), [permissions]);
  const smartActionsAllowed = useMemo(() => permissions.hasPermission(SmartActionPermissions.VIEW), [permissions]);
  const quickRepliesAllowed = useMemo(() => permissions.hasPermissions(QuickRepliesPermissions.VIEW), [permissions]);

  return (
    <Routes>
      <Route element={<AuthorizedLayout />}>
        <Route element={<Connect />} path="connect">
          <Route element={<Navigate replace to={generalAllowed ? 'general' : 'roles'} />} index />
          {generalAllowed ? (
            <Route element={<General />} path="general">
              {smartActionsAllowed ? (
                <>
                  <Route element={<Navigate replace to="smart-actions" />} index />
                  <Route element={<SmartActions />} path="smart-actions" />
                  <Route element={<UpdateSmartAction />} path="smart-actions/edit/:smartActionName" />
                  {permissions.hasPermission(SmartActionPermissions.ADD) ? (
                    <Route element={<CreateSmartAction />} path="smart-actions/new" />
                  ) : null}
                </>
              ) : (
                <Route element={<Navigate replace to="quick-replies" />} index />
              )}
              {quickRepliesAllowed ? <Route element={<SmartActionQuickReplies />} path="quick-replies" /> : null}
            </Route>
          ) : null}
          {rolesAllowed ? (
            <>
              <Route element={<Roles />} path="roles" />
              <Route element={<Role />} path="roles/:roleId/*" />
            </>
          ) : null}
        </Route>
      </Route>
      <Route element={<Navigate to="connect" />} path="*" />;
    </Routes>
  );
};

const NotAuthenticatedRoutes: FC = () => {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route element={<Login />} path="login" />
      <Route
        element={<LoginFeedback description={t('PERMISSIONDENIED_DESCRIPTION')} title={t('PERMISSIONDENIED_TITLE')} />}
        path="permission-denied"
      />
      <Route element={<Navigate to="login" />} path="*" />
    </Routes>
  );
};

const LicenseAgreementRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<LicenseAgreement />} path="*" />
    </Routes>
  );
};
